import pages from './pages'
//Dashboard
import dashboardOperations from './dashboard/operational'
import dashboardFinancials from './dashboard/financial'
import dashboardOperationsJobs from './dashboard/operational/jobs'
import dashboardOperationsPerformance from './dashboard/operational/performance'
import dashboardFinancialsBonusTracker from './dashboard/financial/bonusTracker'
import dashboardFinancialProfitability from './dashboard/financial/profitability'
//Operations Center
import operationsCenterFiling from './operationsCenter/filing'
import operationsCenterFinance from './operationsCenter/finance'
import operationsCenterService from './operationsCenter/service'
import operationsCenterServiceJobBoard from './operationsCenter/service/jobBoard'
import operationsCenterFilingDocuments from './operationsCenter/filing/documents'
import operationsCenterFinancePrePayroll from './operationsCenter/finance/prePayroll'
import operationsCenterFinanceExpenses from './operationsCenter/finance/expenses'
import operationsCenterCalendar from './operationsCenter/calendar/'
import operationsCenterFinanceExpensesCreate from './operationsCenter/finance/expenses/create'

//Business Center
import businessCenterHumanResources from './businessCenter/humanResources'
import businessCenterOperations from './businessCenter/operations'
import businessCenterPricingAndOfferings from './businessCenter/pricingAndOfferings'
import businessCenterResources from './businessCenter/resources'
import businessCenterOperationsServiceList from './businessCenter/operations/serviceList'
import businessCenterOperationsNewService from './businessCenter/operations/newService'
import businessCenterOperationsNewHoo from './businessCenter/operations/hoursOfOperations/create'
import businessCenterOperationsHooList from './businessCenter/operations/hoursOfOperations/list'
import businessCenterOperationsHooDetails from './businessCenter/operations/hoursOfOperations/details'
import businessCenterOperationsHooEdit from './businessCenter/operations/hoursOfOperations/edit'
import businessCenterOperationsLocationList from './businessCenter/operations/location/list'
import businessCenterOperationsLocationNew from './businessCenter/operations/location/new'
import businessCenterOperationsLocationDetails from './businessCenter/operations/location/details'
import businessCenterOperationsLocationEdit from './businessCenter/operations/location/edit'
import businessCenterOperationsVehicleList from './businessCenter/operations/vehicle/list'
import businessCenterOperationsVehicleCreate from './businessCenter/operations/vehicle/create'
import businessCenterOperationsFacilityList from './businessCenter/operations/facility/list'
import businessCenterOperationsFacilityNew from './businessCenter/operations/facility/new'
import businessCenterOperationsFacilityEdit from './businessCenter/operations/facility/edit'
import businessCenterOperationsFacilityDetails from './businessCenter/operations/facility/details'
import businessCenterOperationsInsuranceCreate from './businessCenter/operations/insurance/new'
import businessCenterOperationsInsuranceList from './businessCenter/operations/insurance/list'
import businessCenterOperationsInsuranceEdit from './businessCenter/operations/insurance/edit'
import businessCenterOperationsInsuranceDetails from './businessCenter/operations/insurance/details'
import businessCenterHumanResourcesUserList from './businessCenter/humanResources/users'
import businessCenterHumanResourcesUserCreate from './businessCenter/humanResources/users/create'
import businessCenterHumanResourcesUserProfile from './businessCenter/humanResources/users/profile'
import businessCenterHumanResourcesUserCards from './businessCenter/humanResources/users/cards'
import businessCenterHumanResourcesDriverList from './businessCenter/humanResources/driver/list'
import businessCenterHumanResourcesDriverProfile from './businessCenter/humanResources/driver/profile'
import businessCenterHumanResourcesDriverCreate from './businessCenter/humanResources/driver/create'
import businessCenterHumanResourcesDriverSchedule from './businessCenter/humanResources/driver/schedule/'
import businessCenterHumanResourcesTimeSheets from './businessCenter/humanResources/timeSheets/'

//Resource Center
import resourceCenterSystem from './resourceCenter/system'
import resourceCenterSupport from './resourceCenter/support'
//Sections
import sectionsProviderGeneralApp from './sections/@provider/general/app'
import sectionsProviderGeneralBonus from './sections/@provider/general/bonus'
import sectionsProviderGeneralCsi from './sections/@provider/general/csi'
import sectionsProviderGeneralBanking from './sections/@provider/general/banking'
import sectionsProviderJobRequestList from './sections/@provider/job-request/list'
import sectionsAppDocumentList from './sections/@app/document/list'
import sectionsProviderExpenseList from './sections/@provider/expense/list'
import sectionsProviderExpense from './sections/@provider/expense'
import sectionsProviderServiceList from './sections/@provider/service/list'
import sectionsProviderServiceNewEditForm from './sections/@provider/service/newEditForm'
import sectionsProviderHooNewEditForm from './sections/@provider/hoo/newEditForm'
import sectionsProviderHooList from './sections/@provider/hoo/list'
import sectionsAppUserList from './sections/@app/user/list'
import sectionsAppUser from './sections/@app/user'
import sectionsAppUserAccount from './sections/@app/user/account'
import sectionsAppUserProfile from './sections/@app/user/profile'
import sectionsAppUserCards from './sections/@app/user/cards'
import sectionsProviderVehicleDetails from './sections/@provider/vehicle/details'
import sectionsProviderLocationNewEditForm from './sections/@provider/location/newEditForm'
import sectionsProviderLocationNewEditDetails from './sections/@provider/location/newEditDetails'
import sectionsProviderVehicle from './sections/@provider/vehicle'
import sectionsProviderVehicleList from './sections/@provider/vehicle/list'
import sectionsProviderVehicleCreate from './sections/@provider/vehicle/newEditForm'
import sectionsProviderFacilityDetails from './sections/@provider/facility/details'
import sectionsProviderInsuranceCreate from './sections/@provider/insurance/newEditForm'
import sectionsProviderDriverNewEditForm from './sections/@provider/driver/newEditForm'
import sectionsProviderDiverSchedule from './sections/@provider/driver/schedule'
import sectionsProviderGeneralDriver from './sections/@provider/general/driver'
import sectionsAppCalender from './sections/@app/calendar'
import sectionsProviderPayroll from './sections/@provider/payroll'
import sectionsProviderPayrollComponentsWorksheet from './sections/@provider/payroll/components/worksheet'
import sectionsProviderFinanceExpensesNewEditForm from './sections/@provider/expense/newEditForm'

//Components
import componentsTable from './components/table'
import componentsHookForm from './components/hookForm'
import componentsUpload from './components/upload'
import components from "./components"
import sectionsProviderDriverProfileRating from "./sections/@provider/driver/profileRating"
import sectionsProviderDriverProfile from './sections/@provider/driver/profile'
//App
import app from "./app/"

import demo from './demo.json'
import navigation from './navigation.json'
import termsAndConditions from './termsAndConditions.json'
import privacyPolicy from './privacyPolicy.json'
import termsOfService from './termsOfService.json'
//Hooks
import hooks from './hooks'
//Navs
import navs from './navs'

const translation = {
    pages,
    dashboard: {
        operational: {...dashboardOperations},
        financial: {...dashboardFinancials},
        //sub pages
        operationalJobs: {...dashboardOperationsJobs},
        operationalPerformance: {...dashboardOperationsPerformance},
        financialBonusTracker: {...dashboardFinancialsBonusTracker},
        financialProfitability: {...dashboardFinancialProfitability}
    },
    operationsCenter: {
        filing: {...operationsCenterFiling},
        finance: {...operationsCenterFinance},
        service: {...operationsCenterService},
        //sub pages
        serviceJobBoard: {...operationsCenterServiceJobBoard},
        filingDocuments: {...operationsCenterFilingDocuments},
        financePrePayroll: {...operationsCenterFinancePrePayroll},
        financeExpenses: {...operationsCenterFinanceExpenses},
        calendar: {...operationsCenterCalendar},
        financeExpensesCreate: {...operationsCenterFinanceExpensesCreate},
    },
    businessCenter: {
        humanResources: {...businessCenterHumanResources},
        operations: {...businessCenterOperations},
        pricingAndOfferings: {...businessCenterPricingAndOfferings},
        resources: {...businessCenterResources},
        operationsServiceList: {...businessCenterOperationsServiceList},
        operationsNewService: {...businessCenterOperationsNewService},
        operationsNewHoo: {...businessCenterOperationsNewHoo},
        operationsHooList: {...businessCenterOperationsHooList},
        operationsHooDetails: {...businessCenterOperationsHooDetails},
        operationsHooEdit: {...businessCenterOperationsHooEdit},
        operationsLocationList: {...businessCenterOperationsLocationList},
        operationsLocationNew: {...businessCenterOperationsLocationNew},
        operationsLocationDetails: {...businessCenterOperationsLocationDetails},
        operationsLocationEdit: {...businessCenterOperationsLocationEdit},
        operationsFleetList: {...businessCenterOperationsVehicleList},
        operationsVehicleCreate: {...businessCenterOperationsVehicleCreate},
        operationsFacilityList: {...businessCenterOperationsFacilityList},
        operationsFacilityNew: {...businessCenterOperationsFacilityNew},
        operationsFacilityEdit: {...businessCenterOperationsFacilityEdit},
        operationsFacilityDetails: {...businessCenterOperationsFacilityDetails},
        operationsInsuranceCreate: {...businessCenterOperationsInsuranceCreate},
        operationsInsuranceList: {...businessCenterOperationsInsuranceList},
        operationsInsuranceEdit: {...businessCenterOperationsInsuranceEdit},
        operationsInsuranceDetails: {...businessCenterOperationsInsuranceDetails},

        humanResourcesUserList: {...businessCenterHumanResourcesUserList},
        humanResourcesUserCreate: {...businessCenterHumanResourcesUserCreate},
        humanResourcesUserProfile: {...businessCenterHumanResourcesUserProfile},
        humanResourcesUserCards: {...businessCenterHumanResourcesUserCards},
        humanResourcesDriverList: {...businessCenterHumanResourcesDriverList},
        humanResourcesDriverProfile: {...businessCenterHumanResourcesDriverProfile},
        humanResourcesDriverCreate: {...businessCenterHumanResourcesDriverCreate},
        humanResourcesDriverSchedule: {...businessCenterHumanResourcesDriverSchedule},
        humanResourcesTimeSheets: {...businessCenterHumanResourcesTimeSheets}
    },
    resourceCenter: {
        system: {...resourceCenterSystem},
        support: {...resourceCenterSupport}
    },
    //Sections
    sections: {
        providerGeneralApp: {...sectionsProviderGeneralApp},
        providerGeneralBonus: {...sectionsProviderGeneralBonus},
        providerGeneralCsi: {...sectionsProviderGeneralCsi},
        providerGeneralBanking: {...sectionsProviderGeneralBanking},
        providerJobRequestList: {...sectionsProviderJobRequestList},
        appDocumentList: {...sectionsAppDocumentList},
        providerExpenseList: {...sectionsProviderExpenseList},
        providerExpense: {...sectionsProviderExpense},
        providerServiceList: {...sectionsProviderServiceList},
        providerNewService: {...sectionsProviderServiceNewEditForm},
        providerHooNewEditForm: {...sectionsProviderHooNewEditForm},
        providerHooList: {...sectionsProviderHooList},
        providerLocationNewEditForm: {...sectionsProviderLocationNewEditForm},
        providerLocationNewEditDetails: {...sectionsProviderLocationNewEditDetails},
        providerVehicle: {...sectionsProviderVehicle},
        providerVehicleList: {...sectionsProviderVehicleList},
        providerFacilityDetails: {...sectionsProviderFacilityDetails},
        providerInsuranceCreate: {...sectionsProviderInsuranceCreate},
        providerDriverNewEditForm: {...sectionsProviderDriverNewEditForm},
        providerDriverProfile: {...sectionsProviderDriverProfile},
        providerGeneralDriver: {...sectionsProviderGeneralDriver},
        providerDriverProfileRating: {...sectionsProviderDriverProfileRating},
        providerDiverSchedule: {...sectionsProviderDiverSchedule},
        appUserList: {...sectionsAppUserList},
        appUser: {...sectionsAppUser},
        appUserAccount: {...sectionsAppUserAccount},
        appUserProfile: {...sectionsAppUserProfile},
        appUserCards: {...sectionsAppUserCards},
        providerVehicleDetails: {...sectionsProviderVehicleDetails},
        providerVehicleCreate: {...sectionsProviderVehicleCreate},
        appCalender: {...sectionsAppCalender},
        providerPayroll: {...sectionsProviderPayroll},
        providerPayrollComponentsWorksheet: {...sectionsProviderPayrollComponentsWorksheet},
        providerFinanceExpensesNewEditForm: {...sectionsProviderFinanceExpensesNewEditForm}
    },
    //Components
    components: {
        table: {...componentsTable},
        hookForm: {...componentsHookForm},
        components: {...components},
        upload: {...componentsUpload}
    },
    //App
    app: {...app},
    hooks: {...hooks},
    navs: {...navs},

    ...demo, ...navigation, ...termsAndConditions, ...privacyPolicy, ...termsOfService
}

console.log(translation);
export default translation
