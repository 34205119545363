import {noCase} from 'change-case';
// _mock
import _mock from './_mock';
import {randomInArray, randomNumberRange} from './funcs';
import {fNumber} from "../utils/formatNumber";

// ----------------------------------------------------------------------

export const _appRelated = ['Chrome', 'Drive', 'Dropbox', 'Evernote', 'Github'].map((appName, index) => ({
    id: _mock.id(index),
    name: appName,
    system: (index === 2 && 'Windows') || (index === 4 && 'Windows') || 'Mac',
    price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
    rating: _mock.number.rating(index),
    review: randomNumberRange(999, 99999),
    shortcut: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_${noCase(appName)}.svg`,
}));

export const _userRated = ['Liam Johnson', 'Noah Williams', 'Caleb Anderson', 'Ethan Clark', 'Mason Wilson'].map((appName, index) => ({
    id: _mock.id(index),
    name: appName,
    system: fNumber(randomNumberRange(50, 19537)) + ' Jobs',
    price: index === 0 || index === 2 || index === 4 ? 0 : _mock.number.price(index),
    rating: _mock.number.rating(index),
    review: randomNumberRange(50, 1537),
    shortcut: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/avatars/avatar_${index + 1}.png`,
}));

export const _appInstalled = ['de', 'en', 'fr', 'kr', 'us'].map((country, index) => ({
    id: _mock.id(index),
    name: ['Germany', 'England', 'France', 'Korean', 'USA'][index],
    android: randomNumberRange(999, 99999),
    windows: randomNumberRange(999, 99999),
    apple: randomNumberRange(999, 99999),
    flag: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/icons/ic_flag_${country}.svg`,
}));

export const _topServices = ['Lockout', 'Flat Bed Tow', 'Tow', 'Jump Start', 'Recovery'].map((country, index) => ({
    id: _mock.id(index),
    name: ['Lockout', 'Flat Bed Tow', 'Tow', 'Jump Start', 'Recovery'][index],
    tows: randomNumberRange(999, 99999),
    flag: `https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_flag_en.svg`,
}));

export const _appAuthors = [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    avatar: _mock.image.avatar(index),
    favourite: randomNumberRange(9999, 19999),
}));

export const _appInvoices = [...Array(5)].map((_, index) => ({
    id: `${Date.now() + index}`,
    price: _mock.number.price(index),
    category: randomInArray(['Android', 'Mac', 'Windows']),
    status: randomInArray(['paid', 'out_of_date', 'in_progress']),
}));

export const _appFeatured = [...Array(3)].map((_, index) => ({
    id: _mock.id(index),
    title: ['Harry Potter and the Deathly Hallows - Part 2', 'Disney Zombies 2', 'Lightroom mobile - Koloro'][index],
    description: _mock.text.title(index),
    image: _mock.image.feed(index),
}));
