import {createContext, useEffect, useReducer} from 'react';
import {Auth0Client} from '@auth0/auth0-spa-js';
import PropTypes from 'prop-types';
import {AUTH0_API, CUSTOMER_PATH_AFTER_LOGIN, DRIVER_PATH_AFTER_LOGIN, PATH_AFTER_LOGIN} from '../config';
import axios from '../utils/axios';
import {PATH_REGISTRATION} from '../routes/paths';
import {setSession} from '../utils/jwt';
import {find, startCase} from "lodash";

const debug = process.env.REACT_APP_ENV === 'dev'

let auth0Client = null;

const initialAuthState = {
    isAuthenticated: false,
    error: null,
    initializing: true,
    isInitialized: false,
    organization: null,
    isDriver: false,
    user: null,
};

const handlers = {
    INITIALIZING: (state, action) => {
        const {initializing} = action.payload;

        return {
            ...state,
            initializing
        };
    },
    ERROR: (state, action) => {
        return {
            ...state,
            initializing: false,
            isInitialized: true,
            error: action.payload,
            isAuthenticated: false,
            organization: null,
            isDriver: false,
            user: null,
        }
    },
    INITIALIZE: (state, action) => {
        const {isAuthenticated, user, organization} = action.payload;

        if (debug) console.log({isAuthenticated, user, organization});

        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (user && timeZone !== user.timeZone) {
            axios.post('/api/accounts/users', {users: [{_id: user?._id, timeZone}]})
        }
        let isDriver = false
        if (user && organization) {
            const role = find(user.permissions, {id: organization?._id});
            isDriver = role.role === 'Driver'
        }
        return {
            ...state,
            error: null,
            initializing: false,
            isAuthenticated,
            isInitialized: true,
            organization,
            isDriver,
            user,
        };
    },
    LOGIN: (state, action) => {
        const {user, organization} = action.payload;

        let isDriver = false
        if (user && organization) {
            const role = find(user.permissions, {id: organization?._id});
            isDriver = role.role === 'Driver'
        }

        return {
            ...state,
            error: null,
            initializing: false,
            isAuthenticated: true,
            organization,
            isDriver,
            user,
        };
    },
    UPDATE_WORK_STATUS: (state, action) => {
        const {workStatus} = action.payload;
        return {
            ...state,
            user: {
                ...state.user,
                workStatus
            },
        };
    },
    LOGOUT: (state) => ({
        ...state,
        error: null,
        initializing: false,
        isAuthenticated: false,
        organization: null,
        isDriver: false,
        user: null,
    }),
};

const reducer = (state, action) =>
    handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
    ...initialAuthState,
    method: 'Auth0',
    auth0Client: null,
    isDriver: false,
    refreshUser: () => Promise.resolve(),
    updateWorkStatus: () => Promise.resolve(),
    loginWithRedirect: () => Promise.resolve(),
    logout: () => Promise.resolve(),
});

const AuthProvider = (props) => {
    const {children} = props;
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    const buildUserObject = (user, doloomaUser) => {
        if (doloomaUser?._id && doloomaUser?.userId) {
            axios.post(`/api/accounts/users`, {
                users: [{
                    _id: doloomaUser?._id,
                    status: 'active',
                    emailVerified: user.email_verified,
                }],
            });
        }
        return {
            userId: user.sub,
            avatar: user.picture,
            email: user.email,
            name: user.name,
            firstName: user.given_name,
            lastName: user.family_name,
            role: user.role,
            location: user.location,
            username: user.username,
            nickname: user.nickname,
            doloomaPermissionsConnections: user['dolooma-permissions/connections'],
            doloomaPermissionsRoles: user['dolooma-permissions/roles'],
            ...doloomaUser,
            emailVerified: user.email_verified,
        };
    };

    const redirectAtLogin = (user, organization, redirect) => {

        const role = find(user.permissions, {id: organization?._id});
        if (role?.role === 'Driver') {
            return DRIVER_PATH_AFTER_LOGIN
        }

        if (organization?.type !== 'provider') {
            return CUSTOMER_PATH_AFTER_LOGIN
        }

        return redirect || PATH_AFTER_LOGIN;
    }

    useEffect(() => {
        const initialize = async () => {

            try {

                dispatch({
                    type: 'INITIALIZING',
                    payload: {
                        initializing: true
                    },
                });

                auth0Client = new Auth0Client({
                    useRefreshTokens: true,
                    redirect_uri: window.location.origin,
                    cacheLocation: 'localstorage',
                    ...AUTH0_API,
                });

                const pathRedirect = window.localStorage.getItem('loginUrl');

                const urlSearchParams = new URLSearchParams(window.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());

                if (params.code && params.state) {
                    try {
                        await auth0Client.handleRedirectCallback();
                    } catch (e) {
                        console.error('handleRedirectCallback error', e);
                    }
                    if (pathRedirect) {
                        window.localStorage.removeItem('loginUrl');
                        window.location.href = window.location.origin + pathRedirect;
                    } else {
                        window.history.replaceState({}, document.title, '/');
                    }
                } else if (params.error) {
                    dispatch({
                        type: 'ERROR',
                        payload: {
                            code: startCase(decodeURIComponent(params.error)),
                            message: decodeURIComponent(params.error_description),
                        },
                    });
                    return;
                } else {
                    await auth0Client.checkSession();
                }

                const isAuthenticated = await auth0Client.isAuthenticated();

                if (isAuthenticated) {
                    const user = await auth0Client.getUser();
                    const accessToken = await auth0Client.getTokenSilently();
                    const idToken = await auth0Client.getIdTokenClaims();

                    setSession(accessToken, idToken.__raw);

                    axios.auth0Client = auth0Client;

                    const accountResponse = await axios.get(`/api/organizations`, {
                        params: {
                            userId: user.sub,
                            userEmail: user.email
                        },
                    });

                    const {
                        data: {
                            organizations = [],
                            users = [],
                            auth0Users = []
                        },
                    } = accountResponse;
                    const organization = organizations[0] || null;
                    const doloomaUser = users[0] || null;
                    const auth0User = auth0Users[0] || null;

                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: buildUserObject({...user, ...auth0User}, doloomaUser),
                            organization,
                        },
                    });

                    const redirect = redirectAtLogin(buildUserObject({...user, ...auth0User}, doloomaUser), organization, pathRedirect);
                    if ((!organization || organization.preRegistered) && window.location.pathname !== PATH_REGISTRATION.organization.root) {
                        window.location.pathname = PATH_REGISTRATION.organization.root;
                    } else if (redirect !== window.location.pathname && ['/dashboard', '/dashboard/app'].includes(window.location.pathname)) {
                        window.localStorage.removeItem('loginUrl');
                        window.location.pathname = redirect
                    }
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated,
                            user: null,
                        },
                    });
                    // loginWithRedirect(pathRedirect || '')
                }
            } catch (err) {
                console.error('auth error', err);
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        organization: null,
                    },
                });
                if (window.location.href !== window.location.origin && window.location.pathname !== "/auth/login") {
                    window.location.href = window.location.origin
                }
            }
        };

        initialize();
    }, []);

    const loginWithRedirect = async (sendTo) => {
        const redirectPath = sendTo || window.location.pathname;
        window.localStorage.setItem('loginUrl', redirectPath);

        await auth0Client.loginWithRedirect({
            redirect_uri: window.location.origin,
        });

    };

    const loginWithPopup = async (options) => {
        await auth0Client.loginWithPopup(options);

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
            const user = await auth0Client.getUser();

            const accountResponse = await axios.get(`/api/organizations`, {
                params: {
                    userId: user.sub,
                    userEmail: user.email
                },
            });

            const {
                data: {organizations = [], users = [], auth0Users = []},
            } = accountResponse;
            const organization = organizations[0] || null;
            const doloomaUser = users[0] || null;
            const auth0User = auth0Users[0] || null;

            dispatch({
                type: 'LOGIN',
                payload: {
                    user: buildUserObject({...user, ...auth0User}, doloomaUser),
                    organization,
                },
            });
        }
    };

    const refreshUser = async () => {

        const isAuthenticated = await auth0Client.isAuthenticated();

        if (isAuthenticated) {
            const accessToken = await auth0Client.getTokenSilently();
            const idToken = await auth0Client.getIdTokenClaims();

            setSession(accessToken, idToken.__raw);

            const user = await auth0Client.getUser();

            let organization = null;
            let doloomaUser = null;
            let auth0User = null;

            try {
                const accountResponse = await axios.get(`/api/organizations`, {
                    params: {
                        userId: user.sub,
                    },
                });

                const {
                    data: {organizations = [], users = [], auth0Users = []},
                } = accountResponse;
                organization = organizations[0] || null;
                doloomaUser = users[0] || null;
                auth0User = auth0Users[0] || null;

            } catch (e) {
                console.error(e);
            }

            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: buildUserObject({...user, ...auth0User}, doloomaUser),
                    organization,
                },
            });

        } else {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated,
                    user: null,
                    organization: null,
                },
            });
        }
    };

    const updateWorkStatus = async (workStatus) => {
        dispatch({
            type: 'UPDATE_WORK_STATUS',
            payload: {
                workStatus
            },
        });
    }
    const recollectAccountInfo = async () => refreshUser();

    const logout = async () => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('activeOrganization');
        window.localStorage.removeItem('activeWorkspace');
        await auth0Client.logout();
        dispatch({
            type: 'LOGOUT',
        });
        await new Promise(resolve => setTimeout(resolve, 1000));
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                auth0Client,
                method: 'Auth0',
                recollectAccountInfo,
                updateWorkStatus,
                refreshUser,
                loginWithPopup,
                loginWithRedirect,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export {AuthContext, AuthProvider};
