import PropTypes from 'prop-types';
// @mui
import {Box, Link} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
    large: PropTypes.bool,
};

export default function Logo({disabledLink = false, link = '/', sx, large}) {

    let localSX = {width: 40, height: 40, ...sx}

    if (large) {
        localSX = {width: 350, ...sx}
    }
    const logo = (
        <Box sx={localSX}>
            <img src={large ? '/logo/dolooma-logo.png' : '/logo/dolooma-logo2.png'} alt={'logo'}/>
        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <Link href={link}>{logo}</Link>;
}
