import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {Box} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
//
import {varContainer} from '.';

// ----------------------------------------------------------------------

ScrollViewport.propTypes = {
    children: PropTypes.node.isRequired,
    disableAnimatedMobile: PropTypes.bool,
};

export default function ScrollViewport({children, disableAnimatedMobile = true, ...other}) {
    const isDesktop = useResponsive('up', 'sm');

    if (!isDesktop && disableAnimatedMobile) {
        return <Box {...other}>{children}</Box>;
    }

    return (
        <Box
            component={m.div}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{once: true, amount: 0.8}}
            variants={varContainer()}
            {...other}
        >
            {children}
        </Box>
    );
}
