// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';
import {Box, Button, CircularProgress, Stack, useMediaQuery} from "@mui/material";
import Iconify from "./Iconify";
import {useRef} from "react";

// ----------------------------------------------------------------------

export default function MyAvatar({avatarOptions, isEdit, onChange, avatar, loading, ...other}) {
    const {user} = useAuth();

    const fileInputRef = useRef(null);

    const isMd = useMediaQuery(theme => theme.breakpoints.up('md'))

    const handleClickAttach = () => {
        fileInputRef.current?.click();
    };

    return (
        <Stack {...other} sx={{...other.sx, alignItems: isEdit ? 'center' : null}}>
            <Box sx={{
                position: 'relative',
                width: isEdit ? avatarOptions?.sx?.width || avatarOptions?.style?.width || 80 : null
            }}>
                <Avatar
                    src={avatar || user?.avatar}
                    alt={user?.name}
                    color={user?.avatar ? 'default' : createAvatar(user?.name).color}
                    {...avatarOptions}
                >
                    {createAvatar(user?.name).name}
                </Avatar>
                {isEdit && !loading && <Button
                    size={"small"}
                    onClick={handleClickAttach}
                    variant={"contained"}
                    sx={{
                        minWidth: '38px',
                        width: '38px',
                        position: 'absolute',
                        color: "#fff",
                        bottom: 0,
                        right: {md: 0, xs: -15},
                        border: theme => `${theme.palette.grey[200]} solid 1px`,
                        backgroundColor: theme => theme.palette.grey[200]
                    }}>
                    <Iconify icon={"mdi:pencil"} sx={{fontSize: 28, color: theme => theme.palette.grey[500]}}/>
                </Button>}
                {isEdit && loading &&
                    <Stack
                        alignItems={'center'}
                        justifyContent={'center'}
                        alignContent={'center'}
                        justifyItems={'center'}
                        sx={{
                            zIndex: theme => theme.zIndex.appBar + 10,
                            minWidth: '38px',
                            width: '38px',
                            position: 'absolute',
                            bottom: 0,
                            right: {md: 0, xs: -15},
                        }}
                    >
                        <CircularProgress
                            sx={{
                                color: isMd ? theme => theme.palette.primary.main : '#fff'
                            }}
                            size="1.5rem"
                        />
                    </Stack>}
            </Box>
            <input onChange={onChange} type="file" ref={fileInputRef} style={{display: 'none'}}/>
        </Stack>
    );
}
