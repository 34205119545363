import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {forwardRef, useState} from 'react';
// @mui
import {BottomNavigation, BottomNavigationAction, Box, Paper} from '@mui/material';
import Loading from "./page/Loading";
import SetupComplete from "./page/SetupComplete";
import Alex from './alex'
import useAuth from "../hooks/useAuth";
import Iconify from "./Iconify";
import {useFlags} from "launchdarkly-react-client-sdk";

// ----------------------------------------------------------------------

const BlendedImage = ({src, alt, home}) => {
    return (
        <Box
            sx={{
                zIndex: -10,
                position: 'fixed',
                width: '100%',
                height: '125vh',
                overflow: 'hidden',
                top: 0,
                left: 100,
                opacity: home ? 0.5 : 0.18,
                backgroundImage: `url(${src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                maskImage: 'radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 62%)',
                WebkitMaskImage: 'radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 62%)',
            }}
            aria-label={alt}
        />
    );
};

// ----------------------------------------------------------------------


const Page = forwardRef((
    {
        children,
        title = '',
        onBottomNavChange = () => {
        },
        meta,
        loading = false,
        loadingMessage = '',
        home = false,
        ...other
    },
    ref
) => {
    const {isAuthenticated, isDriver} = useAuth();

    const {featureAiAlex: alexEnabled} = useFlags()

    const [value, setValue] = useState(0);

    return (
        <>
            <BlendedImage
                home={home}
                src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/home/dolooma_welcome.png`}
                alt={"welcome"}/>
            <Helmet>
                <title>{`${title} | Dolooma`}</title>
                {meta}
            </Helmet>

            <SetupComplete/>

            <Box
                ref={ref} {...other}>
                {loading && <Loading message={loadingMessage}/>}
                {!loading && children}
                {!loading && isAuthenticated && !isDriver && alexEnabled && <Alex/>}
                {isDriver && <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                            onBottomNavChange(newValue)
                        }}
                    >
                        <BottomNavigationAction label="Map" icon={<Iconify icon={"uiw:map"}/>}/>
                        <BottomNavigationAction label="Info" icon={<Iconify icon={"material-symbols:info-outline"}/>}/>
                        <BottomNavigationAction label="Signature" icon={<Iconify icon={"ooui:signature-ltr"}/>}/>
                        <BottomNavigationAction label="Photo" icon={<Iconify icon={"arcticons:photo-pro"}/>}/>
                    </BottomNavigation>
                </Paper>}
            </Box>
        </>
    )
});

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    meta: PropTypes.node,
};

export default Page;
