import {createSlice} from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {flatMap, uniq} from "lodash";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    content: [],
    contentLoaded: false,
    item: null,
    sortBy: null,
    filterOptions: {
        makes: [],
        models: [],
        years: [],
        services: []
    },
    filters: {
        type: [],
        makes: [],
        models: [],
        years: [],
        services: [],
        dataOnly: false,
        category: 'All',
        priceRange: '',
        rating: '',
    },
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null,
    },
};

const slice = createSlice({
    name: 'item',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PRODUCTS
        getContentSuccess(state, action) {
            state.isLoading = false;
            state.contentLoaded = true;
            state.content = action.payload;
            state.filterOptions = {
                services: uniq(flatMap(action.payload, 'services')),
                makes: uniq(flatMap(action.payload, 'makes')),
                models: uniq(flatMap(action.payload, 'models')),
                years: uniq(flatMap(action.payload, 'years'))
            }
        },

        // GET PRODUCT
        getItemSuccess(state, action) {
            state.isLoading = false;
            state.item = action.payload;
        },

        //  SORT & FILTER PRODUCTS
        sortByContent(state, action) {
            state.sortBy = action.payload;
        },

        filterContent(state, action) {
            state.filters.type = action.payload.type;
            state.filters.category = action.payload.category;
            state.filters.priceRange = action.payload.priceRange;
            state.filters.rating = action.payload.rating;
            state.filters.services = action.payload.services;
            state.filters.makes = action.payload.makes;
            state.filters.models = action.payload.models;
            state.filters.years = action.payload.years;
            state.filters.dataOnly = action.payload.dataOnly;
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload;

            const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity));
            const discount = cart.length === 0 ? 0 : state.checkout.discount;
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
            const billing = cart.length === 0 ? null : state.checkout.billing;

            state.checkout.cart = cart;
            state.checkout.discount = discount;
            state.checkout.shipping = shipping;
            state.checkout.billing = billing;
            state.checkout.subtotal = subtotal;
            state.checkout.total = subtotal - discount;
        },

        addCart(state, action) {
            const item = action.payload;
            const isEmptyCart = state.checkout.cart.length === 0;

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, item];
            } else {
                state.checkout.cart = state.checkout.cart.map((_item) => {
                    const isExisted = _item.id === item.id;
                    if (isExisted) {
                        return {
                            ..._item,
                            quantity: _item.quantity + 1,
                        };
                    }
                    return _item;
                });
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, item], 'id');
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload);

            state.checkout.cart = updateCart;
        },

        resetCart(state) {
            state.checkout.activeStep = 0;
            state.checkout.cart = [];
            state.checkout.total = 0;
            state.checkout.subtotal = 0;
            state.checkout.discount = 0;
            state.checkout.shipping = 0;
            state.checkout.billing = null;
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1;
        },

        onNextStep(state) {
            state.checkout.activeStep += 1;
        },

        onGotoStep(state, action) {
            const goToStep = action.payload;
            state.checkout.activeStep = goToStep;
        },

        increaseQuantity(state, action) {
            const itemId = action.payload;
            const updateCart = state.checkout.cart.map((item) => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });

            state.checkout.cart = updateCart;
        },

        decreaseQuantity(state, action) {
            const itemId = action.payload;
            const updateCart = state.checkout.cart.map((item) => {
                if (item.id === itemId) {
                    return {
                        ...item,
                        quantity: item.quantity - 1,
                    };
                }
                return item;
            });

            state.checkout.cart = updateCart;
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload;
        },

        applyDiscount(state, action) {
            const discount = action.payload;
            state.checkout.discount = discount;
            state.checkout.total = state.checkout.subtotal - discount;
        },

        applyShipping(state, action) {
            const shipping = action.payload;
            state.checkout.shipping = shipping;
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
        },
    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    sortByContent,
    filterContent,
} = slice.actions;

// ----------------------------------------------------------------------

export function getContent() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/content');
            dispatch(slice.actions.getContentSuccess(response.data.content));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getItem(name) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/content', {
                params: {name},
            });
            dispatch(slice.actions.getItemSuccess(response.data.content[0] || {}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
