import React from "react";
import {Box} from "@mui/material";
import useNavigation from "../../../hooks/useNavigation";
import {TabsApple} from "../../../components/TabsApple";
import {PermissionsLoading} from "../../../contexts/PermissionsContext";
import useResponsive from "../../../hooks/useResponsive";
import {translateNavConfig} from '../../../utils/navTranslationUtil';
import useLocales from "../../../hooks/useLocales";

export default function TopNavigation() {
    const {translate} = useLocales();

    const {fullNavConfig} = useNavigation();

    const translatedNavConfig = translateNavConfig(fullNavConfig, translate);

    const lgUp = useResponsive('up', 'lg');

    return (
        <Box ml={2} sx={{minHeight: 10, background: 'transparent'}}>
            <PermissionsLoading topNavMenu message={translate("navs.sink.topNavigation.loadingPermissions")}>
                {lgUp && <TabsApple tabs={translatedNavConfig}/>}
            </PermissionsLoading>
        </Box>
    )
}
