import {useState} from 'react';
import {CardActions, IconButton, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

ChatMessageInput.propTypes = {
    submitMessage: PropTypes.func.isRequired,
    conversation: PropTypes.object.isRequired,
};

export default function ChatMessageInput({conversation, submitMessage}) {

    const [newMessage, setNewMessage] = useState('');

    const handleMessageChange = (event) => setNewMessage(event.target.value);

    const handleMessageKeyUp = (event) => {
        if (event?.code === 'Enter') {
            handleMessageSubmit();
        }
    };

    const handleMessageSubmit = () => {
        submitMessage(newMessage);
        setNewMessage('');
    };

    return (
        <CardActions>
            <TextField
                onKeyUp={handleMessageKeyUp}
                onChange={handleMessageChange}
                value={newMessage}
                variant={'outlined'}
                fullWidth
            />
            <IconButton onClick={handleMessageSubmit}>
                <Iconify icon={'ic:outline-send'} sx={{color: theme => theme.palette.primary.main}}/>
            </IconButton>
        </CardActions>
    );
}
