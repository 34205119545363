import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {alpha, styled} from '@mui/material/styles';
import {Box} from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';
import {useDispatch, useSelector} from "../redux/store";
import useAuth from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {getOrganization, getOrganizations, setActiveOrganization} from "../redux/slices/organization";
import {setOrganizationSession, setWorkspaceSession} from "../utils/jwt";
import {getWorkspaces} from "../redux/slices/workspaces";

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({src, theme}) => ({
    right: 0,
    bottom: 0,
    zIndex: 99999,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    '&::before': {
        content: '""',
        position: 'absolute', opacity: 0.20,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        maskImage: 'radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 68%)',
        WebkitMaskImage: 'radial-gradient(circle, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 68%)',
    },
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
    isDashboard: PropTypes.bool,
};

export default function LoadingScreen({isDashboard, ...other}) {

    const dispatch = useDispatch();

    const [bgSrc, setBgSrc] = useState('');

    setTimeout(() => setBgSrc(`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/images/home/dolooma_welcome.png`), 150)

    const {user} = useAuth();

    const {
        workspacesCollected,
        activeWorkspace,
    } = useSelector((state) => state.workspaces);

    const {
        activeOrganization,
        organization,
        organizationsCollected,
        organizationCollected,
        isLoading: loadingOrganization,
        isLoadingOrganizations
    } = useSelector((state) => state.organization);

    useEffect(() => {
        if (user && user.permissions?.length > 0 && !loadingOrganization && !organizationCollected) {
            dispatch(getOrganization(user.permissions[0].id, window.localStorage.getItem('activeOrganization')));
        }
    }, [user, dispatch, loadingOrganization, organizationCollected]);

    useEffect(() => {
        if (user && user.permissions?.length > 0 && !isLoadingOrganizations && !organizationsCollected) {
            dispatch(getOrganizations(user.permissions[0].id, window.localStorage.getItem('activeOrganization')));
        }
    }, [user, dispatch, loadingOrganization, organizationCollected, organizationsCollected, isLoadingOrganizations]);

    useEffect(() => {
        if (organizationsCollected && organizationCollected && !activeOrganization) {
            dispatch(setActiveOrganization(organization));
        }
    }, [organizationsCollected, activeOrganization, organizationCollected, organization, dispatch]);

    useEffect(() => {
        if (activeOrganization) {
            setOrganizationSession(activeOrganization?._id);
        }
    }, [activeOrganization]);

    useEffect(() => {
        if (activeWorkspace) {
            setWorkspaceSession(activeWorkspace?._id);
        }
    }, [activeWorkspace]);

    useEffect(() => {
        if (activeOrganization && !workspacesCollected) {
            dispatch(getWorkspaces(activeOrganization?._id, window.localStorage.getItem('activeWorkspace')));
        }
    }, [activeOrganization, dispatch, workspacesCollected]);

    return (
        <Box sx={{position: 'relative'}}>>
            <ProgressBar/>
            {!isDashboard && (
                <RootStyle
                    src={bgSrc} {...other}>
                    <m.div
                        initial={{rotateY: 0}}
                        animate={{rotateY: 360}}
                        transition={{
                            duration: 2,
                            ease: 'easeInOut',
                            repeatDelay: 1,
                            repeat: Infinity,
                        }}
                    >
                        <Logo disabledLink sx={{width: 64, height: 64}}/>
                    </m.div>

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1.2, 1, 1, 1.2, 1.2],
                            rotate: [270, 0, 0, 270, 270],
                            opacity: [0.25, 1, 1, 1, 0.25],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        }}
                        transition={{ease: 'linear', duration: 3.2, repeat: Infinity}}
                        sx={{
                            width: 100,
                            height: 100,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                        }}
                    />

                    <Box
                        component={m.div}
                        animate={{
                            scale: [1, 1.2, 1.2, 1, 1],
                            rotate: [0, 270, 270, 0, 0],
                            opacity: [1, 0.25, 0.25, 0.25, 1],
                            borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                        }}
                        transition={{
                            ease: 'linear',
                            duration: 3.2,
                            repeat: Infinity,
                        }}
                        sx={{
                            width: 120,
                            height: 120,
                            borderRadius: '25%',
                            position: 'absolute',
                            border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                        }}
                    />
                </RootStyle>
            )}
        </Box>
    );
}
