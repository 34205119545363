import {useState} from 'react';

// ----------------------------------------------------------------------

export default function useTabs(defaultValue) {
    const [currentTab, setCurrentTab] = useState(defaultValue || '');

    return {
        currentTab,
        onChangeTab: (event, newValue) => {
            setCurrentTab(newValue);
        },
        setCurrentTab,
    };
}
