import {m} from 'framer-motion';
// @mui
import {styled} from '@mui/material/styles';
import {Box, Button, Container, Typography} from '@mui/material';
// components
import Page from '../components/Page';
import {MotionContainer, varBounce} from '../components/animate';
// assets
import {PageNoAccessIllustration} from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Page401() {
    const goBack = () => window.history.back()
    return (
        <Page title="404 Page Not Found" sx={{height: 1}}>
            <RootStyle>
                <Container component={MotionContainer}>

                    <Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
                        <m.div variants={varBounce().in}>
                            <Typography variant="h3" paragraph>
                                Nice try, but you don't have access to this page!
                            </Typography>
                        </m.div>
                        <Typography sx={{color: 'text.secondary', marginBottom: 2}}>
                            It looks like you’ve stumbled upon a page you’re not supposed to see. Don’t worry, we all
                            get curious sometimes!
                        </Typography>
                        <Typography sx={{color: 'text.secondary', marginBottom: 2}}>
                            Maybe try a different page or head back to safety?
                        </Typography>

                        <m.div variants={varBounce().in}>
                            <PageNoAccessIllustration sx={{height: 260, my: {xs: 5, sm: 10}}}/>
                        </m.div>

                        <Button onClick={goBack} size="large" variant="contained">
                            Back
                        </Button>

                    </Box>
                </Container>
            </RootStyle>
        </Page>
    );
}
