import {dispatch, useSelector} from "../redux/store";
import {setContextIndex, setNavigation, setNavigationLoaded} from "../redux/slices/navigation";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {findIndex} from "lodash";
import {getActive} from "../components/nav-section";
import navConfig from "../layouts/dashboard/navbar/NavConfig";

// ----------------------------------------------------------------------
export default function useNavigation() {

    const {navigation, fullNavigation, navContext} = useSelector((state) => state.navigation);

    const {pathname} = useLocation();

    const {alerts} = useSelector((state) => state.alerts);

    const resetNavigation = () => {
        let navigationConfiguration = navConfig(alerts);
        dispatch(setNavigation(navigationConfiguration, navigationConfiguration));
        dispatch(setNavigationLoaded(true));
    }

    useEffect(() => {
        const index = findIndex(fullNavigation, ni => {
            return ni.items.some(i => {
                return getActive(i.path, pathname)
            })
        });
        if (navContext === -1) {
            dispatch(setContextIndex(index))
        }
    }, [pathname, fullNavigation, navContext]);

    return {
        setNavigationContext: (index) => {
            dispatch(setContextIndex(index))
        },
        resetNavigation,
        navContext,
        fullNavConfig: fullNavigation,
        navConfig: navigation,
    };
}
