import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    alertsCollected: false,
    notificationsCollected: false,
    unprocessedAlerts: [],
    alerts: {byId: {}, allIds: []},
    notifications: {byId: {}, allIds: []},
};

function objFromArray(array, key = '_id') {
    return array.reduce((accumulator, current) => {
        if (current.type === 'contract') {
            // TODO: Need to update contract alerts to provide this information
            accumulator[current[key]] = {...current, title: 'Contract Updated', description: 'Contract updated'};
        } else {
            accumulator[current[key]] = current;
        }
        return accumulator;
    }, {});
}


const slice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.alertsCollected = false;
        },

        // GET ALERTS
        getAlertsSuccess(state, action) {
            state.isLoading = false;
            state.alerts.byId = objFromArray(action.payload);
            state.alerts.allIds = Object.keys(state.alerts.byId);
            state.alertsCollected = true;
        },

        // GET ALERTS
        getNotificationsSuccess(state, action) {
            state.isLoading = false;
            state.notifications.byId = objFromArray(action.payload);
            state.notifications.allIds = Object.keys(state.notifications.byId);
            state.notificationsCollected = true;
        },

        // ADD ALERT
        addAlertSuccess(state, action) {
            const alert = action.payload[0];
            state.alerts.byId[alert._id] = alert;
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // RECEIVE ALERT
        alertReceivedSuccess(state, action) {
            const alert = action.payload;
            if (alert.type === "contract") {
                state.unprocessedAlerts.push({...alert, title: 'Contract Updated', description: 'Contract updated'})
                // state.alerts.byId[alert._id] = {...alert, title: 'Contract Updated', description: 'Contract updated'};
                // state.alerts.allIds = Object.keys(state.alerts.byId);
            } else {
                state.unprocessedAlerts.push(alert)
                // state.alerts.byId[alert._id] = alert;
                // state.alerts.allIds = Object.keys(state.alerts.byId);
            }
        },

        // PROCESS ALERT
        alertProcessedSuccess(state, action) {
            const unprocessedAlerts = action.payload;
            state.unprocessedAlerts = unprocessedAlerts;
        },

        // ADD ALERT
        removeAlertSuccess(state, action) {
            const alertId = action.payload;
            if (state.alerts.byId[alertId]) {
                delete state.alerts.byId[alertId]
            }
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // ADD ALERT
        markAlertAsRead(state, action) {
            const alerts = action.payload;
            alerts.forEach((alert) => {
                state.alerts.byId[alert._id] = alert;
            })
            state.alerts.allIds = Object.keys(state.alerts.byId);
        },

        // ADD ALERT
        updateAlertSuccess(state, action) {
            const alerts = action.payload;
            alerts.forEach((alert) => {
                state.alerts.byId[alert._id] = alert;
            })
            state.alerts.allIds = Object.keys(state.alerts.byId);
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addAlert(alert) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.put(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                alerts: [alert]
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });
            dispatch(slice.actions.addAlertSuccess(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateAlert(alert) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                alerts: [alert]
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });
            dispatch(slice.actions.updateAlertSuccess(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAlerts(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                params: {code: process.env.REACT_APP_COMMON_SERVICES_API_KEY, userId},
            });
            dispatch(slice.actions.getAlertsSuccess(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getNotifications(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts/notifications`, {
                params: {code: process.env.REACT_APP_COMMON_SERVICES_API_KEY, userId},
            });
            dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------
export function clearNotifications(userId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts/notifications`, {
                params: {code: process.env.REACT_APP_COMMON_SERVICES_API_KEY, userId},
            });
            dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markAlertAsRead(alertId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                alerts: [{_id: alertId, isUnRead: false}]
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeAlert(alertId) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            throw new Error('Who called me.')
        } catch (error) {
            console.warn(alertId, error);
        }
        try {
            await axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                params: {code: process.env.REACT_APP_COMMON_SERVICES_API_KEY, ids: alertId}
            });
            dispatch(slice.actions.removeAlertSuccess(alertId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function removeAllAlerts(alertIds) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    alerts: alertIds.join(',')
                }
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function markAllAlertAsRead(alertIds) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/alerts`, {
                alerts: alertIds.map((alertId) => ({_id: alertId, isUnRead: false}))
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });
            dispatch(slice.actions.markAlertAsRead(response.data.alerts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function alertReceived(alert) {
    return async () => {
        dispatch(slice.actions.alertReceivedSuccess(alert))
    }
}


// ----------------------------------------------------------------------

export function processAlert(unprocessedAlerts) {
    return async () => {
        dispatch(slice.actions.alertProcessedSuccess(unprocessedAlerts))
    }
}
