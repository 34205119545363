import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import uuidv4 from "../../utils/uuidv4";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    configurations: {
        contractTemplates: {
            "Empty Agreement": {
                title: "",
                identifier: uuidv4(),
                description: "",
                parties: [],
                contactInfo: [
                    {
                        value: "firstName",
                        required: true
                    }, {
                        value: "lastName",
                        required: true
                    }, {
                        value: "email",
                        required: true
                    }, {
                        value: "phoneNumber",
                        required: true
                    }],
                version: 0,
                dueDateAmount: 2,
                dueDate: 'weeks',
                effectiveDateAmount: 1,
                effectiveDate: 'days',
                expiryDateAmount: 1,
                expiryDate: 'years',
                status: "Draft",
                tags: [],
                progress: 0,
                sections: [],
                schedules: []
            }
        }
    },
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // STOP LOADING
        stopLoading(state) {
            state.isLoading = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getContractSuccess(state, action) {
            state.isLoading = false;
            state.configurations.contracts = {
                ...state.configurations.contracts,
                [action.payload.name]: action.payload.contract
            };
        },

        // GET SERVICES
        getContractTemplateSuccess(state, action) {
            state.isLoading = false;
            state.configurations.contractTemplates = {
                ...state.configurations.contracts,
                [action.payload.name]: action.payload.contractTemplates
            };
        },

        // GET SERVICES
        getContractTemplatesSuccess(state, action) {
            state.isLoading = false;
            state.configurations.contractTemplates = action.payload
        },

    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTowServiceContract() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/configurations/${encodeURI('Tow Service Agreement')}`);
            dispatch(slice.actions.getContractSuccess({
                contract: response.data.configurations[0],
                name: 'Tow Service Agreement'
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getTowServiceContractTemplate(templateName) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            if (templateName === "Empty Agreement") {
                dispatch(slice.actions.stopLoading());
                return;
            }
            const response = await axios.get(`/api/configurations/${encodeURI(templateName)}`);

            dispatch(slice.actions.getContractTemplateSuccess({
                contractTemplates: response.data.configurations[0] || {},
                name: 'Tow Service Agreement'
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getTowServiceContractTemplates() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {

            await axios.get(`/api/configurations`);

            dispatch(slice.actions.getContractTemplatesSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

