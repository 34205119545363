// '@mui
import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import useAuth from "./useAuth";
import {useSelector} from "../redux/store";
import {resetConnection, setConnection} from "../redux/slices/signalR";

// ----------------------------------------------------------------------

export default function useSignalR(hubName) {
    const dispatch = useDispatch()
    const {connection, connected, isLoading} = useSelector((state) => state.signalR);
    const {user} = useAuth();

    const connect = useCallback(() => {

        if (!connection[hubName] || !connected[hubName]) {

            let newConnection = new window.signalR.HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/${hubName}?code=${process.env.REACT_APP_COMMON_SERVICES_API_KEY}`)
                .withAutomaticReconnect()
                .configureLogging(window.signalR.LogLevel.Information)
                .build();

            newConnection.onclose(() => {
                dispatch(resetConnection(hubName))
                setTimeout(() => connect(), 2000);
            });

            newConnection.start().then(() => {
                dispatch(setConnection(newConnection, hubName))
            }).catch((error) => console.error('SignalR Connection Error', error));
        }

    }, [connected, connection, dispatch, hubName]);

    useEffect(() => {
        if (user && (!connection[hubName] || !connected[hubName])) {

            connect();
            return () => {
                if (connection[hubName] && connection[hubName].stop) {
                    connection[hubName].stop();
                    dispatch(resetConnection())
                }
            }
        }
    }, [user, connect, connection, dispatch, hubName, connected]);

    const applyMethod = (method, callback) => {
        connection[hubName] && connection[hubName].on(method, callback);
    }

    const removeMethod = (method, callback) => {
        connection[hubName] && connection[hubName].off(method, callback);
    }

    return [connection[hubName], applyMethod, removeMethod, connected[hubName], isLoading];
}
