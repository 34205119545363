import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    jobClientsLoading: null,
    jobClientsCollected: null,
    jobClients: [],
    membershipLevelsLoading: null,
    membershipLevelsCollected: null,
    membershipLevels: [],
};

const slice = createSlice({
    name: 'hoo',
    initialState,
    reducers: {
        // START LOADING
        startJobRequestClientsLoading(state) {
            state.jobClientsLoading = true;
        },

        startMembershipLevelsLoading(state) {
            state.membershipLevelsLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.jobClientsLoading = false;
            state.jobClientsCollected = true;
            state.error = action.payload;
        },

        // GET ROLE
        getJobRequestClientsSuccess(state, action) {
            state.jobClientsLoading = false;
            state.jobClientsCollected = true;
            state.jobClients = action.payload;
        },

        // GET ROLE
        getMembershipLevelsSuccess(state, action) {
            state.membershipLevelsLoading = false;
            state.membershipLevelsCollected = true;
            state.membershipLevels = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getJobRequestClients() {
    return async () => {
        dispatch(slice.actions.startJobRequestClientsLoading());
        try {
            const response = await axios.get('/api/jobs/clients');
            dispatch(slice.actions.getJobRequestClientsSuccess(response.data.clients));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getJobRequestMembershipLevels() {
    return async () => {
        dispatch(slice.actions.startMembershipLevelsLoading());
        try {
            const response = await axios.get('/api/jobs/membership-levels');
            dispatch(slice.actions.getMembershipLevelsSuccess(response.data.membershipLevels));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
