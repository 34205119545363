import PropTypes from 'prop-types';
import {noCase} from 'change-case';
import {useEffect, useState} from 'react';
// @mui
import {useTheme} from "@mui/material/styles";
import {
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    ListSubheader,
    Tooltip,
    Typography,
} from '@mui/material';
// utils
import {fToNow} from '../../../utils/formatTime';
// _mock_
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import {IconButtonAnimate} from '../../../components/animate';
import {
    PATH_BUSINESS_CENTER,
    PATH_CUSTOMER,
    PATH_DASHBOARD,
    PATH_OPERATIONS_CENTER,
    PATH_RESOURCE_CENTER
} from "../../../routes/paths";
import {useNavigate} from "react-router-dom";
import {dispatch, useSelector} from "../../../redux/store";
import {filter, keys, startCase} from "lodash";
import {isAfter, isBefore} from "date-fns";
import {updateAlert} from "../../../redux/slices/alerts";
import {useTranslation} from "react-i18next";
import {ALERT_TYPES} from "../../../config";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    const {t} = useTranslation();

    const [notifications, setNotifications] = useState([]);

    const {alerts} = useSelector((state) => state.alerts);

    const navigate = useNavigate();

    const theme = useTheme();

    const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMarkAllAsRead = () => {
        setNotifications(
            notifications.map((notification) => ({
                ...notification,
                isUnRead: false,
            }))
        );
    };

    useEffect(() => {
        const ids = keys(alerts?.byId)
        if (ids.length > 0) {
            setNotifications(ids.map((id) => alerts.byId[id]))
        } else if (alerts.allIds?.length <= 0) {
            setNotifications([])
        }
    }, [alerts])

    const oldAlert = (alert) => alert.isUnRead && isBefore(new Date(alert.createdDate), (new Date()).setHours((new Date()).getHours() - 4))

    const newAlert = (alert) => alert.isUnRead && isAfter(new Date(alert.createdDate), (new Date()).setHours((new Date()).getHours() - 4))

    return (
        <>
            <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{width: 40, height: 40}}>
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="line-md:bell-twotone" sx={{color: theme.palette.primary.main}} width={24}
                             height={24}/>
                </Badge>
            </IconButtonAnimate>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{width: 360, p: 0, mt: 1.5, ml: 0.75}}
            >
                <Box sx={{display: 'flex', alignItems: 'center', py: 2, px: 2.5}}>
                    <Box sx={{flexGrow: 1}}>
                        <Typography variant="subtitle1">Notifications</Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                            You have {totalUnRead} unread messages
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20}/>
                            </IconButtonAnimate>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Scrollbar sx={{height: {xs: 340, sm: 'auto'}}}>
                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                New
                            </ListSubheader>
                        }
                    >
                        {filter(notifications, newAlert).length <= 0 &&
                            <ListItem>
                                <ListItemText primary={t('No messages')}/>
                            </ListItem>
                        }
                        {filter(notifications, newAlert).slice(0, 5).map((notification) => (
                            <NotificationItem key={notification._id} notification={notification} onClose={handleClose}/>
                        ))}
                        {filter(notifications, newAlert).length > 5 &&
                            <ListItem>
                                <ListItemText primary={t(`${filter(notifications, newAlert).length - 5} more ...`)}/>
                            </ListItem>
                        }
                    </List>

                    <List
                        disablePadding
                        subheader={
                            <ListSubheader disableSticky sx={{py: 1, px: 2.5, typography: 'overline'}}>
                                Before that
                            </ListSubheader>
                        }
                    >
                        {filter(notifications, oldAlert).length <= 0 &&
                            <ListItem>
                                <ListItemText primary={t('No messages')}/>
                            </ListItem>
                        }
                        {filter(notifications, oldAlert).slice(0, 5).map((notification) => (
                            <NotificationItem key={notification._id} notification={notification} onClose={handleClose}/>
                        ))}
                        {filter(notifications, oldAlert).length > 5 &&
                            <ListItem>
                                <ListItemText primary={t('...')}/>
                            </ListItem>
                        }
                    </List>
                </Scrollbar>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Box sx={{p: 1}}>
                    <Button fullWidth disableRipple onClick={() => {
                        handleClose()
                        navigate(PATH_BUSINESS_CENTER.user.profile)
                    }}>
                        View All
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        createdDate: PropTypes.string,
        id: PropTypes.string,
        isUnRead: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        avatar: PropTypes.any,
    }),
};

function NotificationItem({notification, onClose}) {
    const {avatar, title} = renderContent(notification);
    const {jobRequest} = notification;
    const navigate = useNavigate()

    const {activeOrganization} = useSelector((state) => state.organization);

    const markRead = () => {
        dispatch(updateAlert({...notification, isUnRead: false, count: 0}));
        const BASE_PATH = activeOrganization.type === "provider" ? PATH_DASHBOARD : PATH_CUSTOMER;

        if (notification.type === ALERT_TYPES.CHAT_MESSAGE) {
            onClose();
            navigate(PATH_OPERATIONS_CENTER.chat.root)
        }
        if (notification.type === ALERT_TYPES.JOB_REQUEST_ACCEPT) {
            onClose();
            navigate(BASE_PATH.jobRequest.view, {state: {_id: jobRequest}});
        }
        if (notification.type === 'support_request') {
            onClose();
            navigate(PATH_RESOURCE_CENTER.request.root)
        }
        if (notification.type === ALERT_TYPES.NEW_BID) {
            onClose();
            navigate(BASE_PATH.networkBid.view, {state: {_id: notification.bidId}});
        }
        if (notification.type === ALERT_TYPES.BID_UPDATED) {
            onClose();
            navigate(BASE_PATH.networkBid.view, {state: {_id: notification.bidId, offerId: notification.offerId}});
        }
        if (notification.type === ALERT_TYPES.OFFER_ACCEPTED) {
            onClose();
            navigate(BASE_PATH.contract.edit, {state: {_id: notification.contractId}});
        }
        if (notification.type === ALERT_TYPES.CONTRACT_CREATED) {
            onClose();
            navigate(BASE_PATH.contract.root)
        }
        if (notification.type === ALERT_TYPES.DOCUMENT_IMPORTED) {
            onClose();
            navigate(BASE_PATH.document.list)
        }
    }

    return (
        <ListItemButton
            onClick={markRead}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(notification.isUnRead && {
                    bgcolor: 'action.selected',
                }),
            }}
        >
            <ListItemAvatar>
                <Avatar sx={{bgcolor: 'background.neutral'}}>{avatar}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                            color: 'text.disabled',
                        }}
                    >
                        <Iconify icon="eva:clock-outline" sx={{mr: 0.5, width: 16, height: 16}}/>
                        {fToNow(notification.createdDate)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
    const title = (
        <Typography variant="subtitle2">
            {notification.title || startCase(notification.type) || 'Alert'}
            <Typography component="span" variant="body2" sx={{color: 'text.secondary'}}>
                &nbsp; {noCase(notification.description || '')}
            </Typography>
        </Typography>
    );

    if (notification.type === ALERT_TYPES.JOB_REQUEST) {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_package.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === ALERT_TYPES.NEW_BID) {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_package.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === 'order_placed') {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_package.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === ALERT_TYPES.CONTRACT_CREATED) {
        return {
            avatar: (
                <img
                    alt={notification.title || startCase(notification.type)}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_package.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === ALERT_TYPES.CONTRACT_UPDATED) {
        return {
            avatar: (
                <img
                    alt={notification.title || startCase(notification.type)}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/id_design.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === ALERT_TYPES.DOCUMENT_IMPORTED) {
        return {
            avatar: (
                <img
                    alt={notification.title || startCase(notification.type)}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_package.svg`}
                />
            ),
            title,
        };
    }

    if (notification.type === 'order_shipped') {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_shipping.svg`}
                />
            ),
            title,
        };
    }
    if (notification.type === 'mail') {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_mail.svg`}
                />
            ),
            title,
        };
    }
    if (notification.type === 'chat_message') {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_notification_chat.svg`}
                />
            ),
            title,
        };
    }
    if (notification.type === 'support_request') {
        return {
            avatar: (
                <img
                    alt={notification.title}
                    src={`https://${process.env.REACT_APP_STATIC_ASSETS}/web/assets/icons/ic_support.svg`}
                />
            ),
            title,
        };
    }
    return {
        avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar}/> : null,
        title,
    };
}
