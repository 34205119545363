import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {cloneDeep, each, filter, find, get, last, remove, uniqBy} from "lodash";
import uuidv4 from "../../utils/uuidv4";
import {buildDocumentPath} from "../../utils/file";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    folderPathsId: -1,
    selectedFile: null,
    isLoadingPaths: false,
    deletingFolder: false,
    deletingFile: false,
    isLoadingCurrentDocs: false,
    error: null,
    success: null,
    documentsCollected: false,
    currentDocumentsCollected: false,
    pathsCollected: false,
    documents: [],
    currentDocuments: [],
    labelOptions: [],
    paths: [],
    currentFolder: '',
    folderPaths: [],
    allFolderPathsByOrg: [],
};


function objFromArray(array, key = '_id') {
    return array.reduce((accumulator, current) => {
        accumulator[get(current, key)] = current;
        return accumulator;
    }, {});
}


const slice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        startLoadingCurrentDocuments(state) {
            state.isLoadingCurrentDocs = true;
        },
        // START LOADING
        stopLoadingCurrentDocuments(state) {
            state.isLoadingCurrentDocs = false;
        },

        // START LOADING
        startLoadingCurrentDocs(state) {
            state.isLoadingCurrentDocs = true;
        },

        // START LOADING
        startLoadingPaths(state) {
            state.isLoadingPaths = true;
        },

        // START LOADING
        stopLoadingPaths(state) {
            state.isLoadingPaths = false;
        },

        // HAS ERROR
        hasSuccess(state, action) {
            state.isLoading = false;
            state.success = action.payload;
        },

        // HAS ERROR
        setDeletingFolder(state, action) {
            state.deletingFolder = action.payload;
        },

        // HAS ERROR
        setDeletingFile(state, action) {
            state.deletingFile = action.payload;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getDocumentsSuccess(state, action) {
            state.isLoading = false;
            state.documentsCollected = true;
            state.documents = action.payload;
        },

        // GET SERVICES
        getCurrentDocumentsSuccess(state, action) {
            state.isLoadingCurrentDocs = false;
            state.currentDocumentsCollected = true;
            state.currentDocuments = action.payload;
        },

        // GET SERVICES
        getSelectedFileSuccess(state, action) {
            state.selectedFile = action.payload;
        },

        // GET SERVICES
        getCurrentFolderSuccess(state, action) {
            state.currentFolder = action.payload;
        },

        // GET SERVICES
        getPathsSuccess(state, action) {
            state.isLoadingPaths = false;
            state.pathsCollected = true;
            const {paths: foundPaths, organization} = action.payload
            if (foundPaths.length > 0 && find(foundPaths, path => path.company._id === organization)) {
                const path = find(foundPaths, path => path.company._id === organization);

                state.folderPathsId = path._id;
                state.allFolderPathsByOrg = objFromArray(foundPaths, 'company._id');

                let paths = cloneDeep(path.paths) || []

                if (!find(paths, {name: 'General'})) {
                    paths = [{
                        name: 'General',
                        identifier: '0'
                    }, ...paths];
                }

                if (!find(paths, {name: 'Contracts'})) {
                    paths = [{
                        name: 'Contracts',
                        identifier: '1'
                    }, ...paths];
                }

                state.folderPaths = paths;
                state.paths = paths;
            } else {
                state.folderPaths = [{
                    name: 'General',
                    identifier: '0'
                }, {
                    name: 'Contracts',
                    identifier: '1'
                }]
            }
        },

        // GET SERVICES
        getFolderPathsSuccess(state, action) {
            state.isLoadingPaths = false;
            state.pathsCollected = true;

            state.folderPaths = action.payload.length > 0 ? action.payload : [{
                name: 'General',
                identifier: '0'
            }, {
                name: 'Contracts',
                identifier: '1'
            }]
        },

        // GET SERVICES
        getLabelOptionsSuccess(state, action) {
            state.labelOptions = action.payload
        }
    }
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const setPaths = (paths, organization) => {

    return async () => {
        try {
            if (paths && organization) {
                dispatch(slice.actions.startLoadingPaths());
                dispatch(slice.actions.getPathsSuccess({paths, organization}));
            }
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------


const updateName = (data, path, newName) => {
    // Base case: If the path is empty or data is not an array, do nothing (no updates needed)
    if (path.length === 0 || !Array.isArray(data)) {
        return false;
    }

    // Iterate over the data array to find the correct path
    for (let item of data) {
        // Check if the current item's name matches the first element in the path
        if (item.name === path[0]) {
            // Check if this is the target level where the name should be updated
            if (path.length === 1) {
                // Check for sibling name conflicts
                if (data.some(sibling => sibling.name === newName && sibling !== item)) {
                    // If there's a sibling with the same new name, return false (do not update)
                    return false;
                } else {
                    // No conflict, update the name
                    item.name = newName;
                    return true; // Update successful
                }
            }
            // If not the final element, recurse into subPaths if they exist
            if (item.subPaths && Array.isArray(item.subPaths)) {
                return updateName(item.subPaths, path.slice(1), newName);
            }
        }
    }
    // If the path is not found or the element does not have subPaths, return false
    return false;
}

// ----------------------------------------------------------------------

export const getPath = (path, paths) => {
    if (!Array.isArray(path)) {
        return null;
    }
    const name = path.shift();
    const returnPath = find(paths, {name});
    if (path.length === 0) {
        return returnPath;
    }
    return getPath(path, returnPath?.subPaths);
}

// ----------------------------------------------------------------------

const searchFolderPathsDelete = (fp, i, p, pathArrayLength) => {
    let newFolderPaths = fp;
    let index = -1;
    newFolderPaths.forEach((f, fi) => {
        if (f.name === p && i === pathArrayLength) {
            index = fi;
        } else if (newFolderPaths[fi].subPaths) {
            searchFolderPathsDelete(newFolderPaths[fi].subPaths, i, p, pathArrayLength)
        }
    })

    if (index >= 0) {
        newFolderPaths.splice(index, 1)
    }

    return newFolderPaths;
}

// ----------------------------------------------------------------------

export function clearError() {
    return async () => {
        dispatch(slice.actions.hasError(""));
    }
}

// ----------------------------------------------------------------------

export function clearSuccess() {
    return async () => {
        dispatch(slice.actions.hasSuccess(""));
    }
}

// ----------------------------------------------------------------------

export function removeFolder(currentFolder, folderPaths, activeOrganization, handleClick, callback) {
    return async () => {
        try {
            dispatch(slice.actions.setDeletingFolder(true))

            const pathArray = currentFolder.split('/');
            let newFolderPaths = cloneDeep(folderPaths);
            pathArray.forEach((p, i) => {
                searchFolderPathsDelete(newFolderPaths, i, p, pathArray.length - 1)
            })

            await axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${activeOrganization._id}`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    path: currentFolder
                }
            })

            handleClick(`${currentFolder.replace(last(pathArray), '')}`)();
            dispatch(slice.actions.setDeletingFolder(false))
            dispatch(slice.actions.getFolderPathsSuccess(newFolderPaths))
            dispatch(slice.actions.hasSuccess('All files and folders were deleted!'));
            callback()
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error.message || 'All files and folders could not be deleted!'));
            // setDeletingFolder(false);
            // setShowDeleteFolder(false);
        }
    };
}

// ----------------------------------------------------------------------

export function addImportFolder(paths) {
    if (!find(paths, {name: "Imports"})) {
        
    }
}

// ----------------------------------------------------------------------

export function addNewFolder(currentFolder, folderPaths, newFolder, folderPathsId, activeOrganization, handleClick) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const pathArray = currentFolder.split('/');

            const newItem = {name: newFolder, identifier: uuidv4()};
            let newFolderPaths = cloneDeep(folderPaths);
            const parentFolder = getPath(pathArray, newFolderPaths);

            if (parentFolder?.subPaths?.some(p => p.name.trim().toLowerCase() === newFolder.trim().toLowerCase()) || (currentFolder === '' && newFolderPaths.some(p => p.name.trim().toLowerCase() === newFolder.trim().toLowerCase()))) {
                dispatch(slice.actions.hasError(`Cannot move file. A folder named ${newFolder.name} already exists for ${parentFolder?.name || 'top level'}.`));
                return;
            }

            if (currentFolder === '') {
                newFolderPaths = [
                    ...folderPaths,
                    newItem
                ]
            } else {
                if (parentFolder.subPaths) {
                    parentFolder.subPaths.push(newItem)
                } else {
                    parentFolder.subPaths = [newItem]
                }
            }

            let addResult = null;
            if (folderPathsId !== -1) {
                addResult = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${activeOrganization._id}`, {
                    path: {
                        _id: folderPathsId,
                        paths: newFolderPaths
                    }
                }, {
                    params: {
                        code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                    }
                })
            } else {
                addResult = await axios.put(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${activeOrganization._id}`, {
                    path: {
                        paths: newFolderPaths
                    }
                }, {
                    params: {
                        code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                    }
                })
            }

            dispatch(slice.actions.getPathsSuccess({
                paths: addResult.data.paths,
                organization: activeOrganization._id
            }));

            if (currentFolder) {
                handleClick(`${currentFolder}`)()
                handleClick(`${currentFolder}/${newFolder}`)()
            } else {
                handleClick(`${newFolder}`)()

            }
            dispatch(slice.actions.hasSuccess('Folder was added'));
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error.message || 'Folder could not be added!'));
        }
    };
}

// ----------------------------------------------------------------------

export function setSelectedFile(selectedFile) {
    return async () => {
        dispatch(slice.actions.getSelectedFileSuccess(selectedFile))
    }
}

// ----------------------------------------------------------------------

export function removeFile(activeOrganization, file, callback, currentDocuments, allDocuments) {
    return async () => {
        try {
            dispatch(slice.actions.setDeletingFile(true))

            await axios.delete(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files`, {
                params: {
                    isDocument: true,
                    ...file,
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })

            const {data: {labels}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/labels`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    path: buildDocumentPath(activeOrganization, '')
                }
            });

            // remove file from documents and all documents
            dispatch(slice.actions.getCurrentDocumentsSuccess(currentDocuments.filter(d => d._id !== file._id)))
            dispatch(slice.actions.getDocumentsSuccess(allDocuments.filter(d => d._id !== file._id)))
            dispatch(slice.actions.getLabelOptionsSuccess(labels))
            dispatch(slice.actions.setDeletingFile(false))
            dispatch(slice.actions.hasSuccess('File deleted!'));
            callback()
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error.message || "Document failed to upload!"));
        }
    }
}

// ----------------------------------------------------------------------

export function addFiles(values, setProgress, setCurrentFile, activeOrganization, currentFolder, documents, allDocuments, callback) {
    return async () => {
        try {

            const {files} = values;

            setProgress(0);

            for (let file of files) {
                const {
                    _id,
                    organizationOverride,
                    workspaceOverride,
                    path,
                    documentType,
                    fileType,
                    labels,
                    size,
                    effectiveDate,
                    expirationDate,
                    friendlyName,
                    version,
                    city,
                    county,
                    state,
                    federal
                } = file

                setCurrentFile(path);

                const formData = new FormData();
                formData.append('file', file);

                const params = {
                    _id,
                    fileName: path,
                    friendlyName,
                    documentType,
                    fileType,
                    size,
                    organizationOverride,
                    workspaceOverride,
                    isDocument: true,
                    effectiveDate,
                    expirationDate,
                    version,
                    labels: labels.join(','),
                    city,
                    county,
                    state,
                    federal,
                    path: buildDocumentPath(activeOrganization, currentFolder),
                    container: process.env.REACT_APP_ENV,
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }

                if (path) {
                    const fileAddResponse = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files`, formData, {
                        params,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    dispatch(slice.actions.getCurrentDocumentsSuccess(uniqBy([...fileAddResponse.data.files, ...documents], '_id')))
                    dispatch(slice.actions.getDocumentsSuccess(uniqBy([...fileAddResponse.data.files, ...allDocuments], '_id')))
                    dispatch(slice.actions.getSelectedFileSuccess(fileAddResponse.data.files[0]))
                    // setDocuments([...documents, ...fileAddResponse.data.files])
                } else {
                    const fileAddResponse = await axios.patch(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files`, {
                        files: [{
                            _id,
                            version,
                            friendlyName,
                            documentType,
                            effectiveDate,
                            expirationDate,
                            labels,
                            city,
                            county,
                            state,
                            federal
                        }]
                    });
                    dispatch(slice.actions.getCurrentDocumentsSuccess(uniqBy([...fileAddResponse.data.files, ...documents], '_id')))
                    dispatch(slice.actions.getDocumentsSuccess(uniqBy([...fileAddResponse.data.files, ...allDocuments], '_id')))
                    dispatch(slice.actions.getSelectedFileSuccess(fileAddResponse.data.files[0]))
                }
            }


            const labelOptionResponse = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/labels`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    path: buildDocumentPath(activeOrganization, '')
                }
            })
            dispatch(slice.actions.getLabelOptionsSuccess(labelOptionResponse.data.labels))
            callback();

            dispatch(slice.actions.hasSuccess("All documents have been uploaded!"));
        } catch (error) {
            callback();
            console.error(error)
            dispatch(slice.actions.hasError(error.message || "Document failed to upload!"));
        }
    }
}

// ----------------------------------------------------------------------

export function moveFolder(documents, folderPaths, newPath, folderPathsId, _id, activeOrganization, allDocuments) {
    return async () => {
        dispatch(slice.actions.startLoadingPaths());

        try {
            const currentFolderPaths = cloneDeep(folderPaths); // Deep clone current folder paths
            const newParentPath = newPath; // New parent path for the child folder
            const childPath = _id; // ID of the child folder to be moved
            const childFolder = cloneDeep(getPath(_id.split('/'), currentFolderPaths)); // Deep clone of the child folder object
            const newParentFolder = getPath(newPath.split('/'), currentFolderPaths); // Get the parent folder object based on the new path

            if (childFolder?.identifier === newParentFolder?.identifier) {
                dispatch(slice.actions.stopLoadingPaths());
                return; // If the child folder and parent folder are the same, stop processing
            }

            const currentParentPath = _id.split('/');
            currentParentPath.pop(); // Remove the last segment to get the current parent path
            const currentParentFolder = getPath(currentParentPath, currentFolderPaths);

            if (currentParentFolder?.identifier === newParentFolder?.identifier) {
                dispatch(slice.actions.stopLoadingPaths());
                return; // If the current parent folder and new parent folder are the same, stop processing
            }
            let newFilePathPrefix;
            let files;
            let originalFilePathPrefix;

            if (!newParentFolder) {
                // moving to Root
                originalFilePathPrefix = buildDocumentPath(activeOrganization, childPath)
                newFilePathPrefix = buildDocumentPath(activeOrganization, childFolder.name);

                if (find(currentFolderPaths, {name: childFolder.name})) {
                    dispatch(slice.actions.hasError(`Cannot move file. A folder named ${childFolder.name} already exists.`));
                    dispatch(slice.actions.stopLoadingPaths());
                    return; // If a folder with the same name exists in the new parent folder, stop processing and show error
                }

                // Remove the child folder from the current parent folder's sub-paths
                remove(currentParentFolder.subPaths, (p) => p.identifier === childFolder.identifier);

                // Add the child folder to the new parent folder's sub-paths
                currentFolderPaths.push(childFolder);

            } else if (!currentParentFolder) {
                // moving from Root
                originalFilePathPrefix = buildDocumentPath(activeOrganization, childPath);
                newFilePathPrefix = buildDocumentPath(activeOrganization, `${newParentPath}/${childFolder.name}`);

                if (find(newParentFolder.subPaths, {name: childFolder.name})) {
                    dispatch(slice.actions.hasError(`Cannot move file. A folder named ${childFolder.name} already exists under ${newParentFolder.name}.`));
                    dispatch(slice.actions.stopLoadingPaths());
                    return; // If a folder with the same name exists in the new parent folder, stop processing and show error
                }

                // Remove the child folder from the current parent folder's sub-paths
                remove(currentFolderPaths, (p) => p.identifier === childFolder.identifier);

                // Add the child folder to the new parent folder's sub-paths
                if (newParentFolder.subPaths) {
                    newParentFolder.subPaths.push(childFolder);
                } else {
                    newParentFolder.subPaths = [childFolder];
                }

            } else {

                // Get all documents under the child folder and its sub-folders
                originalFilePathPrefix = buildDocumentPath(activeOrganization, childPath);
                newFilePathPrefix = buildDocumentPath(activeOrganization, `/${newParentPath}/${childFolder.name}`);

                if (find(newParentFolder.subPaths, {name: childFolder.name})) {
                    dispatch(slice.actions.hasError(`Cannot move file. A folder named ${childFolder.name} already exists under ${newParentFolder.name}.`));
                    dispatch(slice.actions.stopLoadingPaths());
                    return; // If a folder with the same name exists in the new parent folder, stop processing and show error
                }

                // Remove the child folder from the current parent folder's sub-paths
                remove(currentParentFolder.subPaths, (p) => p.identifier === childFolder.identifier);

                // Add the child folder to the new parent folder's sub-paths
                if (newParentFolder.subPaths) {
                    newParentFolder.subPaths.push(childFolder);
                } else {
                    newParentFolder.subPaths = [childFolder];
                }
            }

            // collect all files to be moved
            files = filter(allDocuments, d => d.path.startsWith(originalFilePathPrefix));

            // Update the document paths and files
            await axios.patch(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/move`, {
                files: files.map(file => ({
                    _id: file._id,
                    path: file.path.replace(originalFilePathPrefix, newFilePathPrefix)
                }))
            });

            await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${activeOrganization._id}`, {
                path: {
                    _id: folderPathsId,
                    paths: currentFolderPaths
                }
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });

            // Update the Redux store with the new folder paths
            dispatch(slice.actions.getFolderPathsSuccess(currentFolderPaths));

            // Update the document paths in the Redux store
            const newDocuments = cloneDeep(documents);
            const newAllDocuments = cloneDeep(allDocuments);
            each(files, f => {
                let doc = find(newDocuments, {_id: f._id});
                if (doc) {
                    doc.path = doc.path.replace(originalFilePathPrefix, newFilePathPrefix);
                }
                doc = find(newAllDocuments, {_id: f._id});
                if (doc) {
                    doc.path = doc.path.replace(originalFilePathPrefix, newFilePathPrefix);
                }
            });

            dispatch(slice.actions.getDocumentsSuccess(newAllDocuments));
            dispatch(slice.actions.getCurrentDocumentsSuccess(newDocuments));
            dispatch(slice.actions.getCurrentFolderSuccess(newPath));
            dispatch(slice.actions.hasSuccess("Folders have been updated!"));
            dispatch(slice.actions.stopLoadingPaths());

        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function moveFile(activeOrganization, newPath, currentFolder, documents, _id, allDocuments) {
    return async () => {
        dispatch(slice.actions.startLoadingCurrentDocuments());
        try {
            const path = buildDocumentPath(activeOrganization, newPath)

            await axios.patch(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/move`, {
                files: [{
                    _id,
                    path
                }]
            })

            const newDocuments = cloneDeep(documents);
            find(newDocuments, {_id}).path = path;
            dispatch(slice.actions.getCurrentDocumentsSuccess(newDocuments.filter((d) => d.path === buildDocumentPath(activeOrganization, currentFolder))));
            dispatch(slice.actions.getDocumentsSuccess(allDocuments.map((d) => find(newDocuments, {_id: d._id}) || d)))
            dispatch(slice.actions.getDocumentsSuccess(newDocuments));
            dispatch(slice.actions.hasSuccess("Documents have been updated!"));
            dispatch(slice.actions.stopLoadingCurrentDocuments());
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopLoadingCurrentDocuments());
        }
    };
}

// ----------------------------------------------------------------------

export function setCurrentFolder(currentFolder) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getCurrentFolderSuccess(currentFolder));
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function changeFolderName(newFolderName, folderPathsId, documents, currentFolder, folderPaths, allDocuments, activeOrganization) {
    return async () => {

        try {
            let newPath = currentFolder.split('/');
            const newPaths = cloneDeep(folderPaths);
            const noDuplicates = updateName(newPaths, currentFolder.split('/'), newFolderName.trim());

            if (newFolderName.trim() === last(newPath)) {
                return;
            }
            if (!noDuplicates) {
                return;
            }
            dispatch(slice.actions.startLoadingPaths());

            newPath.pop();
            newPath.push(newFolderName.trim());
            newPath = newPath.join('/');

            const files = filter(allDocuments, d => d.path.startsWith(buildDocumentPath(activeOrganization, currentFolder)))

            await axios.patch(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/move`, {
                files: files.map(file => ({
                    _id: file._id,
                    path: file.path.replace(
                        buildDocumentPath(activeOrganization, currentFolder),
                        buildDocumentPath(activeOrganization, newPath)
                    )
                }))
            })

            await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${activeOrganization._id}`, {
                path: {
                    _id: folderPathsId,
                    paths: newPaths
                }
            }, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })

            const {data: {files: allFiles}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/list`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })

            const newDocuments = cloneDeep(documents);
            each(files, f => {
                const doc = find(newDocuments, {_id: f._id})
                if (doc) {
                    doc.path = buildDocumentPath(activeOrganization, newPath);
                }
            })

            dispatch(slice.actions.getCurrentFolderSuccess(newPath))
            dispatch(slice.actions.getDocumentsSuccess(allFiles))
            dispatch(slice.actions.getCurrentDocumentsSuccess(newDocuments))
            dispatch(slice.actions.getFolderPathsSuccess(newPaths))
            dispatch(slice.actions.hasSuccess("Documents have been updated!"));
            dispatch(slice.actions.stopLoadingPaths);
        } catch (e) {
            dispatch(slice.actions.hasError(e.message));
        }
    };
}

// ----------------------------------------------------------------------

export function getDocuments(activeOrganization) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const {data: {files}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/list`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })
            const {data: {labels}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/labels`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    path: buildDocumentPath(activeOrganization, '')
                }
            });
            dispatch(slice.actions.getLabelOptionsSuccess(labels))
            dispatch(slice.actions.getDocumentsSuccess(files));
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getCurrentDocuments(path) {
    return async () => {
        dispatch(slice.actions.startLoadingCurrentDocs());
        try {
            const {data: {files}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/list`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY,
                    path
                }
            })
            dispatch(slice.actions.getCurrentDocumentsSuccess(files));
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPaths(organization) {
    return async () => {
        dispatch(slice.actions.startLoadingPaths());
        try {
            const {data: {paths}} = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/files/paths/${organization}`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            })
            dispatch(slice.actions.getPathsSuccess({paths, organization}));
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
