import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {capitalize, groupBy, mapValues} from "lodash";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    locationsByOrg: {},
    isCaStateCoverageLoading: false,
    isUsStateCoverageLoading: false,
    isLoadingUsRegions: false,
    isLoadingCaRegions: false,
    locationsCollected: false,
    error: null,
    locations: [],
    location: null,
    caStateCoverage: null,
    caStateBoundaries: null,
    caStateCoverageLoaded: false,
    usStateCoverage: null,
    usStateBoundaries: null,
    usStateCoverageLoaded: false,
    caStateRegions: null,
    caStateRegionsLoaded: false,
    usStateRegions: null,
    usStateRegionsLoaded: false
};

function organizeByOrgWorkspace(data) {
    // Group by organization
    const groupedByOrg = groupBy(data, 'organization');

    // Transform to desired structure
    const result = mapValues(groupedByOrg, orgGroup => {
        return groupBy(orgGroup, 'workspace');
    });

    return result;
}

const slice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
        // START LOADING
        recollectLocations(state) {
            state.locationsCollected = false;
        },
        // START LOADING
        startLoadingLocation(state) {
            state.isLoadingLocation = true;
        },

        // START LOADING
        startLoadingRegions(state, action) {
            const country = action.payload
            state[`isLoading${capitalize(country)}Regions`] = true;
        },

        startStateCoverageLoading(state, action) {
            const country = action.payload
            state[`is${capitalize(country)}StateCoverageLoading`] = true;
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getLocationsSuccess(state, action) {
            state.isLoading = false;
            state.locationsCollected = true;
            state.locations = action.payload;
            state.locationsByOrg = organizeByOrgWorkspace(action.payload);
        },

        // GET ROLE
        getLocationSuccess(state, action) {
            state.isLoadingLocation = false;
            state.location = action.payload;
        },

        // GET ROLE
        getBoundarySuccess(state, action) {
            const {country, location} = action.payload;
            state.isLoading = false;// response.data.location.data.features
            state[`${country.trim()}StateCoverage`] = location;
            state[`${country.trim()}StateBoundaries`] = location.data.features;
            state[`is${country.trim()}StateCoverageLoading`] = false;
            state[`${country.trim()}StateCoverageLoaded`] = true;
        },

        // GET ROLE
        getRegionSuccess(state, action) {
            const {country, location} = action.payload;
            state[`${country}StateRegions`] = location;
            state[`${country}StateRegionsLoaded`] = true;
            state[`isLoading${capitalize(country)}Regions`] = false;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLocations() {
    return async () => {
        dispatch(slice.actions.startLoading());

        try {
            const response = await axios.get('/api/locations');
            dispatch(slice.actions.getLocationsSuccess(response.data.locations));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getLocation(_id) {
    return async () => {
        dispatch(slice.actions.startLoadingLocation());
        try {
            const response = await axios.get(`/api/locations`, {
                params: {_id},
            });
            dispatch(slice.actions.getLocationSuccess(response?.data?.locations ? response.data.locations[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function recollectLocations() {
    return async () => {
        try {
            dispatch(slice.actions.recollectLocations());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getBoundaries(country) {
    return async () => {
        dispatch(slice.actions.startStateCoverageLoading(country));
        try {
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/locations/boundaries/${country}`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });

            const location = response?.data?.location ? response.data.location : null
            dispatch(slice.actions.getBoundarySuccess({location, country}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getRegions(country) {
    return async () => {
        dispatch(slice.actions.startLoadingRegions(country));
        try {
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/locations/regions/${country}`, {
                params: {
                    code: process.env.REACT_APP_COMMON_SERVICES_API_KEY
                }
            });
            const location = response?.data?.location ? response.data.location : null
            dispatch(slice.actions.getRegionSuccess({location, country}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setLocation(location) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getLocationSuccess(location));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
