import {camelCase, each, filter} from 'lodash';
import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
import axios from '../../utils/axios';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    rolesCollected: false,
    error: null,
    roles: [],
    activeRoles: [],
    role: null,
};

const slice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET ROLES
        getRolesSuccess(state, action) {
            state.isLoading = false;
            state.rolesCollected = true;
            each(action.payload, (role) => {
                state[`${camelCase(role.name)}Role`] = role;
            });

            state.roles = action.payload;
            state.activeRoles = filter(action.payload, {active: true});
        },

        // GET ROLE
        getRoleSuccess(state, action) {
            state.isLoading = false;
            state.role = action.payload;
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRoles(configurationApplicationRoles) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getRolesSuccess(configurationApplicationRoles));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getRole(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/roles`, {
                params: {code: process.env.REACT_APP_COMMON_SERVICES_API_KEY, identifier},
            });
            dispatch(slice.actions.getRoleSuccess(response?.data?.roles ? response.data.roles[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setRole(role) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getRoleSuccess(role));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
