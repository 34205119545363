import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    labels: [],
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getLabelsSuccess(state, action) {
            state.isLoading = false;
            state.services = action.payload;
        },

    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLabels() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/label');
            dispatch(slice.actions.getLabelsSuccess(response.data.service));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/questions');
            dispatch(slice.actions.getServiceQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/problem/codes');
            dispatch(slice.actions.getServiceProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getServiceSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/services/symptoms');
            dispatch(slice.actions.getServiceSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getService(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/service`, {
                params: {identifier},
            });
            dispatch(slice.actions.getServiceSuccess(response?.data?.service ? response.data.service[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setService(service) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getServiceSuccess(service));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
