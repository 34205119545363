// @mui
import {Stack, Typography} from '@mui/material';
// hooks
// routes
// assets
import {DocIllustration} from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {

    return (
        <Stack
            spacing={3}
            sx={{px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block'}}
        >

            <DocIllustration sx={{width: 1}}/>

            <Typography variant={"subtitle1"} color={"text.secondary"}>Re-imagined our way.</Typography>
            <Typography variant={"subtitle1"} color={"text.secondary"} style={{marginTop: 0}}>Built your
                way!</Typography>
        </Stack>
    );
}
