import PropTypes from 'prop-types';
import {Navigate} from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {PATH_CUSTOMER, PATH_DASHBOARD} from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default function GuestGuard({children}) {
    const {isAuthenticated, company} = useAuth();

    if (isAuthenticated) {
        let path = '';
        switch (company?.type) {
            case 'provider':
                path = PATH_DASHBOARD.root
                break;
            case 'consumer':
                path = PATH_CUSTOMER.root
                break;
            default:
                path = PATH_DASHBOARD.root
                break;
        }
        return <Navigate to={path}/>;
    }

    return <>{children}</>;
}
