// routes
import {PATH_PAGE} from '../../routes/paths';
// components
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

const menuConfig = [
    {
        title: 'Home',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/',
    },
    {
        title: 'Products',
        hidden: true,
        icon: <Iconify icon={'icon-park-outline:ad-product'} {...ICON_SIZE} />,
        path: PATH_PAGE.products,
    },
    {
        title: 'Pricing',
        hidden: true,
        icon: <Iconify icon={'entypo:price-tag'} {...ICON_SIZE} />,
        path: PATH_PAGE.pricing,
    },
    {
        title: 'About Us',
        icon: <Iconify icon={'clarity:info-circle-line'} {...ICON_SIZE} />,
        path: PATH_PAGE.about
    },
    {
        title: 'Blog',
        icon: <Iconify icon={'carbon:blog'} {...ICON_SIZE} />,
        path: PATH_PAGE.blog
    },
    {
        title: 'FAQs',
        icon: <Iconify icon={'eva:question-mark-circle-outline'} {...ICON_SIZE} />,
        path: PATH_PAGE.faqs
    },
    {
        title: 'Login',
        icon: <Iconify icon={'mdi:login-variant'} {...ICON_SIZE} />,
        action: 'login',
    },
    {
        title: 'Sign Up',
        icon: <Iconify icon={'game-icons:archive-register'} {...ICON_SIZE} />,
        action: 'signup',
    }
];

export default menuConfig;
