import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    navLoaded: false,
    error: null,
    navigation: [],
    fullNavigation: [],
    navContext: -1,
};

const slice = createSlice({
    name: 'part',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getNavigationSuccess(state, action) {
            state.isLoading = false;
            const {fullNavigation, contextNavigation} = action.payload;
            state.navigation = contextNavigation;
            state.fullNavigation = fullNavigation;
        },

        // GET SERVICES
        setNavigationLoadedSuccess(state, action) {
            state.navLoaded = action.payload;
        },

        // GET SERVICE QUESTIONS
        getContextSuccess(state, action) {
            state.isLoading = false;
            const index = action.payload;
            state.navigation = [state.fullNavigation[index]];
            state.navContext = index;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setNavigation(fullNavigation, contextNavigation) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getNavigationSuccess({fullNavigation, contextNavigation}));
        } catch (error) {
            const newStack = error.stack.split('\n')
            console.error({error, newStack})
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setNavigationLoaded(status) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.setNavigationLoadedSuccess(status));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setContextIndex(index) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getContextSuccess(index));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

