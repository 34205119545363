import PropTypes from 'prop-types';
import {formatDistanceToNowStrict} from 'date-fns';
// @mui
import {styled} from '@mui/material/styles';
import {Avatar, Box, IconButton, Tooltip, Typography} from '@mui/material';
// components
import Iconify from '../Iconify';
import useLocales from "../../hooks/useLocales";
import {useNavigate} from "react-router-dom";
import {PATH_OPERATIONS_CENTER} from "../../routes/paths";
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
}));

const ContentStyle = styled('div')(({theme}) => ({
    maxWidth: 320,
    padding: theme.spacing(1.5),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
}));

const InfoStyle = styled(Typography)(({theme}) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

ChatMessageItem.propTypes = {
    message: PropTypes.object.isRequired,
    conversation: PropTypes.object.isRequired,
    participants: PropTypes.array.isRequired,
    onOpenLightbox: PropTypes.func,
    closeConversation: PropTypes.func.isRequired,
};

export default function ChatMessageItem({message, participants, conversation, onOpenLightbox, closeConversation}) {
    const {translate} = useLocales();
    const navigate = useNavigate()
    const sender = participants?.find((participant) => participant.role === message.role);
    const senderDetails =
        message.role !== 'assistant'
            ? {type: 'me'}
            : {avatar: sender?.avatar, name: sender?.name};

    const isMe = senderDetails.type === 'me';
    const isImage = message.contentType === 'image';
    // const isIcon = message.contentType === 'icon';
    const firstName = senderDetails.name && senderDetails.name.split(' ')[0];

    const icons = {
        '$scheduleIcon': {
            icon: 'grommet-icons:schedule-play',
            // func: setShowDateTimePicker,
            title: 'Use the date and time picker'
        },
        '$locationIcon': {
            icon: 'entypo:location',
            // func: setShowLocations,
            title: 'Choose a location'
        },
        '$mapIcon': {
            icon: 'bx:map-pin',
            // func: setShowMap,
            title: 'Use the map to show your location'
        },
        '$jobRequestIcon': {
            icon: 'carbon:batch-job',
            func: () => {
                closeConversation()
                navigate(PATH_OPERATIONS_CENTER.jobRequest.view, {state: {_id: conversation._id}})
            },
            title: 'View the details of the job request'
        }
    }

    const createBreaks = (p) => {
        const regex = /\\n/g;
        if (!p) {
            return p;
        }
        const messageParts = p.split(regex)
        const matches = p.match(regex)

        if (!p || !matches || matches.length <= 0) {
            return p;
        }
        return messageParts.map((p, index) => (
            <Box key={index}>
                {p}{matches && matches[index] && <><br/><br/></>}
            </Box>
        ))
    }


    const getMessageContent = (message) => {
        const iconRegex = /\$[a-zA-Z]+Icon/g;
        const messageParts = message.content.split(iconRegex)
        const matches = message.content.match(iconRegex)

        return messageParts.map((p, index) => {
            return (
                <Box key={index}>
                    {createBreaks(p)}{matches && matches[index] &&
                    <Tooltip arrow placement="top" title={translate(icons[matches[index]].title)}>
                        <IconButton
                            onClick={() => icons[matches[index]].func(true)}
                            color="primary"
                        >
                            <Iconify icon={icons[matches[index]].icon}/>
                        </IconButton>
                    </Tooltip>}
                </Box>
            )
        })
    }

    // const getDisplayMessage = (message) => {
    //     if (isImage) {
    //         return (
    //             <Image
    //                 alt='attachment'
    //                 src={message.body}
    //                 onClick={() => onOpenLightbox(message.body)}
    //                 sx={{borderRadius: 1, cursor: 'pointer', '&:hover': {opacity: 0.8}}}
    //             />
    //         );
    //     }
    //     if (isIcon) {
    //         return (<Iconify icon={message.body} sx={{fontSize: 32}}/>);
    //     }
    //     return (<Typography variant='body2'>{message.content}</Typography>);
    // };

    return (
        <RootStyle>
            <Box
                sx={{
                    display: 'flex',
                    ...(isMe && {
                        ml: 'auto',
                    }),
                }}
            >
                {senderDetails.type !== 'me' && (
                    <Avatar alt={senderDetails.name} src={senderDetails.avatar} sx={{width: 32, height: 32, mr: 2}}/>
                )}

                <div>
                    <InfoStyle
                        variant='caption'
                        sx={{
                            ...(isMe && {justifyContent: 'flex-end'}),
                        }}
                    >
                        {!isMe && `${firstName},`}&nbsp;
                        {formatDistanceToNowStrict(new Date(message.createdDate), {
                            addSuffix: true,
                        })}
                    </InfoStyle>

                    <ContentStyle
                        sx={{
                            ...(isMe && {color: 'grey.800', bgcolor: 'primary.lighter'}),
                            ...(isImage && {p: 0}),
                        }}
                    >
                        {getMessageContent(message)}
                    </ContentStyle>
                </div>
            </Box>
        </RootStyle>
    );
}
