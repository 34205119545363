import {createContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import useEnvironment from "../hooks/useEnvironment";
import {dispatch, useSelector} from "../redux/store";
import {setNavigation, setNavigationLoaded} from "../redux/slices/navigation";
import navConfig from "../layouts/dashboard/navbar/NavConfig";
import usePermissions from "../hooks/usePermissions";

const initialSession = {
    initialized: false,
    loading: false,
    role: null,
    navigation: [],
    fullNavigation: []
};

const NavigationContext = createContext({
    Session: initialSession,
    hasAccess: () => false,
}); // component props type

const NavigationProvider = ({children}) => {

    const {environmentReady, getCurrentOrgDetails} = useEnvironment();

    const {hasAccess, flagsLoaded} = usePermissions()

    const {alerts} = useSelector((state) => state.alerts);

    const {navLoaded} = useSelector((state) => state.navigation);

    useEffect(() => {
        let navigationConfiguration = navConfig(alerts);
        if (environmentReady && !navLoaded && flagsLoaded) {
            const contextNavigation = [];
            navigationConfiguration.forEach(subheader => {
                if (hasAccess(subheader)) {
                    contextNavigation.push({
                        ...subheader,
                        items: subheader.items.filter(item => hasAccess(item))
                    })
                }
            })
            dispatch(setNavigation(contextNavigation, contextNavigation));
            dispatch(setNavigationLoaded(true));
        }
    }, [flagsLoaded, navLoaded, alerts, environmentReady, getCurrentOrgDetails, hasAccess]);

    return (
        <NavigationContext.Provider
            value={{}}
        >
            {children}
        </NavigationContext.Provider>
    );
};

NavigationProvider.propTypes = {
    children: PropTypes.node,
};

export {NavigationProvider, NavigationContext};

