import numeral from 'numeral';
import {parsePhoneNumber} from 'awesome-phonenumber'
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInMonths,
    differenceInSeconds,
    differenceInYears
} from 'date-fns';

// ----------------------------------------------------------------------


export function round(number) {
    return Math.round(number * 100) / 100
}

export function convertMinutesToDetailedTime(totalMinutes) {
    // Calculate total seconds from minutes
    const totalSeconds = totalMinutes * 60;

    // Define the start date (we can use the Unix epoch)
    const startDate = new Date(0);

    // Calculate the end date by adding seconds to the start date
    const endDate = new Date(startDate.getTime() + totalSeconds * 1000);

    // Calculate differences for years, months, days, hours, minutes, and seconds
    const years = differenceInYears(endDate, startDate);
    const months = differenceInMonths(endDate, startDate) % 12;
    const days = differenceInDays(endDate, startDate) % 30; // Approximation, as month length varies
    const hours = differenceInHours(endDate, startDate) % 24;
    const minutes = differenceInMinutes(endDate, startDate) % 60;
    const seconds = differenceInSeconds(endDate, startDate) % 60;

    return {years, months, days, hours, minutes, seconds};
}

export function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours > 0 ? `${numeral(hours).format('00')}:` : ''}${numeral(minutes).format('00')}:${numeral(secs).format('00')}`;
}

export function fCurrency(number) {
    return numeral(number).format(Number.isInteger(number) ? '$0,0.00' : '$0,0.00');
}

export function fPhone(number, iso) {
    if (!number) {
        return number;
    }

    const pn = parsePhoneNumber(number, iso || 'us');
    return pn.getNumber('international');
}

export function fPercent(number) {
    return numeral(number / 100).format('0.0%');
}

export function fNumber(number, decimal) {
    return numeral(number).format(decimal ? '0.00' : '');
}

export function fShortenNumber(number) {
    return numeral(number).format('0.00a').replace('.00', '');
}

export function fShortenCurrency(number) {
    return numeral(number).format('$0.00a').replace('.00', '');
}

export function fData(number) {
    return numeral(number).format('0.0 b');
}

export function parseLocaleNumber(stringNumber, locale) {
    const formatter = new Intl.NumberFormat(locale);
    const parts = formatter.formatToParts(12345.6);
    const groupSymbol = parts.find(part => part.type === 'group').value;
    const decimalSymbol = parts.find(part => part.type === 'decimal').value;

    const cleanedString = stringNumber
        .replace(new RegExp('\\' + groupSymbol, 'g'), '')
        .replace(new RegExp('\\' + decimalSymbol), '.');

    return parseFloat(cleanedString);
}
