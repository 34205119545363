import {useRef, useState} from 'react';
import {
    Alert,
    alpha,
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    Divider,
    Grid,
    Link,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Stack,
    styled,
    Typography,
    useTheme,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {filter} from 'lodash';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from '../../../redux/store';
import {setActiveOrganization,} from '../../../redux/slices/organization';
import {getWorkspaces, setActiveWorkspace} from '../../../redux/slices/workspaces';
import Iconify from '../../../components/Iconify';
import {PATH_DASHBOARD} from '../../../routes/paths';
import usePermissions from "../../../hooks/usePermissions";
import {IconButtonAnimate} from "../../../components/animate";

const greyLight = {
    50: '#f9f9f9',
    100: '#eff3f5',
    200: '#D3E6F3',
    300: '#B1C9DC',
    400: '#8CA3BA',
    500: '#5F748D',
    600: '#455A79',
    700: '#2F4365',
    800: '#1E2E51',
    900: '#121F43',
};

const CardActionAreaWrapper = styled(CardActionArea)(
    ({theme}) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.palette.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`,
);

const MenuListWrapperSecondary = styled(MenuList)(
    ({theme}) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${greyLight['700']};

      &.Mui-selected {
          background: ${theme.palette.primary.light};
          color: ${greyLight['800']};
      };
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.palette.secondary.main};
          color: ${greyLight['800']};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`,
);

function HeaderMenu() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const theme = useTheme();

    const isRTL = theme.direction === 'rtl';

    const {
        workspaces,
        activeWorkspace,
    } = useSelector((state) => state.workspaces);

    const {
        activeOrganization,
        organizations,
        organization,
    } = useSelector((state) => state.organization);

    const ref = useRef(null);

    const [isOpen, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const ref2 = useRef(null);
    const [isOpen2, setOpen2] = useState(false);

    const handleOpen2 = () => {
        setOpen2(true);
    };

    const handleClose2 = () => {
        setOpen2(false);
    };

    const allOrgs = [...organizations, organization];

    const {hasAccess} = usePermissions();

    if (!hasAccess({flag: 'pkg_feature_basic_platform'})) {
        return null;
    }

    return (
        <>
            <Stack
                direction={isRTL ? 'row' : 'row-reverse'}
                sx={{
                    display: {xs: 'inline-flex', sm: 'none'},
                }}
            >
                {organizations?.length > 0 && <IconButtonAnimate
                    onClick={handleOpen}
                    color={"primary"}
                    sx={{
                        width: 40,
                        height: 40,
                        ...(isOpen && {bgcolor: 'action.selected'}),
                    }}
                >
                    <Iconify icon={"carbon:workspace-import"}/>
                </IconButtonAnimate>}
                {workspaces?.length > 0 && <IconButtonAnimate
                    onClick={handleOpen2}
                    color={"secondary"}
                    sx={{
                        width: 40,
                        height: 40,
                        ...(isOpen && {bgcolor: 'action.selected'}),
                    }}
                >
                    <Iconify icon={"octicon:organization-16"}/>
                </IconButtonAnimate>}
            </Stack>

            <Stack
                direction={isRTL ? 'row' : 'row-reverse'}
                sx={{
                    display: {xs: 'none', sm: 'inline-flex'},
                }}
            >
                {workspaces?.length > 1 && <Button
                    ref={ref}
                    onClick={handleOpen}
                    endIcon={<Iconify icon={'line-md:arrow-down-circle-twotone'} sx={{fontSize: 24,}}/>}
                    color='secondary'
                    size='small'
                    sx={{
                        mr: 1,
                        backgroundColor: `${theme.palette.secondary.main}`,
                        color: `${theme.palette.secondary.contrastText}`,
                        '.MuiSvgIcon-root': {
                            color: `${theme.palette.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`,
                        },

                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.palette.primary.main,
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.palette.secondary.main,
                                )}`,
                            },
                        },
                    }}
                >
                    <Stack spacing={1} alignItems={'flex-start'} sx={{height: '100%'}}>
                        {activeWorkspace?.name}
                    </Stack>
                </Button>}
                {organizations?.length > 0 && <Button
                    ref={ref2}
                    onClick={handleOpen2}
                    endIcon={<Iconify icon={'line-md:arrow-down-circle-twotone'} sx={{fontSize: 24,}}/>}
                    color='secondary'
                    size='small'
                    sx={{
                        mr: 1,
                        backgroundColor: `${theme.palette.secondary.main}`,
                        color: `${theme.palette.secondary.contrastText}`,

                        '.MuiSvgIcon-root': {
                            color: `${theme.palette.secondary.dark}`,
                            transition: `${theme.transitions.create(['color'])}`,
                        },

                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}`,
                            color: `${theme.palette.getContrastText(
                                theme.palette.primary.main,
                            )}`,

                            '.MuiSvgIcon-root': {
                                color: `${theme.palette.getContrastText(
                                    theme.palette.secondary.main,
                                )}`,
                            },
                        },
                    }}
                >
                    <Stack spacing={0} alignItems={'flex-start'}>
                        {activeOrganization?.name}
                    </Stack>
                </Button>}
            </Stack>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        background: alpha(greyLight['800'], 0.06),
                    }}
                    display='flex'
                    alignItems='flex-start'
                    justifyContent='space-between'
                >
                    <Box>
                        <Typography
                            sx={{
                                pb: 0.5,
                            }}
                            variant='h4'
                        >
                            {t('Workspaces')}
                        </Typography>
                        <Typography noWrap variant='subtitle2'>
                            {t('All currently active workspaces')}
                        </Typography>
                    </Box>
                    {false && <Link
                        onClick={() => {
                            navigate(PATH_DASHBOARD.workspace.root);
                            handleClose();
                        }}
                        variant='subtitle2'
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'none',
                            ml: 3,
                            display: 'inline-block',
                        }}
                    >
                        {t('View all')}
                    </Link>}
                </Box>
                <Divider/>
                <Stack
                    direction={{xs: 'column', sm: 'row'}}
                    divider={<Divider orientation='vertical' flexItem/>}
                    justifyContent='stretch'
                    alignItems='stretch'
                    spacing={0}
                >
                    <MenuListWrapperSecondary disablePadding>
                        {workspaces.map((workspace) => (
                            <MenuItem key={workspace?._id} selected={workspace?._id === activeWorkspace?._id}
                                      onClick={() => {
                                          handleClose();
                                          setTimeout(() => {
                                              dispatch(setActiveWorkspace(workspace));
                                          }, 10)
                                      }}>
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: 'h5',
                                    }}
                                    primary={workspace.name}
                                />
                                <Iconify icon={'line-md:chevron-right-circle-twotone'}
                                         sx={{
                                             ml: 2,
                                             color: workspace?._id === activeWorkspace?._id ? '' : `${greyLight['300']}`,
                                             opacity: 0.8,
                                         }}
                                />
                            </MenuItem>
                        ))}
                    </MenuListWrapperSecondary>
                </Stack>
            </Popover>
            <Popover
                disableScrollLock
                anchorEl={ref2.current}
                onClose={handleClose2}
                open={isOpen2}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '.MuiPopover-paper': {
                        background: `linear-gradient(127.55deg, ${theme.palette.grey["900"]} 3.73%, ${theme.palette.grey["700"]} 92.26%)`,
                    },
                }}
            >
                <Box
                    sx={{
                        maxWidth: 400,
                    }}
                    p={3}
                    display='flex'
                    alignItems='flex-start'
                    justifyContent='space-between'
                >
                    <Box>
                        <Typography
                            variant='h4'
                            gutterBottom
                            sx={{
                                color: '#ffffff',
                                fontSize: theme.typography.pxToRem(18),
                            }}
                        >
                            {t('Organizations')}
                        </Typography>
                        <Typography
                            variant='subtitle2'
                            textAlign='center'
                            sx={{
                                color: '#ffffff',
                            }}
                        >
                            {t('All sub-organizations you have access to')}
                        </Typography>
                    </Box>
                    {false && <Link
                        onClick={() => {
                            navigate(PATH_DASHBOARD.organization.root);
                            handleClose2();
                        }}
                        variant='subtitle2'
                        sx={{
                            cursor: 'pointer',
                            textTransform: 'none',
                            ml: 3,
                            display: 'inline-block',
                            color: theme.palette.secondary.lighter
                        }}
                    >
                        {t('View all')}
                    </Link>}
                </Box>
                <Grid container mt={1} spacing={2} sx={{p: 2}}>
                    {filter(allOrgs, (org) => org?._id !== activeOrganization?._id).length <= 0 &&
                        <Grid item xs={12} mt={-2}>
                            <Alert severity={"info"}>
                                <Typography variant='subtitle1'>{t('No Sub Organizations')}</Typography>
                            </Alert>
                        </Grid>
                    }
                    {filter(allOrgs, (org) => org?._id !== activeOrganization?._id).map((org) => (
                        <Grid key={org._id} item xs={12} sm={6}>
                            <Card>
                                <CardActionAreaWrapper
                                    sx={{
                                        p: 2,
                                    }}
                                    onClick={() => {
                                        dispatch(setActiveOrganization(org));
                                        dispatch(getWorkspaces(org._id))
                                        handleClose2();
                                    }}
                                >
                                    <Avatar src={org.avatar}/>
                                    <Typography variant='subtitle1'>{org.name}</Typography>
                                </CardActionAreaWrapper>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    );
}

export default HeaderMenu;
