import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    environmentReady: false,
    globalFilters: JSON.parse(localStorage.getItem('global-filters')),
    globalFiltersLoaded: false,
};

const slice = createSlice({
    name: 'environment',
    initialState,
    reducers: {
        setEnvironmentReady(state, action) {
            state.environmentReady = action.payload
        },

        setGlobalFilters(state, action) {
            state.globalFiltersLoaded = true
            state.globalFilters = action.payload
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setEnvironmentReady(ready) {
    return async () => {
        try {
            dispatch(slice.actions.setEnvironmentReady(ready));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setGlobalFilters(newFilters) {
    return async () => {
        try {
            localStorage.setItem('global-filters', JSON.stringify(newFilters));
            dispatch(slice.actions.setGlobalFilters(newFilters));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
