import PropTypes from 'prop-types';
import {m} from 'framer-motion';
// @mui
import {Box} from '@mui/material';
//
import {varFade} from './variants';

// ----------------------------------------------------------------------

TextAnimate.propTypes = {
    text: PropTypes.string.isRequired,
    variants: PropTypes.object,
    props: PropTypes.object,
    typography: PropTypes.string,
    sx: PropTypes.object
};

export default function TextAnimate({text, variants, sx, typography, props, ...other}) {

    let comp = m.h1;

    switch (typography) {
        case 'h2':
            comp = m.h2;
            break;
        case 'h3':
            comp = m.h3;
            break;
        case 'h4':
            comp = m.h4;
            break;
        case 'h5':
            comp = m.h5;
            break;
        case 'h6':
            comp = m.h6;
            break;
        default:
            comp = m.h1;
            break
    }
    return (
        <Box
            component={comp}
            sx={{
                typography: typography || 'h1',
                overflow: 'hidden',
                display: 'inline-flex',
                ...sx
            }}
            {...other}
        >
            {text.split('').map((letter, index) => (
                <m.span key={index} variants={variants || varFade(props).inUp}>
                    {letter}
                </m.span>
            ))}
        </Box>
    );
}
