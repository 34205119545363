import PropTypes from "prop-types";
import Settings from "../settings";
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Fab,
    Grid,
    IconButton,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {alpha, styled} from "@mui/material/styles";
import React, {useCallback, useEffect, useState} from "react";
import Iconify from "../Iconify";
import {AnimatePresence, m} from 'framer-motion';
import {varFade} from "../animate";
import {NAVBAR} from "../../config";
import useIndividualSignalR from "../../hooks/useIndividualSignalR";
import useTabs from "../../hooks/useTabs";
import {dispatch, useSelector} from "../../redux/store";
import {useSnackbar} from "notistack";
import {isEmpty, keys, startCase} from "lodash";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {addLog, clearLogs, setLogs} from "../../redux/slices/system";
import cssStyles from '../../utils/cssStyles';

// ----------------------------------------------------------------------

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        key: index
    };
}

const RootStyle = styled('span')(({theme}) => ({
    // ...cssStyles(theme).bgBlur({opacity: 0.64}),
    bottom: 0,
    left: '50%',
    position: 'fixed',
    marginTop: theme.spacing(-3),
    padding: theme.spacing(0.5),
    zIndex: theme.zIndex.drawer + 2,
    borderRadius: '24px 0 20px 24px',
    // boxShadow: `-12px 12px 32px -4px ${alpha(
    //     theme.palette.mode === 'light' ? theme.palette.grey[600] : theme.palette.common.black,
    //     0.36
    // )}`,
}));

// ----------------------------------------------------------------------

const SystemMessageRootStyle = styled(m.div)(({theme}) => ({
    ...cssStyles(theme).bgBlur({color: theme.palette.background.paper, opacity: 0.25}),
    bottom: 0,
    left: 0,
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 5,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}));

Settings.propTypes = {
    isDashboard: PropTypes.bool
};

let lastRan = Date.now();

function Imports() {

    const [connection, applyMethod, removeMethod, connected, isLoading] = useIndividualSignalR('system');

    const [listenerSet, setListenerSet] = useState(false)

    const {logs, lastAlert, initialized} = useSelector((state) => state.system);

    const {enqueueSnackbar} = useSnackbar();

    const {activeOrganization} = useSelector(state => state.organization);

    const [importProcessRunning, setImportProcessRunning] = useState(false);

    const [showDetails, setShowDetails] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!initialized) {
            dispatch(setLogs())
        }
    }, [initialized]);

    useEffect(() => {
        if (lastAlert) {
            const {done, message, severity} = lastAlert
            setImportProcessRunning(!isEmpty(lastAlert) && !done)

            const currentTime = Date.now();
            const timeDifferenceInSeconds = (currentTime - lastRan) / 1000;
            const timerRunning = timeDifferenceInSeconds <= 1

            if (!isEmpty(lastAlert) && ((!lastAlert.displayed && !timerRunning) || (timerRunning && !lastAlert.displayed && lastAlert.done))) {
                lastRan = Date.now();
                enqueueSnackbar(
                    `${lastAlert.process}: ${message}`,
                    {
                        variant: severity,
                        action: (<Button onClick={() => setOpen(true)}>Show Details</Button>)
                    }
                );
            }
        }
    }, [lastAlert, enqueueSnackbar])

    const messageReceived = useCallback((alert) => {
        console.log('messageReceived', alert)
        dispatch(addLog(alert))
    }, [])

    useEffect(() => {
        if (activeOrganization && messageReceived && !listenerSet && !isLoading && connected) {
            setListenerSet(true);
            removeMethod(activeOrganization._id, messageReceived);
            applyMethod(activeOrganization._id, messageReceived);
        }
    }, [connection, applyMethod, removeMethod, connected, isLoading, activeOrganization, listenerSet, messageReceived]);

    const handleClearMessaging = () => dispatch(clearLogs());

    const varSidebar = varFade({
        distance: NAVBAR.BASE_WIDTH,
        durationIn: 0.32,
        durationOut: 0.32,
    }).inUp

    const varButton = varFade({
        durationIn: 0.32,
        durationOut: 0.32,
    }).in

    return (
        <>
            {!open && importProcessRunning && <RootStyle>
                <Fab
                    variant="extended"
                    onClick={() => setOpen(true)}
                    onMouseOver={() => setShowDetails(true)}
                    onMouseLeave={() => setShowDetails(false)}
                >
                    <Iconify
                        sx={{color: theme => theme.palette.primary.contrastText}}
                        width={32}
                        height={32}
                        icon={!importProcessRunning ? "line-md:download-off-outline" : "line-md:downloading-loop"}
                    />
                    <AnimatePresence>
                        {showDetails && (
                            <m.div  {...varButton}>
                                Import Process {importProcessRunning ? 'Running' : 'Not Running'}
                            </m.div>
                        )}
                    </AnimatePresence>
                </Fab>
            </RootStyle>}
            <AnimatePresence>
                {open && (
                    <SystemMessageRootStyle {...varSidebar}>
                        <Container maxWidth={"lg"}>
                            <SystemMessages
                                logs={logs}
                                onClose={() => setOpen(false)}
                                handleClearMessaging={handleClearMessaging}
                            />
                        </Container>
                    </SystemMessageRootStyle>
                )
                }
            </AnimatePresence>
        </>
    );
}

export default Imports;

function SystemMessages(
    {
        logs,
        onClose,
        handleClearMessaging
    }
) {

    const {currentTab, onChangeTab} = useTabs(0)

    if (keys(logs).length === 0) {
        return (
            <Card>
                <CardContent>
                    <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
                        <Typography variant="h6">No Import Processing</Typography>
                        <Stack direction="row" alignItems={"center"} spacing={2}>
                            <IconButton onClick={onClose}>
                                <Iconify icon={"ic:twotone-close"}/>
                            </IconButton>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        )
    }

    return (
        <Card>
            <CardContent>
                <Stack pb={2} direction="row" alignItems={"center"} justifyContent={"space-between"}>
                    <Typography variant="h6">Imports Processing</Typography>
                    <Stack direction="row" alignItems={"center"} spacing={2}>
                        <IconButton onClick={handleClearMessaging}>
                            <Iconify icon={"pepicons-print:list-off"}/>
                        </IconButton>
                        <IconButton onClick={onClose}>
                            <Iconify icon={"ic:twotone-close"}/>
                        </IconButton>
                    </Stack>
                </Stack>
                <Divider/>
                <Grid container spacing={2}>
                    {keys(logs).length > 1 && <Grid item xs={3}>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                minHeight: 224,
                            }}
                        >
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={currentTab}
                                onChange={onChangeTab}
                                sx={{borderRight: 1, borderColor: 'divider', width: '100%'}}
                            >
                                {keys(logs).map((process, index) => (
                                    <Tab label={startCase(process)} {...a11yProps(index)}/>
                                ))}

                            </Tabs>
                        </Box>
                    </Grid>}
                    {keys(logs).length === 1 && <Grid item xs={12}>
                        <Box sx={{height: 500, overflowY: 'scroll'}}>
                            {keys(logs).map((process, index) => (
                                <Box key={index}>
                                    <Timeline>
                                        {logs[process]?.messages.map((log, index) => (
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent>
                                                    <Typography
                                                        color={`${log.severity}.main` || 'info.main'}
                                                        variant="body2"
                                                    >
                                                        {log.timestamp}
                                                    </Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={log.severity || 'info'}/>
                                                    {index < logs.length - 1 && <TimelineConnector/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant="body1">{log.message}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>
                                </Box>
                            ))}

                        </Box>
                    </Grid>}
                    {keys(logs).length > 1 && <Grid item xs={9}>
                        <Box sx={{height: 500, overflowY: 'scroll'}}>
                            {keys(logs).map((process, index) => (
                                <Box value={currentTab} key={index} index={index}>
                                    {currentTab === index && <Timeline>
                                        {logs[process]?.messages.map((log, index) => (
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent>
                                                    <Typography
                                                        color={`${log.severity}.main` || 'info.main'}
                                                        variant="body2"
                                                    >
                                                        {log.timestamp}
                                                    </Typography>
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={log.severity || 'info'}/>
                                                    {index < logs.length - 1 && <TimelineConnector/>}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant="body1">{log.message}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))}
                                    </Timeline>}
                                </Box>
                            ))}

                        </Box>
                    </Grid>}
                </Grid>

            </CardContent>
        </Card>
    )
}
