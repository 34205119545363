// @mui
import {Box, Stack} from '@mui/material';
import CallAgent from '../assets/call_agent.svg'
import Image from "../components/Image";

// ----------------------------------------------------------------------

export default function PhoneIllustration({...other}) {

    return (
        <Stack {...other} height={120} alignItems={"center"} alignContent={"center"}
               justifyContent={"center"}
               justifyItems={'center'}>
            <Box height={110}>
                <Image src={CallAgent}/>
            </Box>
        </Stack>
    );
}
