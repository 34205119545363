import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import numeral from 'numeral';
// '@mui
import {CURRENCIES, LANGS} from '../config'
import {enUS, es, frCA} from 'date-fns/locale'
// ----------------------------------------------------------------------

export default function useLocales() {
    const {i18n, t: translate} = useTranslation();
    const langStorage = localStorage.getItem('i18nextLng');
    const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0];
    const [currentCurrencySymbol, setCurrencySymbol] = useState('');
    const [decimalSeparator, setDecimalSeparator] = useState('.');
    const [thousandsSeparator, setThousandsSeparator] = useState(',');
    const [dateFnsLang, setDateFnsLang] = useState(enUS);

    useEffect(() => {
        numeral.locale(currentLang.value);
        setCurrencySymbol(CURRENCIES[currentLang.currencyCode].symbol.default.display)
        setDecimalSeparator(CURRENCIES[currentLang.currencyCode].delimiters.decimal)
        setThousandsSeparator(CURRENCIES[currentLang.currencyCode].delimiters.thousands)
        if (currentLang.value === "mx-es") {
            setDateFnsLang(es)
        }

        if (currentLang.value === "en-us") {
            setDateFnsLang(enUS)
        }

        if (currentLang.value === "fr-ca") {
            setDateFnsLang(frCA)
        }
    }, [currentLang])

    const handleChangeLanguage = (newlang) => {
        i18n.changeLanguage(newlang);
    };

    return {
        thousandsSeparator,
        decimalSeparator,
        onChangeLang: handleChangeLanguage,
        currentCurrencySymbol,
        translate,
        currentLang,
        allLang: LANGS,
        dateFnsLang
    };
}
