import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    logs: {},
    lastAlert: null,
    initialized: false,
    error: null,
};

const slice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        setLogsSuccess(state, action) {
            const logs = window.localStorage.getItem('logs') || "{}";
            const lastAlert = window.localStorage.getItem('lastAlert') || "{}";
            state.logs = JSON.parse(logs);
            state.lastAlert = JSON.parse(lastAlert);
            state.initialized = true;
        },
        addLogsSuccess(state, action) {
            const {alert} = action.payload;
            const {message, severity, process, start} = alert
            const newLogs = {
                ...state.logs,
                [process]: {
                    messages: start ? [
                        {
                            message,
                            severity,
                            timestamp: new Date().toLocaleTimeString()
                        }
                    ] : [
                        ...(state.logs[process]?.messages || []),
                        {
                            message,
                            severity,
                            timestamp: new Date().toLocaleTimeString()
                        }
                    ]
                }
            }
            window.localStorage.setItem('lastAlert', JSON.stringify({...alert, displayed: true}));
            window.localStorage.setItem('logs', JSON.stringify(newLogs));
            state.logs = newLogs
            state.lastAlert = alert
        },
        clearLogsSuccess(state) {
            window.localStorage.removeItem('lastAlert');
            window.localStorage.removeItem('logs');
            state.logs = {}
            state.lastAlert = null
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function addLog(alert) {
    return async () => {
        try {
            dispatch(slice.actions.addLogsSuccess({
                alert
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setLogs() {
    return async () => {
        try {
            dispatch(slice.actions.setLogsSuccess());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function clearLogs(url, script) {
    return async () => {
        try {
            dispatch(slice.actions.clearLogsSuccess());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
