import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    facilitiesCollected: false,
    error: null,
    allFacilities: [],
    allFacilitiesCollected: false,
    facilities: [],
    facilityModifiers: [],
    facilityClasses: [],
    facilitiesForConsumer: [],
    facilityQuestions: [],
    facilitySymptoms: [],
    facilityProblemCodes: [],
    facility: null
};

const slice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getFacilitiesSuccess(state, action) {
            state.isLoading = false;
            state.facilitiesCollected = true;
            state.facilities = action.payload;
        },

        // GET SERVICES
        getAllFacilitiesSuccess(state, action) {
            state.isLoading = false;
            state.allFacilitiesCollected = true;
            state.allFacilities = action.payload;
        },

        // GET SERVICES
        getFacilitiesForConsumerSuccess(state, action) {
            state.isLoading = false;
            state.facilitiesForConsumer = action.payload;
        },

        // GET SERVICE QUESTIONS
        getFacilityQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.facilityQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getFacilitySymptomsSuccess(state, action) {
            state.isLoading = false;
            state.facilitySymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getFacilityModifiersSuccess(state, action) {
            state.isLoading = false;
            state.facilityModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getFacilityClassesSuccess(state, action) {
            state.isLoading = false;
            state.facilityClasses = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getFacilityProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.facilityProblemCodes = action.payload;
        },

        // GET SERVICE
        getFacilitySuccess(state, action) {
            state.isLoading = false;
            state.facility = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFacilities() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facilities');
            dispatch(slice.actions.getFacilitiesSuccess(response.data.facilities));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAllFacilities(activeOrganization, organizations) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facilities', {
                params: {
                    orgIds: activeOrganization.parentOrganization ? '' : [activeOrganization, ...organizations].map(item => item._id).join(',')
                }
            });
            dispatch(slice.actions.getAllFacilitiesSuccess(response.data.facilities));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacilityQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facility/questions');
            dispatch(slice.actions.getFacilityQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacilityProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facility/problem/codes');
            dispatch(slice.actions.getFacilityProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacilitySymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facility/symptoms');
            dispatch(slice.actions.getFacilitySymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacilityModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facility/modifiers');

            dispatch(slice.actions.getFacilityModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacilityClasses() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/facility/facility/sizes');

            dispatch(slice.actions.getFacilityClassesSuccess(response.data.classes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getFacility(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/facilities`, {
                params: {identifier},
            });
            dispatch(slice.actions.getFacilitySuccess(response?.data?.facilities ? response.data.facilities[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setFacility(facility) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getFacilitySuccess(facility));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
