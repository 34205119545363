import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
import {findIndex} from "lodash";
//

// ----------------------------------------------------------------------

const initialState = {
    scripts: [],
    error: null,
};

const slice = createSlice({
    name: 'script',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // GET ROLE
        scriptLoaded(state, action) {
            const {url, script} = action.payload;
            state.scripts = {
                ...state.scripts,
                [url]: {
                    loaded: true,
                    script,
                }
            };
        },
        // GET ROLE
        scriptUnloaded(state, action) {
            const {url} = action;
            const index = findIndex(state.scripts, {url});
            if (index >= 0) {
                state.scripts.splice(index, 1);
            }
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function loadScript(url, onLoad, async) {
    return async () => {
        const script = document.createElement('script')
        script.onload = onLoad;
        script.src = url
        script.async = async
        document.body.appendChild(script);
        try {
            dispatch(slice.actions.scriptLoaded({url, script}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function unloadScript(url, script) {
    return async () => {
        try {
            document.body.removeChild(script);
            dispatch(slice.actions.scriptUnloaded({url}));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
