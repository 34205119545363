import PropTypes from 'prop-types';
import {memo} from 'react';
// @mui
import {Box, LinearProgress, Stack, Typography} from '@mui/material';
//
import {NavListRoot} from './NavList';
import uuidv4 from "../../../utils/uuidv4";
import {HasAccess} from "../../../contexts/PermissionsContext";
import useLocales from "../../../hooks/useLocales";
// ----------------------------------------------------------------------

const hideScrollbar = {
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

NavSectionHorizontal.propTypes = {
    navConfig: PropTypes.array,
    isLoading: PropTypes.bool,
};

function NavSectionHorizontal({navConfig, isLoading}) {

    const {translate} = useLocales();

    if (isLoading) {
        return (
            <Box>
                <Stack sx={{width: '100%', height: 350}} direction={"column"} spacing={2} justifyContent={"center"}
                       alignItems={"center"}>
                    <Typography variant={"body2"} color={"primary.main"}>
                        {translate("navs.sink.horizontal.loadingUserAccess")}
                    </Typography>
                    <Stack sx={{width: '100%', color: 'grey.500', pl: 2, pr: 2}}>
                        <LinearProgress color="primary"/>
                    </Stack>
                </Stack>
            </Box>
        )
    }

    return (
        <Stack direction="row" justifyContent="center" sx={{bgcolor: 'background.neutral', borderRadius: 1, px: 0.5}}>
            <Stack direction="row" sx={{...hideScrollbar, py: 1}}>
                {navConfig.map((group) => (
                    <HasAccess {...group} key={uuidv4()}>
                        <Stack direction="row" flexShrink={0}>
                            {group.items.map((list) => (
                                <HasAccess {...list} key={uuidv4()}>
                                    <NavListRoot list={list}/>
                                </HasAccess>
                            ))}
                        </Stack>
                    </HasAccess>
                ))}
            </Stack>
        </Stack>
    );
}

export default memo(NavSectionHorizontal);
