import {createSlice} from '@reduxjs/toolkit';
// utils
import {find, groupBy} from 'lodash';
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//


// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    workspacesCollected: false,
    activeWorkspace: null,
    error: null,
    workspaces: [],
    globalWorkspacesByOrg: null,
};

const slice = createSlice({
    name: 'workspaces',
    initialState,
    reducers: {
        // START LOADING
        startLoadingWorkspaces(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET WORKSPACES
        getWorkspacesSuccess(state, action) {
            const {workspaces, activeId} = action.payload;
            state.workspaces = workspaces;
            const activeWorkspace = activeId ? find(workspaces, {_id: activeId}) : null;
            if (activeWorkspace) {
                state.activeWorkspace = activeWorkspace;
            } else {
                state.activeWorkspace = workspaces[0];
            }
            state.globalWorkspacesByOrg = groupBy(workspaces, 'organization');
            state.isLoading = false;
            state.workspacesCollected = true;
        },

        // GET GLOBAL WORKSPACES
        getGlobalWorkspacesSuccess(state, action) {
            state.globalWorkspacesByOrg = groupBy(action.payload, 'organization');
        },


        // GET ACTIVE WORKSPACE
        setActiveWorkspaceSuccess(state, action) {
            state.isLoading = false;
            state.activeWorkspace = action.payload;
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getWorkspaces(organization, activeId, silentLoad) {
    return async () => {
        if (!silentLoad) {
            dispatch(slice.actions.startLoadingWorkspaces());
        }
        try {
            const response = await axios.get('/api/workspaces', {
                params: {orgIdOverride: organization},
            });
            const {workspaces} = response.data;
            dispatch(slice.actions.getWorkspacesSuccess({workspaces, activeId}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGlobalWorkspaces(organizations = []) {
    return async () => {
        try {
            const response = await axios.get('/api/workspaces', {
                params: {orgIds: organizations.join(',')},
            });
            const {workspaces} = response.data;
            dispatch(slice.actions.getGlobalWorkspacesSuccess(workspaces));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setActiveWorkspace(workspace) {
    return async () => {
        dispatch(slice.actions.startLoadingWorkspaces());
        try {
            dispatch(slice.actions.setActiveWorkspaceSuccess(workspace));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
