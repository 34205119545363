import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    vehiclesCollected: false,
    error: null,
    allVehicles: [],
    allVehiclesCollected: false,
    vehicles: [],
    vehicleModifiers: [],
    vehicleClasses: [],
    vehiclesForConsumer: [],
    vehicleQuestions: [],
    vehicleSymptoms: [],
    vehicleProblemCodes: [],
    vehicle: null
};

const slice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET SERVICES
        getVehiclesSuccess(state, action) {
            state.isLoading = false;
            state.vehiclesCollected = true;
            state.vehicles = action.payload;
        },

        // GET SERVICES
        getAllVehiclesSuccess(state, action) {
            state.isLoading = false;
            state.allVehiclesCollected = true;
            state.allVehicles = action.payload;
        },

        // GET SERVICES
        getVehiclesForConsumerSuccess(state, action) {
            state.isLoading = false;
            state.vehiclesForConsumer = action.payload;
        },

        // GET SERVICE QUESTIONS
        getVehicleQuestionsSuccess(state, action) {
            state.isLoading = false;
            state.vehicleQuestions = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getVehicleSymptomsSuccess(state, action) {
            state.isLoading = false;
            state.vehicleSymptoms = action.payload;
        },

        // GET SERVICE SYMPTOMS
        getVehicleModifiersSuccess(state, action) {
            state.isLoading = false;
            state.vehicleModifiers = action.payload;
        },


        // GET SERVICE SYMPTOMS
        getVehicleClassesSuccess(state, action) {
            state.isLoading = false;
            state.vehicleClasses = action.payload;
        },

        // GET SERVICE PROBLEM CODES
        getVehicleProblemCodesSuccess(state, action) {
            state.isLoading = false;
            state.vehicleProblemCodes = action.payload;
        },

        // GET SERVICE
        getVehicleSuccess(state, action) {
            state.isLoading = false;
            state.vehicle = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVehicles() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicles');
            dispatch(slice.actions.getVehiclesSuccess(response.data.vehicles));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAllVehicles(activeOrganization, organizations) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicles', {
                params: {
                    orgIds: activeOrganization.parentOrganization ? '' : [activeOrganization, ...organizations].map(item => item._id).join(',')
                }
            });
            dispatch(slice.actions.getAllVehiclesSuccess(response.data.vehicles));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicleQuestions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicle/questions');
            dispatch(slice.actions.getVehicleQuestionsSuccess(response.data.questions));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicleProblemCodes() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicle/problem/codes');
            dispatch(slice.actions.getVehicleProblemCodesSuccess(response.data.codes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicleSymptoms() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicle/symptoms');
            dispatch(slice.actions.getVehicleSymptomsSuccess(response.data.symptoms));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicleModifiers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicle/modifiers');

            dispatch(slice.actions.getVehicleModifiersSuccess(response.data.modifiers));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicleClasses() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/vehicle/vehicle/sizes');

            dispatch(slice.actions.getVehicleClassesSuccess(response.data.classes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getVehicle(identifier) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/vehicle`, {
                params: {identifier},
            });
            dispatch(slice.actions.getVehicleSuccess(response?.data?.vehicle ? response.data.vehicle[0] : null));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setVehicle(vehicle) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getVehicleSuccess(vehicle));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
