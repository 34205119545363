// @mui
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Stack, Typography} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
import {useSelector} from "../../redux/store";
import {cloneDeep, each, find, keys} from "lodash";
import {useEffect, useState} from "react";
//

// ----------------------------------------------------------------------

export default function GlobalFilters() {
    const {globalFilters, onChangeGlobalFilters} = useSettings();

    const [localGlobalFilters, setLocalGlobalFilters] = useState(globalFilters || {});

    const {activeOrganization, organizations} = useSelector((state) => state.organization);

    const {globalWorkspacesByOrg} = useSelector((state) => state.workspaces);

    const handledChange = async ({target: {checked, name}}) => {
        const workspaces = cloneDeep(localGlobalFilters.workspaces);
        each(globalWorkspacesByOrg[name], workspace => {
            workspaces[name][workspace._id] = checked;
        })
        const newGlobalFilters = {
            ...globalFilters,
            organizations: {
                ...globalFilters.organizations,
                [name]: checked
            },
            workspaces
        }
        setLocalGlobalFilters(newGlobalFilters);
        onChangeGlobalFilters(newGlobalFilters);
    }

    const handleWorkspaceChange = (orgId) => ({target: {checked, name}}) => {
        const newGlobalFilters = {
            ...globalFilters,
            workspaces: {
                ...globalFilters.workspaces,
                [orgId]: {
                    ...globalFilters.workspaces[orgId],
                    [name]: checked
                }
            }
        }
        setLocalGlobalFilters(newGlobalFilters);
        onChangeGlobalFilters(newGlobalFilters)
    }

    useEffect(() => {
        const type = 'global-filters-reset'
        const handleStorageChange = (e) => {
            const key = 'global-filters'
            const newValue = JSON.parse(localStorage.getItem(key));
            if (e.type === type && e.key === key) {
                setLocalGlobalFilters(newValue)
            }
        };
        window.addEventListener(type, handleStorageChange);

        return () => {
            window.removeEventListener(type, handleStorageChange);
        };
    }, []);

    const isIncluded = (id) => localGlobalFilters?.organizations ? localGlobalFilters.organizations[id] : false;

    const isWorkspaceIncluded = (orgId, id) => localGlobalFilters?.workspaces ? localGlobalFilters.workspaces[orgId][id] : false;

    return (

        <Stack spacing={1.5}>
            <Typography variant="subtitle2">Organizations</Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={isIncluded(activeOrganization._id)} onChange={handledChange}/>}
                    name={activeOrganization._id}
                    label={activeOrganization.name}
                />
                {organizations.map(organization => (
                    <FormControlLabel
                        key={organization._id}
                        control={<Checkbox checked={isIncluded(organization._id)} onChange={handledChange}/>}
                        name={organization._id}
                        label={organization.name}
                    />
                ))}
            </FormGroup>

            <Typography variant="subtitle2">Workspaces</Typography>
            {keys(localGlobalFilters?.organizations).map((orgId) => (
                <FormControl key={orgId}>
                    <FormLabel
                        component="legend">{activeOrganization?._id === orgId ? activeOrganization.name : find(organizations, {_id: orgId})?.name || ''}</FormLabel>
                    <FormGroup>
                        {globalWorkspacesByOrg[orgId]?.map(workspace => (
                            <FormControlLabel
                                disabled={!isIncluded(orgId)}
                                key={workspace._id}
                                control={<Checkbox checked={isWorkspaceIncluded(workspace.organization, workspace._id)}
                                                   onChange={handleWorkspaceChange(orgId)}/>}
                                name={workspace._id}
                                label={workspace.name}
                            />
                        ))}

                    </FormGroup>
                </FormControl>
            ))}
        </Stack>
    );
}
