// @mui
import {styled} from '@mui/material/styles';
import {Alert, Button, CircularProgress, Collapse, Container, Link} from '@mui/material';
//
import {useEffect, useState} from "react";
import {capitalize, get, startCase} from "lodash";
import {dispatch, useSelector} from "../../redux/store";
import {clearCompanyProfile, getCompanyProfile} from "../../redux/slices/company";
import useAuth from "../../hooks/useAuth";
import {PATH_BUSINESS_CENTER} from "../../routes/paths";
import {Link as RouterLink} from "react-router-dom";
import {useFlags} from "launchdarkly-react-client-sdk";

// ----------------------------------------------------------------------

const links = (type, linkName) => ({
    name: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    url: PATH_BUSINESS_CENTER.user.accountWithTabAndOverrideLink(5, 'social', linkName),
    email: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    billing: {
        paymentMethods: PATH_BUSINESS_CENTER.user.accountWithTabAndOverrideLink(1, 'organization', 'paymentMethods')
    },
    supportEmail: PATH_BUSINESS_CENTER.user.accountWithTabAndOverrideLink(0, 'organization', linkName),
    billingEmail: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    phoneNumber: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    address: PATH_BUSINESS_CENTER.user.accountWithTabAndOverrideLink(0, 'organization', linkName),
    about: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    country: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    facebookLink: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    youtubeLink: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    instagramLink: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    linkedinLink: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    twitterLink: PATH_BUSINESS_CENTER.user.accountWithTabAndOverride(0, 'organization', linkName),
    locations: type === "provider" ? PATH_BUSINESS_CENTER.location.list : PATH_BUSINESS_CENTER.location.list,
    hoursOfOperation: type === "provider" ? PATH_BUSINESS_CENTER.hoo.list : PATH_BUSINESS_CENTER.hoo.list,
    rates: type === "provider" ? PATH_BUSINESS_CENTER.rate.list : PATH_BUSINESS_CENTER.rate.list,
    services: type === "provider" ? PATH_BUSINESS_CENTER.service.list : PATH_BUSINESS_CENTER.service.list
})

const SetupCompleteStyle = styled('div')(({theme}) => ({
    margin: theme.spacing(2)
}));

// ----------------------------------------------------------------------

SetupComplete.propTypes = {};

export default function SetupComplete() {
    const {organization, isAuthenticated,} = useAuth();

    const {roleAccessOwner, featureSetupComplete} = useFlags()

    const [expand, setExpand] = useState(false)

    const {
        profileComplete,
        criticalItemsMissing,
        itemsMissing,
        profileState,
        profileLoaded
    } = useSelector((state) => state.companies);

    useEffect(() => {
        if (!profileLoaded && organization && isAuthenticated) {
            dispatch(getCompanyProfile(organization._id))
        }
    }, [profileLoaded, organization, isAuthenticated])


    useEffect(() => {
        if (!isAuthenticated && profileLoaded) {
            dispatch(clearCompanyProfile())
        }
    }, [isAuthenticated, profileLoaded, organization])

    if (!isAuthenticated) {
        return null;
    }

    if (!featureSetupComplete) {
        // Disable set up complete if flag not enabled
        return null;
    }

    const buildLink = (linkName, index, itemCount) => {
        return (
            <span key={linkName}>
                <Link component={RouterLink} to={get(links(organization.type, linkName), linkName)}
                      sx={{typography: 'button', color: "inherit"}}>
                    {capitalize(startCase(linkName))}
                </Link>
                {index < itemCount - 1 && ', '}
            </span>
        )
    }

    return (
        <Container maxWidth={"xl"}>
            <SetupCompleteStyle>
                {
                    roleAccessOwner && profileLoaded && profileComplete < 100 &&
                    <Alert
                        style={{textAlign: 'left'}}
                        iconMapping={{
                            success: <CircularProgress color={profileState} size={24} variant={"determinate"}
                                                       value={profileComplete}/>,
                        }}
                        severity={profileState}
                        action={
                            itemsMissing?.length > 0 &&
                            <Button onClick={() => setExpand(!expand)} color="inherit" size="small">
                                {expand ? 'Show Less' : 'Learn More'}
                            </Button>
                        }
                    >
                        Your business profile is {profileComplete}% complete.
                        {profileState === "error" && ` The following critical information is required: `}{criticalItemsMissing.map((c, i) => buildLink(c, i, criticalItemsMissing.length))}
                        {profileState === "success" && ` You are ready to start enabling your business.`}
                        <Collapse in={expand} style={{width: '100%', textAlign: 'left', paddingTop: 5}}>
                            The following profile items are still missing information.
                            <br/>
                            {itemsMissing.map((c, i) => buildLink(c, i, itemsMissing.length))}
                        </Collapse>
                    </Alert>
                }
            </SetupCompleteStyle>
        </Container>
    )
}
