import useAuth from "../hooks/useAuth";
import {CLOCK_IN_PAY_TYPES} from "../config";
import Label from "./Label";

export default function LoggedInLabel({userOverride}) {

    const {user} = useAuth();
    if (!CLOCK_IN_PAY_TYPES.includes((userOverride || user)?.compensation?.payType)) {
        return null;
    }

    return (
        <Label color={(userOverride || user).workStatus?.clockedIn ? 'success' : 'warning'}>
            Clocked {!(userOverride || user).workStatus?.clockedIn ? ' Out' : ' In'}
        </Label>
    )
}
