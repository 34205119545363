import {format, formatDistanceToNow, getTime, getUnixTime, parseISO, setHours, setMinutes, setSeconds} from 'date-fns';

// ----------------------------------------------------------------------

// Function to convert a string to ISO UTC
export function convertToISOUTC(dateString) {
    return new Date(dateString).toISOString();
}

export function fDate(date, formatString) {
    return format(new Date(date), formatString || 'MMMM dd, yyyy');
}

export function fDateTime(date) {
    return format(new Date(date), 'PPp');
}

export function fTimestamp(date) {
    return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'MM/dd/yyyy p');
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function formatUTCTimeCorrectly(dateString) {
    // Directly create a Date object from the ISO string
    const date = new Date(dateString);

    // Format the hours and minutes manually
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
}

// Function to parse time string to Date object
export function parseTimeString(timeString = "") {
    if (typeof timeString === "string") {
        const [hours, minutes] = timeString.split(':');
        const date = new Date();
        date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        return date;
    }
    return new Date();
}

// Function to format Date object to desired time format
export function formatTime(date) {
    return format(date, 'p'); // Example format: "03:00 PM"
}

export function timePunchIsWithinPayrollPeriod(timePunch, payPeriod) {
    if (!timePunch?.clockInTime) {
        return true;
    }
    const punchStartDate = new Date(timePunch.clockInTime * 1000);
    return (punchStartDate >= payPeriod.start && punchStartDate < payPeriod.end)
}

export function calculateHours(timePunches, totalRegularHours = 40, days = 7, currentPayPeriod) {
    // Calculate base hours based on the proportion of the work period

    const base = (totalRegularHours / 7) * days;

    // Calculate the total time worked in seconds
    const totalSeconds = timePunches.reduce(
        (acc, punch) => {
            const punchDate = new Date(punch.clockInTime * 1000);
            if (currentPayPeriod && (punchDate < currentPayPeriod.start || punchDate >= currentPayPeriod.end)) {
                return acc;
            }
            return acc + (punch.clockOutTime ? (punch.clockOutTime - punch.clockInTime) : 0)
        },
        0
    );

    // Convert total seconds to hours
    const totalHours = totalSeconds / 3600;

    // Calculate regular hours and overtime hours based on the base
    const regularHours = Math.min(totalHours, base);
    const overtimeHours = totalHours > base ? totalHours - base : 0;

    return {
        regularHours,
        overtimeHours,
    };
}


// Convert 'HH:mm' time format back to Unix timestamp, preserving the original date (row.date)
export function convertToUnixTimestamp(timeString, dateString) {

    // Parse the 'yyyy-MM-dd' date string
    const originalDate = parseISO(dateString); // Creates a Date object from the original date

    // Split the 'HH:mm' string and set hours/minutes
    const [hours, minutes] = timeString.split(':');

    const updatedDate = setSeconds(
        setMinutes(setHours(originalDate, hours), minutes), 0
    );

    return getUnixTime(updatedDate); // Convert the updated Date object back to Unix timestamp
}

export function generateRandomNumberBasedOnUnixTimestamp() {
    const timestamp = Date.now(); // Get the current Unix timestamp in milliseconds
    const randomSeed = timestamp % 1000000; // Reduce the seed size for better handling
    const randomNumber = Math.floor(Math.random() * randomSeed);
    return randomNumber;
}
