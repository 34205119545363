import PropTypes from 'prop-types';
import {createContext, useCallback, useEffect, useState} from 'react';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
// utils
import getColorPresets, {colorPresets, defaultPreset} from '../utils/getColorPresets';
// config
import {defaultSettings} from '../config';
import {dispatch, useSelector} from "../redux/store";
import {getGlobalWorkspaces} from "../redux/slices/workspaces";
import {each, flatMap, keys, merge} from "lodash";
import axios from "../utils/axios";
import {setGlobalFilters} from "../redux/slices/environment";

// ----------------------------------------------------------------------

const initialState = {
    ...defaultSettings,
    globalFilters: {},
    globalFiltersChanged: null,
    onChangeMode: () => {
    },
    onToggleMode: () => {
    },
    onChangeDirection: () => {
    },
    onChangeColor: () => {
    },
    onToggleStretch: () => {
    },
    onChangeLayout: () => {
    },
    onResetSetting: () => {
    },
    onResetGlobalFilters: () => {
    },
    setColor: defaultPreset,
    colorOption: [],
};

const SettingsContext = createContext(initialState);

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
    children: PropTypes.node,
};

function SettingsProvider({children}) {

    const {activeOrganization, organizations} = useSelector((state) => state.organization);

    const {globalFilters, globalFiltersLoaded} = useSelector((state) => state.environment);

    const [globalFiltersChanged, setGlobalFiltersChanged] = useState(false);

    const {globalWorkspacesByOrg} = useSelector((state) => state.workspaces);

    const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [settings, setSettings] = useLocalStorage('settings', {
        themeMode: isDarkMode() ? 'dark' : 'light',
        themeDirection: initialState.themeDirection,
        themeColorPresets: initialState.themeColorPresets,
        themeStretch: initialState.themeStretch,
        themeLayout: initialState.themeLayout,
    }, false);

    const onChangeMode = (event) => {
        setSettings({
            ...settings,
            themeMode: event.target.value,
        });
    };

    const onToggleMode = () => {
        setSettings({
            ...settings,
            themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
        });
    };

    const onChangeDirection = (event) => {
        setSettings({
            ...settings,
            themeDirection: event.target.value,
        });
    };

    const onChangeColor = (event) => {
        setSettings({
            ...settings,
            themeColorPresets: event.target.value,
        });
    };

    const onChangeLayout = (event) => {
        setSettings({
            ...settings,
            themeLayout: event.target.value,
        });
    };

    const onToggleStretch = () => {
        setSettings({
            ...settings,
            themeStretch: !settings.themeStretch,
        });
    };

    const onResetSetting = () => {
        const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        setSettings({
            ...settings,
            themeMode: isDarkMode() ? 'dark' : 'light',
            themeLayout: initialState.themeLayout,
            themeStretch: initialState.themeStretch,
            themeDirection: initialState.themeDirection,
            themeColorPresets: initialState.themeColorPresets,
        });
    };


    const getDefaultOrgFilters = () => ({
        [activeOrganization?._id]: true,
        ...objFromArray(organizations, true)
    });

    const getDefaultWorkspaceFilters = () => {
        const workspaces = {}
        each(keys(globalWorkspacesByOrg), orgId => {
            workspaces[orgId] = objFromArray(globalWorkspacesByOrg[orgId], true);
        })
        return workspaces;
    }

    const updateAxiosFilterDefaults = useCallback(() => {
        const orgIds = [];
        const workspaceIds = [];

        if (globalFilters) {
            each(keys(globalFilters?.organizations), (orgId) => {
                if (globalFilters?.organizations[orgId]) {
                    orgIds.push(orgId)
                }
                each(keys(globalFilters?.workspaces ? globalFilters?.workspaces[orgId] : []), (workspaceId) => {
                    if (globalFilters?.workspaces[orgId][workspaceId]) {
                        workspaceIds.push(workspaceId)
                    }
                })
            })
        }

        if (!activeOrganization?.parentOrganization && organizations.length > 0 && axios.defaults.params.orgIds !== orgIds.join(',')) {

            axios.defaults.params = {
                ...axios.params,
                ...(orgIds.length > 0 && {orgIds: orgIds.join(',')}),
                ...(workspaceIds.length > 0 && {workspaceIds: workspaceIds.join(',')})
            }
            setGlobalFiltersChanged(current => !current)
        }
        if ((activeOrganization?.parentOrganization || organizations.length === 0) && delete axios.defaults.params.orgIds) {

            delete axios.defaults.params.orgIds;
            delete axios.defaults.params.workspaceIds;
            setGlobalFiltersChanged(current => !current)
        }
    }, [activeOrganization, globalFilters, organizations])

    useEffect(() => {
        updateAxiosFilterDefaults();
    }, [activeOrganization, updateAxiosFilterDefaults]);

    const onResetGlobalFilters = () => {
        const organizations = getDefaultOrgFilters();
        const workspaces = getDefaultWorkspaceFilters();
        dispatch(setGlobalFilters({
            ...initialState.globalFilters,
            organizations,
            workspaces,
        }));
        updateAxiosFilterDefaults({
            ...initialState.globalFilters,
            organizations,
            workspaces,
        });

        const key = 'global-filters'
        const type = 'global-filters-reset'
        window.dispatchEvent(new StorageEvent(type, {key}));
    };

    const onChangeGlobalFilters = (newGlobalFilters) => {
        dispatch(setGlobalFilters(newGlobalFilters))
        updateAxiosFilterDefaults(newGlobalFilters)
    }

    const onModeChange = useCallback((event) => {
        const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches

        setSettings({
            ...settings,
            themeMode: isDarkMode() ? 'dark' : 'light',
        });
    }, [setSettings, settings])

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', onModeChange)
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', onModeChange)
        }
    }, [onModeChange]);

    function objFromArray(array, value = null, key = '_id') {
        return array.reduce((accumulator, current) => {
            accumulator[current[key]] = value || current;
            return accumulator;
        }, {});
    }

    useEffect(() => {
        if (!globalWorkspacesByOrg && activeOrganization && organizations && organizations.length > 0) {
            dispatch(getGlobalWorkspaces([activeOrganization?._id, ...flatMap(organizations, org => org?._id)], true));
        }
    }, [activeOrganization, organizations, globalWorkspacesByOrg]);

    useEffect(() => {
        if (globalWorkspacesByOrg && activeOrganization && organizations && organizations.length > 0 && !activeOrganization.parentOrganization && !globalFiltersLoaded) {
            let workspaces = {}
            each(keys(globalWorkspacesByOrg), orgId => {
                workspaces[orgId] = objFromArray(globalWorkspacesByOrg[orgId], true);
            })
            const newFilters = merge({
                organizations: {
                    [activeOrganization?._id]: true,
                    ...objFromArray(organizations, true)
                },
                workspaces
            }, globalFilters)
            dispatch(setGlobalFilters(newFilters));
            updateAxiosFilterDefaults(newFilters)
        }
    }, [activeOrganization, organizations, globalWorkspacesByOrg, globalFiltersLoaded, globalFilters, updateAxiosFilterDefaults]);


    return (
        <SettingsContext.Provider
            value={{
                ...settings,
                globalFilters,
                updateAxiosFilterDefaults,
                // Mode
                onChangeMode,
                onToggleMode,
                // Direction
                onChangeDirection,
                // Color
                onChangeColor,
                setColor: getColorPresets(settings.themeColorPresets),
                colorOption: colorPresets.map((color) => ({
                    name: color.name,
                    value: color.main,
                })),
                // Stretch
                onToggleStretch,
                // Navbar Horizontal
                onChangeLayout,
                // Reset Setting
                onResetSetting,
                // Global Filters
                globalFiltersChanged,
                onChangeGlobalFilters,
                // Reset Global Filters
                onResetGlobalFilters,
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
}

export {SettingsProvider, SettingsContext};
