import {Button, Menu, MenuItem, styled} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {clone, startCase} from "lodash";
import useNavigation from "../../../hooks/useNavigation";
import React, {useEffect, useReducer, useState} from "react";
import Iconify from "../../../components/Iconify";
import {HasAccess} from "../../../contexts/PermissionsContext";
import uuidv4 from "../../../utils/uuidv4";

const StyledMenu = styled((props) => (
    <Menu
        width={'100%'}
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

function reducer(state, action) {
    const newState = clone(state)
    switch (action.type) {
        case 'set':
            return action.payload
        default:
            return newState;
    }
}

export default function VerticalTopNavigation({nav}) {
    const {setNavigationContext, navContext} = useNavigation();
    const [tabIndex, setTabIndex] = useReducer(reducer, navContext || 0, () => navContext || 0);
    useEffect(() => {
        if (navContext !== tabIndex) {
            setTabIndex({type: 'set', payload: navContext})
        }
    }, [navContext, tabIndex]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                fullWidth
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<Iconify icon={"icon-park-outline:down"}/>}
            >
                {startCase(nav[tabIndex]?.subheader)}
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {nav?.map((group, index) => (
                    <HasAccess {...group} key={uuidv4()}>
                        <MenuItem onClick={() => {
                            setTabIndex({type: 'set', payload: index});
                            setNavigationContext(index);
                            setAnchorEl(null)
                        }} disableRipple>
                            <Iconify icon={""}/>
                            {startCase(group?.subheader)}
                        </MenuItem>
                    </HasAccess>
                ))}
            </StyledMenu>
        </div>
    );
}
