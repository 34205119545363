// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
// @mui
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import {LocalizationProvider} from '@mui/x-date-pickers';
// redux
import {persistor, store} from './redux/store';
// contexts
import {SettingsProvider} from './contexts/SettingsContext';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version
// import {AuthProvider} from './contexts/JWTContext';
import {AuthProvider} from './contexts/Auth0Context';
// import { AuthProvider } from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
//
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {withLDProvider} from 'launchdarkly-react-client-sdk';
import App from './App';

// ----------------------------------------------------------------------

const LDProvider = withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    context: {
        kind: 'user',
        key: 'anonymous-user',
        anonymous: true
    },
    reactOptions: {
        useCamelCaseFlagKeys: true
    }
})(App);

// ----------------------------------------------------------------------

ReactDOM.render(
    <AuthProvider>
        <HelmetProvider>
            <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <SettingsProvider>
                            <CollapseDrawerProvider>
                                <BrowserRouter>
                                    <LDProvider/>
                                </BrowserRouter>
                            </CollapseDrawerProvider>
                        </SettingsProvider>
                    </LocalizationProvider>
                </PersistGate>
            </ReduxProvider>
        </HelmetProvider>
    </AuthProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
