import PropTypes from 'prop-types';
import {AnimatePresence, m} from 'framer-motion';
import {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
// @mui
import {alpha, styled} from '@mui/material/styles';
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    Stack,
    Typography
} from '@mui/material';
// hooks
import useSettings from '../../hooks/useSettings';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import {defaultSettings, NAVBAR} from '../../config';
//
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import {IconButtonAnimate, varFade} from '../animate';
//
import ToggleButton from './ToggleButton';
import SettingMode from './SettingMode';
import SettingLayout from './SettingLayout';
import SettingStretch from './SettingStretch';
import SettingDirection from './SettingDirection';
import SettingFullscreen from './SettingFullscreen';
import SettingColorPresets from './SettingColorPresets';
import useSessionManager from "../../hooks/useSessionManager";
import axios from "../../utils/axios";
import useAuth from "../../hooks/useAuth";
import {PATH_AUTH} from "../../routes/paths";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import GlobalFilters from "./GlobalFilters";
import {useSelector} from "../../redux/store";
import useEnvironment from "../../hooks/useEnvironment";

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({theme}) => ({
    ...cssStyles(theme).bgBlur({color: theme.palette.background.paper, opacity: 0.92}),
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'fixed',
    overflow: 'hidden',
    width: NAVBAR.BASE_WIDTH,
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 3,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    boxShadow: `-24px 12px 32px -4px ${alpha(
        theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
        0.16
    )}`,
}));

// ----------------------------------------------------------------------

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    borderRadius: '0px !important',
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<Iconify icon={"ic:sharp-arrow-forward-ios"} sx={{fontSize: '0.9rem'}}/>}
        {...props}

    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// ----------------------------------------------------------------------

Settings.propTypes = {
    isDashboard: PropTypes.bool
};

function Settings() {
    const {logout} = useAuth();
    const {activeOrganization, organizations} = useSelector((state) => state.organization);
    const {
        themeMode,
        themeDirection,
        themeColorPresets,
        themeStretch,
        themeLayout,
        onResetSetting,
        onResetGlobalFilters
    } = useSettings();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(activeOrganization?.parentOrganization ? "panel0" : "panel1");
    const [showSessionWarning, setShowSessionWarning] = useState(false);
    const checkEveryXMinutes = 60 * 1000;
    const {checkAuthToken, checkTimeLeft} = useSessionManager();
    const [timeLeft, setTimeLeft] = useState(600);
    const navigate = useNavigate();
    const {environmentReady} = useEnvironment();

    useEffect(() => {
        if (timeLeft <= 0) {
            logout()
                .then(() => {
                    navigate(PATH_AUTH.login, {replace: true});
                });
        }

        setTimeout(() => {
            setShowSessionWarning(checkAuthToken());
            setTimeLeft(checkTimeLeft());
        }, checkEveryXMinutes);
    }, [timeLeft, checkAuthToken, checkEveryXMinutes, checkTimeLeft, logout, navigate]);

    const notDefault =
        themeMode !== defaultSettings.themeMode ||
        themeDirection !== defaultSettings.themeDirection ||
        themeColorPresets !== defaultSettings.themeColorPresets ||
        themeLayout !== defaultSettings.themeLayout ||
        themeStretch !== defaultSettings.themeStretch;

    const varSidebar =
        themeDirection !== 'rtl'
            ? varFade({
                distance: NAVBAR.BASE_WIDTH,
                durationIn: 0.32,
                durationOut: 0.32,
            }).inRight
            : varFade({
                distance: NAVBAR.BASE_WIDTH,
                durationIn: 0.32,
                durationOut: 0.32,
            }).inLeft;

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [open]);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : "");
    };

    const resetSession = async () => {
        try {
            await axios.get(`/api/session/refresh`);
            setShowSessionWarning(false);
            setTimeLeft(600)
        } catch (error) {
            console.error('resetSession: Error', error)
        }
    }

    if (!environmentReady) {
        return null;
    }

    return (
        <>
            <Backdrop
                open={open}
                onClick={handleClose}
                sx={{background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1}}
            />

            <Dialog open={showSessionWarning}>
                <DialogTitle>Session Expiring</DialogTitle>
                <DialogContent>Your session is going to expire soon. Would you like to continue working?</DialogContent>
                <DialogActions>
                    <Button onClick={resetSession}>Yes, I want more time</Button>
                    <Button onClick={() => setShowSessionWarning(false)}>Nope, I'm good</Button>
                </DialogActions>
            </Dialog>
            {!open && <ToggleButton open={open} notDefault={notDefault} onToggle={handleToggle}/>}

            <AnimatePresence>
                {open && (
                    <>
                        <RootStyle {...varSidebar}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between"
                                   sx={{py: 2, pl: 2.5}}>
                                <Typography variant="h6">Preferences</Typography>
                                <div>
                                    <IconButtonAnimate onClick={handleClose}>
                                        <Iconify icon={'eva:close-fill'} width={20} height={20}/>
                                    </IconButtonAnimate>
                                </div>
                            </Stack>
                            <Scrollbar sx={{flexGrow: 1}}>
                                {!activeOrganization.parentOrganization && organizations.length > 0 &&
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                        <AccordionSummary
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Stack direction="row" alignItems="center" justifyContent="space-between"
                                                   width={1}>
                                                <Typography variant="subtitle1">Global Filters</Typography>
                                                <div>
                                                    <IconButtonAnimate onClick={(event) => {
                                                        event.stopPropagation();
                                                        onResetGlobalFilters(event)
                                                    }}>
                                                        <Iconify icon={'ic:round-refresh'} width={20} height={20}/>
                                                    </IconButtonAnimate>
                                                </div>
                                            </Stack>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/*<Divider sx={{borderStyle: 'dashed'}}/>*/}
                                            <Scrollbar sx={{flexGrow: 1}}>
                                                <Stack spacing={3} sx={{p: 3}}>
                                                    <GlobalFilters/>
                                                </Stack>
                                            </Scrollbar>
                                        </AccordionDetails>
                                    </Accordion>}
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <AccordionSummary
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Stack direction="row" alignItems="center" justifyContent="space-between"
                                               width={1}>
                                            <Typography variant="subtitle1">Settings</Typography>
                                            <div>
                                                <IconButtonAnimate onClick={(event) => {
                                                    event.stopPropagation();
                                                    onResetSetting(event)
                                                }}>
                                                    <Iconify icon={'ic:round-refresh'} width={20} height={20}/>
                                                </IconButtonAnimate>
                                            </div>
                                        </Stack>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Scrollbar sx={{flexGrow: 1}}>
                                            <Stack spacing={3} sx={{p: 3}}>
                                                <Stack spacing={1.5}>
                                                    <Typography variant="subtitle2">Mode</Typography>
                                                    <SettingMode/>
                                                </Stack>

                                                <Stack spacing={1.5}>
                                                    <Typography variant="subtitle2">Direction</Typography>
                                                    <SettingDirection/>
                                                </Stack>

                                                <Stack spacing={1.5}>
                                                    <Typography variant="subtitle2">Layout</Typography>
                                                    <SettingLayout/>
                                                </Stack>

                                                <Stack spacing={1.5}>
                                                    <Typography variant="subtitle2">Presets</Typography>
                                                    <SettingColorPresets/>
                                                </Stack>

                                                <Stack spacing={1.5}>
                                                    <Typography variant="subtitle2">Stretch</Typography>
                                                    <SettingStretch/>
                                                </Stack>

                                                <SettingFullscreen/>
                                            </Stack>
                                        </Scrollbar>
                                    </AccordionDetails>
                                </Accordion>
                            </Scrollbar>
                        </RootStyle>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}

// ----------------------------------------------------------------------

export default Settings;

BoxMask.propTypes = {
    value: PropTypes.string,
};

export function BoxMask({value}) {
    return (
        <FormControlLabel
            label=""
            value={value}
            control={<Radio sx={{display: 'none'}}/>}
            sx={{
                m: 0,
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                position: 'absolute',
            }}
        />
    );
}
