import {createSlice} from '@reduxjs/toolkit';
// utils
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    currentContext: ''
};

function objFromArray(array, key = '_id') {
    return array.reduce((accumulator, current) => {
        accumulator[current[key]] = current;
        return accumulator;
    }, {});
}


const slice = createSlice({
    name: 'alex',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
            state.alertsCollected = false;
        },

        // SET CONTEXT
        setConversationContext(state, action) {
            state.currentContext = action.payload;
        },

        // GET ALERTS
        getAlertsSuccess(state, action) {
            state.isLoading = false;
            state.alerts.byId = objFromArray(action.payload);
            state.alerts.allIds = Object.keys(state.alerts.byId);
            state.alertsCollected = true;
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setConversationContext(context) {
    return async () => {
        try {
            dispatch(slice.actions.setConversationContext(context));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
