import {filter, find} from "lodash";
import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    packages: null,
    currentPackages: [],
    doloomaPackages: [],
    companyPackages: [],
};

const slice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET PACKAGES
        getPackagesSuccess(state, action) {
            state.isLoading = false;

            state.doloomaPackages = filter(action.payload, {doloomaOnly: true})
            state.companyPackages = filter(action.payload, (r) => !r.doloomaOnly)

            state.packages = action.payload;
        },

        // SET CURRENT PACKAGES
        setPackagesSuccess(state, action) {
            state.isLoading = false;

            const {packages, currentPackages} = action.payload;
            state.doloomaPackages = filter(packages, {doloomaOnly: true})
            state.companyPackages = filter(packages, (p) => !p.doloomaOnly)
            state.currentPackages = filter(packages, ({identifier}) => !!find(currentPackages, {identifier}))

            state.packages = packages;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function setCurrentPackages(currentPackages) {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/package');
            dispatch(slice.actions.setPackagesSuccess({packages: response.data.package, currentPackages}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPackages() {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/package');
            dispatch(slice.actions.getPackagesSuccess(response.data.package));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
