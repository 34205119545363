import {useCallback, useEffect, useReducer, useRef, useState} from 'react';
import {Avatar, Backdrop, Card, CardHeader, Divider, Fab, Popover} from '@mui/material';
import {findIndex} from 'lodash';
import alex from '../../assets/alex.png';
import useAuth from '../../hooks/useAuth';
import uuidv4 from '../../utils/uuidv4';
import axios from '../../utils/axios';
import ChatMessageList from './ChatMessageList';
import ChatMessageInput from './ChatMessageInput';
import {useDispatch, useSelector} from "../../redux/store";
import {ALERT_TYPES, ALEX_CONTEXTS} from "../../config";
import {setConversationContext} from "../../redux/slices/alex";
import {markAlertAsRead} from "../../redux/slices/alerts";
import {useLocation} from "react-router-dom";

// ----------------------------------------------------------------------
const initialValue = {
    messages: [],
};

const init = () => initialValue;

const setConversation = (state, action) => {
    let messageIndex = -1;
    switch (action?.type) {
        case 'ADD_MESSAGE':
            return {
                ...state,
                messages: [
                    ...state.messages,
                    action.payload,
                ],
            };
        case 'REPLACE_MESSAGE':
            messageIndex = findIndex(state.messages, {id: action.payload.id});
            if (messageIndex >= 0) {
                state.messages[messageIndex] = action.payload;
            }
            return {
                ...state,
            };
        case 'INITIALIZE':
            return action.payload;
        default:
            return initialValue;
    }
};

// ----------------------------------------------------------------------

export default function Alex() {

    const {user} = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);

    const participants = [
        {
            avatar: alex,
            role: 'assistant',
            name: 'Alex ',
        },
        {
            ...user,
            role: 'user',
            id: user?.userId || ''
        }
    ]

    const fabRef = useRef();

    const [conversation, dispatch] = useReducer(setConversation, initialValue, init);

    const {alerts} = useSelector((state) => state.alerts);

    const {currentContext} = useSelector((state) => state.alex);

    const reduxDispatch = useDispatch();
    const {state} = useLocation();

    const getInitialConversation = useCallback((contextOverride) => axios.post('/api/alex', {
        chatContext: contextOverride || currentContext,
        messages: [],
        initialize: true
    }), [currentContext])

    useEffect(() => {

        alerts.allIds.forEach((_id) => {
            const alert = alerts.byId[_id]
            if (alert.type === ALERT_TYPES.JOB_REQUEST_ACCEPT && alert.isUnRead) {
                reduxDispatch(setConversationContext(ALEX_CONTEXTS.JOB_REQUEST_ACCEPT))
                getInitialConversation(ALEX_CONTEXTS.JOB_REQUEST_ACCEPT)
                    .then(({data: {messages}}) => {

                        dispatch({
                            type: 'INITIALIZE',
                            payload: {messages, _id: alert.jobRequest},
                        });

                        const {_id} = state || {}
                        if (_id !== alert.jobRequest) {
                            setAnchorEl(fabRef.current);
                        } else {
                            reduxDispatch(markAlertAsRead(alert._id))
                        }
                    })
                    .catch((error) => console.error(error))
            }
        });

    }, [alerts, state, getInitialConversation, reduxDispatch]);

    const closeAlerts = () => {

        alerts.allIds.forEach((_id) => {
            const alert = alerts.byId[_id]
            if (alert.type === ALERT_TYPES.JOB_REQUEST_ACCEPT && alert.isUnRead) {
                reduxDispatch(markAlertAsRead(alert._id))
            }
        });
    }

    const handleClick = (event) => {
        dispatch({
            type: 'INITIALIZE',
            payload: getInitialConversation(),
        });

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        dispatch();
        setAnchorEl(null);
    };

    const handleMessageSubmit = (newMessage) => {

        const myNewMessage = {
            id: uuidv4(),
            role: 'user',
            createdAt: new Date(),
            senderId: user.userId,
            body: newMessage,
        };

        dispatch({
            type: 'ADD_MESSAGE',
            payload: myNewMessage,
        });

        askAlex(myNewMessage);
    };

    const askAlex = (myMessage) => {

        const alexMessage = {
            id: uuidv4(),
            createdAt: new Date(),
            senderId: 'alex',
            contentType: 'icon',
            role: 'assistant',
            body: 'eos-icons:typing',
        };

        dispatch({
            type: 'ADD_MESSAGE',
            payload: alexMessage,
        });

        axios.post('/api/alex', {
            chatContext: currentContext,
            messages: [...conversation.messages.map((message) => ({
                role: message.role,
                content: message.body,
            })), {role: myMessage.role, content: myMessage.body}],
        })
            .then((response) => {
                const {data: {message: {role, content}}} = response;

                dispatch({
                    type: 'REPLACE_MESSAGE',
                    payload: {
                        ...alexMessage,
                        role,
                        body: content,
                        contentType: '',
                    },
                });

            })
            .catch((error) => console.error(error));
    };

    const openChat = Boolean(anchorEl);

    return (
        <>
            <Backdrop open={openChat} sx={{zIndex: theme => theme.zIndex.snackbar + 1, backdropFilter: 'blur(6px)'}}>
                <Popover
                    sx={{zIndex: theme => theme.zIndex.snackbar + 2}}
                    open={openChat}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >

                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar src={alex}/>
                            }
                            title={'Alex'}
                            subheader={'You personal Dolooma assistant'}
                        />
                        <Divider/>
                        <ChatMessageList
                            closeConversation={(callback) => {
                                closeAlerts()
                                dispatch({
                                    type: 'INITIALIZE',
                                    payload: {messages: []},
                                });

                                reduxDispatch(setConversationContext(''))
                                setAnchorEl(null);
                                if (callback) {
                                    callback();
                                }
                            }}
                            conversation={conversation}
                            participants={participants}
                        />
                        <ChatMessageInput conversation={conversation} submitMessage={handleMessageSubmit}/>
                    </Card>
                </Popover>
            </Backdrop>

            <Fab
                ref={fabRef}
                onClick={handleClick}
                size={'medium'}
                sx={{
                    background: 'transparent',
                    backgroundColor: 'transparent',
                    fontSize: 24,
                    cursor: 'pointer',
                    position: 'fixed',
                    bottom: 10,
                    right: 10
                }}>
                <img alt={'alex'} src={alex}/>
            </Fab>
        </>
    );
}
