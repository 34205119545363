import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    pipelinesCollected: false,
    steps: []
};

const slice = createSlice({
    name: 'pipeline',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getPipelineSuccess(state, action) {
            state.isLoading = false;
            state.pipelinesCollected = true;
            const {pipelines} = action.payload;
            const {columnOrder, columns} = pipelines[0];
            state.columnOrder = columnOrder;
            state.columns = columns;
            state.steps = columnOrder.map((key) => columns[key].name.toLowerCase());
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPipeline() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/accounts/pipelines');
            const {pipelines} = response.data;
            dispatch(slice.actions.getPipelineSuccess({pipelines}));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
