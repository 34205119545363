import _mock from './_mock';
import {randomInArray} from './funcs';
import {random} from "lodash";
import {endOfMonth, format, startOfMonth, subMonths} from "date-fns";

// ----------------------------------------------------------------------

export const _bookings = [...Array(5)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    avatar: _mock.image.avatar(index),
    checkIn: _mock.time(index),
    checkOut: _mock.time(index),
    phoneNumber: _mock.phoneNumber(index),
    status: randomInArray(['pending', 'un_paid', 'paid']),
    roomType: randomInArray(['double', 'king', 'single']),
}));

export const _bonusHistory = [...Array(5)].map((_, index) => {
    // Calculate the start and end dates of the month based on the index
    const monthStart = startOfMonth(subMonths(new Date(), index));
    const monthEnd = endOfMonth(subMonths(new Date(), index));
    const score = random(93, 97);
    return {
        id: _mock.id(index),
        name: _mock.name.fullName(index),
        avatar: _mock.image.avatar(index),
        startDate: format(monthStart, 'dd MMM yyyy'), // Format the date
        endDate: format(monthEnd, 'dd MMM yyyy'), // Format the date
        phoneNumber: score,
        status: ['earned', 'missed'][score < 95 ? 1 : 0],
        roomType: randomInArray(['double', 'king', 'single']),
    };
});

export const _bookingsOverview = [...Array(3)].map((_, index) => ({
    status: ['Pending', 'Cancel', 'Done'][index],
    quantity: _mock.number.percent(index) * 1000,
    value: _mock.number.percent(index),
}));

export const _ataVarianceOverview = [...Array(3)].map((_, index) => ({
    status: ['Met ETA', 'Exceeded ETA', 'Under ETA'][index],
    quantity: [3.5, 1.5, 95][index],
    value: [3.5, 1.5, 95][index]
}));

export const _bookingReview = [...Array(5)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    description: _mock.text.description(index),
    avatar: _mock.image.avatar(index),
    rating: _mock.number.rating(index),
    postedAt: _mock.time(index),
    tags: ['Great Service', 'Recommended', 'Best Price'],
}));

export const _bookingNew = [...Array(5)].map((_, index) => ({
    id: _mock.id(index),
    name: _mock.name.fullName(index),
    avatar: _mock.image.avatar(index),
    bookdAt: _mock.time(index),
    roomNumber: 'A-21',
    roomType: randomInArray(['double', 'king', 'single']),
    person: '3-5',
    cover: `https://minimals.cc/assets/images/rooms/room-${index + 1}.jpg`,
}));
