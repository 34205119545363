import React, {useEffect, useReducer} from "react";
import Tab, {tabClasses} from "@mui/material/Tab";
import Tabs, {tabsClasses} from "@mui/material/Tabs";
import {HasAccess} from "../contexts/PermissionsContext";
import uuidv4 from "../utils/uuidv4";
import {clone, startCase} from "lodash";
import useNavigation from "../hooks/useNavigation";
import usePermissions from "../hooks/usePermissions";

export const tabsStyles = (theme) => ({
    root: {
        borderRadius: "5px",
        minHeight: 44,
        zIndex: theme.zIndex.appBar + 10,
    },
    flexContainer: {
        position: "relative",
        padding: "0 3px",
        zIndex: 1,
    },
    indicator: {
        top: 3,
        bottom: 3,
        right: 3,
        height: "auto",
        borderRadius: "8px",
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.secondary.main,
    },
    selected: {
        // color: '#fff',
    }
});

export const tabItemStyles = (theme) => ({
    root: {
        fontWeight: 500,
        minHeight: 44,
        marginRight: '5px !important',
        minWidth: 96,
        textTransform: "initial",
        paddingX: 1,
        "&:hover": {
            color: (theme.vars || theme).palette.text.primary,
        },
        [`&.${tabClasses.selected}`]: {
            opacity: 1,
        },
        [theme.breakpoints.up("md")]: {
            minWidth: 100,
        },
    },
});

function toSx(styles, classes) {
    return function sxCallback(theme) {
        let sx = {};
        Object.entries(styles(theme)).forEach(([key, value]) => {
            if (key === "root") {
                sx = {...sx, ...value};
            } else {
                sx[`& .${classes[key]}`] = value;
            }
        });
        return sx;
    };
}

function reducer(state, action) {
    const newState = clone(state)
    switch (action.type) {
        case 'set':
            return action.payload
        default:
            return newState;
    }
}

export function TabsApple({sx, tabs}) {

    const {setNavigationContext, navContext} = useNavigation();
    const {hasAccess} = usePermissions()
    const [tabIndex, setTabIndex] = useReducer(reducer, navContext || 0, () => navContext || 0);
    const tabItemSx = toSx(tabItemStyles, tabClasses);

    useEffect(() => {
        if (navContext !== tabIndex) {
            setTabIndex({type: 'set', payload: navContext})
        } else if (navContext === -1 && tabIndex === -1) {
            let index = -1;
            tabs.forEach((tab, i) => {
                if (hasAccess(tab) && index === -1) {
                    index = i;
                }
            })
            setNavigationContext(index)
        }
    }, [navContext, tabIndex, hasAccess, setNavigationContext, tabs]);

    const filterTabs = (tab) => {
        return hasAccess(tab);
    }

    return (
        <Tabs allowScrollButtonsMobile scrollButtons visibleScrollbar

              value={tabIndex}
              onChange={(e, index) => {
                  setTabIndex({type: 'set', payload: index});
                  if (navContext >= 0) {
                      setNavigationContext(index)
                  }
              }}
              sx={[toSx(tabsStyles, tabsClasses), ...(Array.isArray(sx) ? sx : [sx])]}
        >
            {tabs?.filter(filterTabs).map((group, index) => (
                <HasAccess {...group} key={uuidv4()}>
                    <Tab disableRipple label={startCase(group?.subheader)}
                         sx={[tabItemSx, index === tabIndex ? {color: "#fff !important"} : {}]}
                         onClick={() => {
                             setTabIndex({type: 'set', payload: index});
                             setNavigationContext(index)
                         }}/>
                </HasAccess>
            ))}
        </Tabs>
    );
}
