import {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    Typography
} from '@mui/material';
import useAuth from '../hooks/useAuth';
import {CLOCK_IN_PAY_TYPES} from "../config";
import axios from "../utils/axios";
import {getUnixTime} from "date-fns";
import Iconify from "../components/Iconify";
import usePayrollAutocomplete from "../hooks/usePayrollAutocomplete";
import useEnvironment from "../hooks/useEnvironment";

const initialSession = {
    initialized: false,
    loading: false,
    role: null,
    Session: null,
};

const WorkStatusContext = createContext({
    Session: initialSession,
    hasAccess: () => false,
}); // component props type

function createInitialState(defaultState) {
    return defaultState;
}

function reducer(state, action) {
    switch (action.type) {
        case 'show_clock_in': {
            return {
                ...state,
                showClockInOutWindow: true
            };
        }
        case 'hide_clock_in': {
            return {
                ...state,
                showClockInOutWindow: false
            }
        }
        default: {
            return {
                ...state,
                showClockInOutWindow: false
            }
        }
    }
}

const WorkStatusProvider = ({children}) => {
    const {user, updateWorkStatus, logout} = useAuth();

    const {environmentReady} = useEnvironment();

    const {thisPayrollPeriod} = usePayrollAutocomplete({year: new Date().getFullYear()});

    const [state, dispatch] = useReducer(
        reducer,
        {showClockInOutWindow: false},
        createInitialState
    );

    const moreThan24Hours = (punchIn) => {
        console.log('moreThan24Hours')
        const now = Math.floor(new Date().getTime() / 1000);
        const totalSeconds = now - punchIn;
        const totalHours = totalSeconds / 3600 || 0;
        console.log({totalHours})
        return totalHours > 24;
    }

    console.log({user: user?.workStatus})

    const handleClockOut = async () => {
        const clockOutTime = getUnixTime(new Date()); // Get the current time as Unix timestamp

        const {data: {workStatus}} = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/time-punches/clock/out`, {
            workWeek: thisPayrollPeriod?.workWeek,
            clockInTime: user.workStatus.clockInTime, // Send the clock-in time
            clockOutTime // Send the clock-out time
        }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_COMMON_SERVICES_API_KEY}`
            }
        });

        updateWorkStatus(workStatus);
    };

    const handleClockIn = async () => {
        const clockInTime = getUnixTime(new Date()); // Get the current time as Unix timestamp

        const {data: {workStatus}} = await axios.post(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/time-punches/clock/in`, {
            workWeek: thisPayrollPeriod?.workWeek,
            clockInTime, // Send clock-in timestamp
        }, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_COMMON_SERVICES_API_KEY}`
            }
        });

        updateWorkStatus(workStatus);
    };

    useEffect(() => {
        if (!user?.workStatus?.clockedIn && CLOCK_IN_PAY_TYPES.includes(user?.compensation?.payType)) {
            dispatch({type: 'show_clock_in'});
        }
        if (user?.workStatus?.clockedIn && CLOCK_IN_PAY_TYPES.includes(user?.compensation?.payType) && moreThan24Hours(user.workStatus.clockInTime)) {
            dispatch({type: 'show_clock_in'});
            moreThan24Hours(user.workStatus.clockInTime)
        } else {
            dispatch({type: 'hide_clock_in'});
        }
    }, [user]);

    return (
        <WorkStatusContext.Provider
            value={{
                handleClockOut,
                handleClockIn
            }}
        >
            <Dialog open={state.showClockInOutWindow && environmentReady}>
                <DialogTitle>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar sx={{bgcolor: theme => theme.palette.secondary.dark}}>
                            <Iconify icon={"mdi:clock-in"} color={'white'} width={20} height={20}/>
                        </Avatar>
                        <Typography variant={'inherit'}> Punch In</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{mt: 4}}> Please punch in to continue working</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={logout}>Log Out</Button>
                    <Button onClick={handleClockIn}>Punch In</Button>
                </DialogActions>
            </Dialog>
            {children}
        </WorkStatusContext.Provider>
    );
};

WorkStatusProvider.propTypes = {
    children: PropTypes.node,
};

export {WorkStatusProvider, WorkStatusContext};

