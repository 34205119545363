// '@mui
import {useCallback, useEffect, useReducer} from "react";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    connection: {},
    connected: {}
};

const init = (state) => state;

function reducer(state, action) {
    if (action.type === 'reset') {
        return {
            isLoading: false,
            error: null,
            connection: {},
            connected: {}
        };
    }
    if (action.type === 'set') {
        const {hubName, newConnection} = action;
        return {
            isLoading: false,
            error: null,
            connection: {
                [hubName]: newConnection
            },
            connected: {
                [hubName]: true
            }
        };
    }
    throw Error(`Unknown action: ${JSON.stringify(action)}`);
}

// ----------------------------------------------------------------------

export default function useIndividualSignalR(hubName) {
    const [state, dispatch] = useReducer(reducer, initialState, init);

    const connect = useCallback(() => {

        if (!state.connection[hubName] || !state.connected[hubName]) {

            let newConnection = new window.signalR.HubConnectionBuilder()
                .withUrl(`${process.env.REACT_APP_COMMON_SERVICES_API}/api/${hubName}?code=${process.env.REACT_APP_COMMON_SERVICES_API_KEY}`)
                .withAutomaticReconnect()
                .configureLogging(window.signalR.LogLevel.Information)
                .build();

            newConnection.onclose(() => {
                dispatch({type: 'reset'})
                setTimeout(() => connect(), 2000);
            });

            newConnection.start()
                .then(() => {
                    dispatch({
                        type: 'set',
                        newConnection,
                        hubName
                    })

                })
                .catch((error) => console.error('SignalR Connection Error', error));
        }

    }, [state, dispatch, hubName]);

    useEffect(() => {
        if (!state.connection[hubName] || !state.connected[hubName]) {
            connect();
            return () => {
                if (state.connection[hubName] && state.connection[hubName].stop) {
                    state.connection[hubName].stop();
                    dispatch({type: 'reset'})
                }
            }
        }
    }, [connect, state, dispatch, hubName]);


    const applyMethod = (method, callback) => {
        state.connection[hubName] && state.connection[hubName].on(method, callback);
    }

    const removeMethod = (method, callback) => {
        state.connection[hubName] && state.connection[hubName].off(method, callback);
    }

    return [state.connection[hubName], applyMethod, removeMethod, state.connected[hubName], state.isLoading];
}
