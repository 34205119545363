import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    isLoadingAsset: false,
    isUpdating: false,
    error: null,
    appAssets: [],
    appAsset: null
};

const slice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // START LOADING
        startLoadingAsset(state) {
            state.isLoadingAsset = true;
        },

        // START UPDATING
        startUpdating(state) {
            state.isUpdating = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.isLoadingAsset = false;
            state.isUpdating = false;
            state.error = action.payload;
        },

        // GET ROLES
        getAppAssetsSuccess(state, action) {
            state.isLoading = false;
            state.isLoadingAsset = false;
            state.isUpdating = false;
            state.appAssets = action.payload;
        },

        // GET ROLE
        getAppAssetSuccess(state, action) {
            state.isLoading = false;
            state.isLoadingAsset = false;
            state.isUpdating = false;
            state.appAsset = action.payload;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAppAssets() {

    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/api/assets/app`);
            dispatch(slice.actions.getAppAssetsSuccess(response.data.apps));

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getAppAsset(identifier) {
    return async () => {
        dispatch(slice.actions.startLoadingAsset());
        try {
            const response = await axios.get(`/api/assets/app`, {
                params: {'apps.identifier': identifier},
            });
            dispatch(slice.actions.getAppAssetSuccess(response.data.apps[0]));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setAppAssets(_id, newData) {
    return async () => {
        dispatch(slice.actions.startUpdating());
        try {
            const response = await axios.post(`/api/assets/app`, {
                _id,
                apps: newData
            })
            dispatch(slice.actions.getAppAssetsSuccess(response.data.apps));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
