import {Box, CardContent, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import {useEffect, useMemo, useRef, useState} from 'react';
import ChatMessageItem from './ChatMessageItem';
import Iconify from '../Iconify';
import {filter} from "lodash";

// ----------------------------------------------------------------------

ChatMessageList.propTypes = {
    conversation: PropTypes.object.isRequired,
    participants: PropTypes.array.isRequired,
    closeConversation: PropTypes.func.isRequired,
};

export default function ChatMessageList({conversation, participants, closeConversation}) {

    const messagesRef = useRef();

    const lastMessage = useRef();

    const isInView = useIsInViewport(lastMessage);

    const [lastItemOInView, setLastItemInView] = useState(false);

    useEffect(() => {
        setLastItemInView(isInView);
    }, [isInView]);

    const hasOverflowingChildren = messagesRef?.current?.offsetHeight < messagesRef?.current?.scrollHeight;

    useEffect(() => {
        lastMessage.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'end',
        });
    }, [conversation]);

    return (
        <>
            <CardContent>
                <Stack sx={{maxHeight: 300, overflowY: 'scroll'}} ref={messagesRef}>
                    {filter(conversation.messages, (m) => !['system', 'function'].includes(m.role)).map((message, index) => {
                        if (index === conversation.messages.length - 1) {
                            return (
                                <Box key={message.id} ref={lastMessage}>
                                    <ChatMessageItem
                                        closeConversation={closeConversation}
                                        participants={participants}
                                        message={message}
                                        conversation={conversation}
                                    />
                                </Box>
                            );
                        }
                        return (
                            <ChatMessageItem
                                closeConversation={closeConversation}
                                participants={participants}
                                key={message.id}
                                message={message}
                                conversation={conversation}
                            />);
                    })}
                </Stack>
            </CardContent>

            <Stack direction={'row'} alignContent={'center'} alignItems={'center'} justifyContent={'center'}
                   sx={{height: 25}}>
                {hasOverflowingChildren && !lastItemOInView && <Iconify icon={'ic:outline-more-horiz'}/>}
            </Stack>
        </>
    );
}

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) =>
                setIsIntersecting(entry.isIntersecting),
            ),
        [],
    );

    useEffect(() => {
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);

    return isIntersecting;
}
