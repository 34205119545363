import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
import {INTEGRATION_CARDS} from "../../config";
import {camelCase, find, flatMap} from "lodash";
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    integrationsCollected: false,
    error: null,
    integrations: [],
    integrationsByType: {},
    integrationTypes: []
};

const slice = createSlice({
    name: 'integrations',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
            state.integrationsCollected = false;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET INTEGRATIONS
        getIntegrationsSuccess(state, action) {
            state.isLoading = false;
            state.integrationsCollected = true;
            state.integrations = action.payload;

        },

        // GET INTEGRATIONS
        setIntegrationsByType(state, action) {
            const integrationsByType = {}
            const integrationTypes = flatMap(INTEGRATION_CARDS, 'header')
            integrationTypes.forEach(type => {
                const typeKey = camelCase(type)
                integrationsByType[typeKey] = {};
                find(INTEGRATION_CARDS, {header: type}).cards.forEach(({key}) => {
                    if (action.payload[key]) {
                        const dataKey = camelCase(key)
                        integrationsByType[typeKey][dataKey] = action.payload[key];
                    }
                })
            })
            state.integrationsByType = integrationsByType;
            state.integrationTypes = integrationTypes;
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getIntegrations() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/integrations');
            dispatch(slice.actions.getIntegrationsSuccess(response.data.integrations[0]));
            dispatch(slice.actions.setIntegrationsByType(response.data.integrations[0]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
