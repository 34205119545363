import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    payrollRatesLoading: null,
    payrollRatesCollected: null,
    payrollPeriodsCollected: null,
    payrollRates: [],
    payrollPeriods: [],
    currentPayPeriod: null,
    payrollSettingsLoading: null,
    payrollPeriodsLoading: null,
    payrollSettingsCollected: null,
    payrollSettings: null,
    payrollOptions: [],
    payrollOptionsCollected: false,
    payrollOptionsLast50Years: [],
    payrollOptionsLast50YearsCollected: false,
};

const slice = createSlice({
    name: 'hoo',
    initialState,
    reducers: {
        // START LOADING
        startPayrollRatesLoading(state) {
            state.payrollRatesLoading = true;
        },

        startPayrollSettingsLoading(state) {
            state.payrollSettingsLoading = true;
        },

        startPayrollPeriodsLoading(state) {
            state.payrollPeriodsLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.payrollRatesLoading = false;
            state.payrollSettingsLoading = false;
            state.payrollRatesCollected = true;
            state.error = action.payload;
        },

        // HAS ERROR
        hasSettingsError(state, action) {
            state.payrollSettingsLoading = false;
            state.payrollSettingsCollected = true;
            state.error = action.payload;
        },

        getPayrollRatesSuccess(state, action) {
            state.payrollRatesLoading = false;
            state.payrollRatesCollected = true;
            state.payrollRates = action.payload;
        },

        getPayrollSettingsSuccess(state, action) {
            state.payrollSettingsLoading = false;
            state.payrollSettingsCollected = true;
            state.payrollOptionsCollected = false;
            state.payrollOptions = [];
            state.payrollOptionsLast50YearsCollected = false;
            state.payrollOptionsLast50Years = [];
            state.currentPayPeriod = null;
            state.payrollSettings = action.payload || {};
        },

        getPayrollPeriodsSuccess(state, action) {
            state.payrollPeriodsLoading = false;
            state.payrollPeriodsCollected = true;
            state.payrollPeriods = action.payload || [];
        },

        setCurrentPayPeriod(state, action) {
            state.currentPayPeriod = action.payload || {};
        },

        setPayrollOptions(state, action) {
            state.payrollOptions = action.payload || [];
            state.payrollOptionsCollected = true;
        },

        setPayrollLast50years(state, action) {
            state.payrollOptionsLast50Years = action.payload || [];
            state.payrollOptionsLast50YearsCollected = true;
        }
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPayrollRates() {
    return async () => {
        dispatch(slice.actions.startPayrollRatesLoading());
        try {
            const response = await axios.get('/api/payroll/rates');
            dispatch(slice.actions.getPayrollRatesSuccess(response.data.rates));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPayrollSettings() {
    return async () => {
        dispatch(slice.actions.startPayrollSettingsLoading());
        try {
            const response = await axios.get('/api/payroll/settings');
            dispatch(slice.actions.getPayrollSettingsSuccess(response.data.payrollSettings[0]));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPayrollPeriods() {
    return async () => {
        dispatch(slice.actions.startPayrollPeriodsLoading());
        try {
            const {data: {payrollPeriods}} = await axios.get('/api/payroll/periods');
            dispatch(slice.actions.getPayrollPeriodsSuccess(payrollPeriods));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updatePayrollSettings(settings) {
    return async () => {
        dispatch(slice.actions.startPayrollSettingsLoading());
        try {
            dispatch(slice.actions.getPayrollSettingsSuccess(settings));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setCurrentPayPeriod(payPeriod) {
    return async () => {
        try {
            dispatch(slice.actions.setCurrentPayPeriod(payPeriod));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setPayrollOptions(payPeriod) {
    return async () => {
        try {
            dispatch(slice.actions.setPayrollOptions(payPeriod));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setPayrollLast50years(payPeriod) {
    return async () => {
        try {
            dispatch(slice.actions.setPayrollLast50years(payPeriod));
        } catch (error) {
            dispatch(slice.actions.hasSettingsError(error));
        }
    };
}
