import PropTypes from 'prop-types';
import {Alert, AlertTitle, Container} from '@mui/material';
import Page401 from "../pages/Page401";
import usePermissions from "../hooks/usePermissions";

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
    accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
    children: PropTypes.node
};

export default function RoleBasedGuard({permission, children}) {
    const {hasAccess} = usePermissions()

    if (permission && !hasAccess(permission) && !permission.includes('*')) {
        return (
            <Container>
                <Alert severity="error">
                    <AlertTitle>Permission Denied</AlertTitle>
                    You do not have permission to access this page. If you feel you have reached this page in error and
                    you should have access. Please contact
                    your system administrator.
                </Alert>
                <Page401/>
            </Container>
        );
    }

    return <>{children}</>;
}
