import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    activeStep: 0,
    services: {}, // stored as an array with the key as locationId:hoursOfOperationId
    hoursOfOperation: {}, // stored as an array with the key as locationId
    locations: [],
    rates: {}, // stored as an rate object with the key as locationId:hoursOfOperationId:serviceId
    costs: {}, // stored as an rate object with the key as locationId:hoursOfOperationId:serviceId
    service: null
};

const slice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        reset(state, action) {
            state.isLoading = false;
            state.error = null;
            state.activeStep = 0;
            state.services = {}; // stored as an array with the key as locationId:hoursOfOperationId
            state.hoursOfOperation = {}; // stored as an array with the key as locationId
            state.locations = [];
            state.rates = {}; // stored as an rate object with the key as locationId:hoursOfOperationId:serviceId
            state.costs = {}; // stored as an rate object with the key as locationId:hoursOfOperationId:serviceId
            state.service = null
        },

        // HAS ERROR
        getRateSetupSuccess(state, action) {
            state.isLoading = false;
            const {services, hoursOfOperation, locations, rates, costs} = action.payload;

            state.services = services;
            state.hoursOfOperation = hoursOfOperation;
            state.locations = locations;
            state.rates = rates;
            state.costs = costs;

        },

        setActiveStep(state, action) {
            state.activeStep = action.payload
        },

        setServices(state, action) {
            state.services = action.payload
        },

        setRates(state, action) {
            state.rates = action.payload
        },

        setHoursOfOperation(state, action) {
            state.hoursOfOperation = action.payload
        },

        setLocations(state, action) {
            state.locations = action.payload

        },

        setCosts(state, action) {
            state.costs = action.payload
        },
    },
});


// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function continueRateSetup(_id, data) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            if (_id) {
                const response = await axios.get('/api/rates/wizard', {params: {_id}});
                dispatch(slice.actions.getRateSetupSuccess(response.data.coverages[0]));
            } else if (data) {
                dispatch(slice.actions.getRateSetupSuccess(data));
            }
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function reset() {
    return async () => {
        try {
            dispatch(slice.actions.reset());
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setActiveStep(step) {
    return async () => {
        try {
            dispatch(slice.actions.setActiveStep(step));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setServices(services) {
    return async () => {
        try {
            dispatch(slice.actions.setServices(services));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setHoursOfOperation(hoos) {
    return async () => {
        try {
            dispatch(slice.actions.setHoursOfOperation(hoos));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setLocations(locations) {
    return async () => {
        try {
            dispatch(slice.actions.setLocations(locations));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setRates(rates) {
    return async () => {
        try {
            dispatch(slice.actions.setRates(rates));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function setCosts(costs) {
    return async () => {
        try {
            dispatch(slice.actions.setCosts(costs));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
