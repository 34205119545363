import {createContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useEnvironment from '../hooks/useEnvironment';
import useAuth from '../hooks/useAuth';
import Loading from '../components/page/Loading';
import {Box, LinearProgress, Stack} from "@mui/material";
import {useFlags, useLDClient} from "launchdarkly-react-client-sdk";
import {camelCase, find} from "lodash";
import Page401 from "../pages/Page401";
import useLocales from "../hooks/useLocales";

let debug = process.env.REACT_APP_ENV === 'dev'

const initialPermissions = {
    initialized: false,
    loading: false,
    role: null,
    permissions: null,
    workspace: null,
    organization: null,
    userContext: {}
};

export const PermissionsContext = createContext({
    permissions: initialPermissions,
    hasAccess: () => false,
    flagsLoaded: false
}); // component props type

const PermissionsProvider = ({children}) => {

    const [permissions, setPermissions] = useState(initialPermissions);

    const [flagsLoaded, setFlagLoaded] = useState(false);

    const {environmentReady, organization, workspace, getCurrentOrgDetails} = useEnvironment();

    const {user, isAuthenticated} = useAuth();

    const ldClient = useLDClient();

    const ldFlags = useFlags();

    if (debug) console.log('PermissionsProvider: permissions', {permissions});

    useEffect(() => {
        const systemReady = Boolean(environmentReady && isAuthenticated && user && ldClient);
        const changeInView = Boolean(organization && workspace && permissions.organization !== organization && permissions.workspace !== workspace);
        // const changeInView = permissions.workspace !== workspace;

        if (systemReady && (!flagsLoaded || changeInView) && !permissions.loading) {
            const userPermissions = find(user.permissions, p => p.id === organization && p.workspaces.includes(workspace));
            if (!userPermissions) {
                console.error('User permissions not found for Organization and Workspace selected.', {
                    user,
                    userPermissions,
                    organization,
                    workspace
                });
                return
            }
            setPermissions({
                ...permissions,
                loading: true,
            });

            ldClient.identify(user.context, user.ldHash)
                .then((result) => {
                    setFlagLoaded(true);
                    setPermissions({
                        role: {
                            name: user.permissions[0].role,
                        },
                        organization,
                        workspace,
                        userContext: user.context,
                        permissions: [],
                        loading: false,
                        initialized: true,
                    });
                })
                .catch((error) => console.error(error));
        }
    }, [getCurrentOrgDetails, organization, workspace, environmentReady, user, isAuthenticated, ldClient, flagsLoaded, permissions]);

    const hasAccess = (props, debugOverride) => {
        const {flag, flags = [], or = false} = props;
        const featureFlag = camelCase(flag)
        const featureFlags = flags.map(f => camelCase(f));

        if (!flag && flags.length === 0) {
            if (debug || debugOverride) console.log('hasAccess was given nothing', false);
            return false;
        }

        if (debug || debugOverride) {
            console.log('hasAccess', {props});
            console.log('hasAccess', {featureFlag});
            console.log(`hasAccess: ldFlags[${featureFlag}]`, ldFlags[featureFlag]);
            console.log('featureFlags', featureFlags)
            console.log('featureFlags.every(f => ldFlags[f])', featureFlags.map(f => ({[f]: ldFlags[f]})))
        }

        if (featureFlag && flags.length === 0) {
            return ldFlags[featureFlag];
        }

        if (featureFlag && flags.length > 0) {
            return ldFlags[featureFlag] && (!or ? featureFlags.every(f => ldFlags[f]) : featureFlags.some(f => ldFlags[f]));
        }

        return !or ? featureFlags.every(f => ldFlags[f]) : featureFlags.some(f => ldFlags[f])
    };

    return (
        <PermissionsContext.Provider
            value={{
                permissions,
                hasAccess,
                flagsLoaded
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
};

PermissionsProvider.propTypes = {
    workspace: PropTypes.string,
    organization: PropTypes.string,
    children: PropTypes.object,
};

export default PermissionsProvider;

HasAccess.propTypes = {
    permission: PropTypes.string,
    flag: PropTypes.string,
    or: PropTypes.bool,
    flags: PropTypes.arrayOf(PropTypes.string),
    asset: PropTypes.string,
    role: PropTypes.string,
    debugOverride: PropTypes.bool,
    children: PropTypes.object,
};

export function HasAccess({children, ...item}) {

    if (debug) console.log('HasAccess Component: item', {...item});


    return (
        <PermissionsContext.Consumer>
            {({hasAccess}) => {
                if (hasAccess({...item})) {
                    return children;
                } else if (item.page) {
                    return <Page401/>
                }
                return null;
            }}

        </PermissionsContext.Consumer>
    );
}

PermissionsLoading.propTypes = {
    message: PropTypes.string,
    messageVariant: PropTypes.string,
    children: PropTypes.node,
    horizontalMenu: PropTypes.bool,
};

export function PermissionsLoading({message, messageVariant, children, horizontalMenu, topNavMenu}) {

    const {translate} = useLocales();

    if (debug) console.log('PermissionsLoading', {message, messageVariant});

    const baseProperties = {
        style: {
            backgroundColor: 'transparent',
            background: 'transparent',
        },
    };

    const messageProperties = {
        style: {
            backgroundColor: 'transparent',
            background: 'transparent',
        },
    };

    if (horizontalMenu) {
        messageProperties.style.marginTop = 25;
        baseProperties.style.height = 100;
        baseProperties.style.minHeight = 100;
    }

    return (
        <PermissionsContext.Consumer>
            {({permissions}) => {
                const {loading, initialized} = permissions;
                const showLoading = loading || !initialized;
                if (debug) console.log('PermissionsContext', {showLoading, loading, initialized});
                if (showLoading && topNavMenu) {
                    return (
                        <Stack px={2} minHeight={10} justifyContent={"center"}>
                            <Box sx={{color: theme => theme.palette.text.primary}}>
                                {message || translate("navs.sink.permissionContext.loadingUserPermissions")}
                            </Box>
                            <Box>
                                <LinearProgress/>
                            </Box>
                        </Stack>
                    );
                }
                if (showLoading) {
                    return (
                        <Loading
                            size={horizontalMenu ? 'small' : 'big'}
                            message={message || translate("navs.sink.permissionContext.loadingUserPermissions")}
                            baseProperties={baseProperties}
                            messageProperties={messageProperties}
                            messageTypography={{
                                variant: messageVariant || 'subtitle2',
                            }}
                            style={{backgroundColor: 'transparent', background: 'transparent'}}
                        />
                    );
                }
                return children;
            }}

        </PermissionsContext.Consumer>
    );
}
